export enum Provider {
  NAVER = 'NAVER',
  KAKAO = 'KAKAO',
  GOOGLE = 'GOOGLE',
  EMAIL = 'EMAIL',
}

export const AuthSignInSocial = [
  {
    reasonType: 'ERROR_1500',
    reasonMessage: '소셜 인증토큰 획득에 실패하였습니다.',
  },
  {
    reasonType: 'ERROR_1501',
    reasonMessage: '소셜기관 고객정보 연동에 실패하였습니다.',
  },
  {
    reasonType: 'ERROR_1502',
    reasonMessage: '소셜 인증은 kakao, naver, google 만 지원합니다.',
  },
  {
    reasonType: 'ERROR_1503',
    reasonMessage: '소셜기관에 연동된 번호가 다른 국가의 휴대폰 번호 입니다.\n국내 휴대폰 번호를 입력해주세요.',
  },
  {
    reasonType: 'ERROR_1504',
    reasonMessage: '소셜 인증기관에 휴대폰번호가 존재하지 않습니다.\n화면에서 휴대폰 번호를 입력해주세요.',
  },
];
