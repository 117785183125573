<script setup lang="ts">
import { useBasicsStore } from '~/services/base';
import Account from '~/services/userCommon/component/Account.vue';

const basicStore = useBasicsStore();
const props = defineProps<{
  duplicatedAccounts: {
    createdAt: string;
    phoneNumber: string;
    accounts: Array<{
      account: string;
      createdAt: string;
    }>;
  };
}>();
const emits = defineEmits<{
  (e: 'closeModal'): void;
}>();
const handleVisible = computed<boolean>(() => {
  return !!props.duplicatedAccounts.accounts.length;
});
</script>

<template>
  <Dialog v-model:visible="handleVisible" modal class="wb-dialog wb-dialog-common">
    <div class="wb-dialog-common__container">
      <div class="wb-dialog-common__header">
        <button class="wb-dialog-common__close-btn" @click="emits('closeModal')">
          <span class="hide">닫기</span>
        </button>
        <h2 class="wb-dialog-common__title">가입 계정</h2>
      </div>
      <div class="wb-dialog-common__content">
        <Account
          :created-at="props.duplicatedAccounts.createdAt"
          :items="props.duplicatedAccounts.accounts"
          :number="props.duplicatedAccounts.phoneNumber" />
      </div>
      <div class="wb-dialog-common__footer">
        <Button label="로그인" class="w-full" @click="basicStore.setLoginModalState(true)" />
      </div>
    </div>
  </Dialog>
</template>

<style lang="scss" scoped></style>
