import { userProfileService } from '.';
import { type MyProfile, type ProfileUpdateResponse } from './type';

interface State {
  profile?: MyProfile;
  signUpPathOptions: any;
}

export const useUserProfileStore = defineStore('user-profile', {
  state: (): State => ({
    profile: undefined, // 사용자 정보
    signUpPathOptions: [], // 회원가입 경로
  }),
  actions: {
    // 사용자 정보를 읽어옵니다.
    async fetchMyProfile(): Promise<MyProfile> {
      const res = await userProfileService.fetchMe();
      if (!res?.data) {
        return {};
      }

      const { data } = res;
      this.profile = data?.data?.meResponse;
      this.signUpPathOptions = data?.data?.meResponse?.signUpPathOptions;
      return data?.data?.meResponse;
    },
    async updateMyProfile(params): Promise<ProfileUpdateResponse> {
      const { data } = await userProfileService.updateMyProfile(params);
      return data?.data?.profileMeUpdateResponse;
    },
  },
  getters: {
    getMyProfile(state) {
      return state.profile;
    },
    getSignUpPathOptions(state) {
      return state.signUpPathOptions;
    },
  },
});
