import { useUserAuthStore } from '~/services/userAuth';

import Tracker from '../libs/Tracker';

type PopupOptions = {
  key?: string;
  layout?: 'default' | 'modal';
  width?: number;
  alignLeft?: boolean;
  hideTitle?: boolean;
  overlay?: boolean;
  emoji?: {
    text: string;
    animation: 'none' | 'wave' | 'tada' | 'heart-beat' | 'spin' | 'flash' | 'bounce' | 'rubber-band' | 'head-shake';
  };
  autoClose?: number;
  showOnce?: boolean;
  doNotShowAfterSubmit?: boolean;
  customFormUrl?: string;
  hiddenFields?: {
    [key: string]: any;
  };
  onOpen?: () => void;
  onClose?: () => void;
  onPageView?: (page: number) => void;
  onSubmit?: (payload: any) => void;
};

// https://tally.so/r/3ElWKA
export function useTally(options?: PopupOptions) {
  const openTallyPopup = (url, eventProperties?: { [key: string]: any }) => {
    if (!window.Tally) {
      return console.error('Tally is not defined');
    }

    const authStore = useUserAuthStore();
    const userId = authStore.user?.userId || ''; // 미로그인 이나 비회원 일때 처리 어떻게 할지 정해야함
    const formId = url.trim().substring(url.lastIndexOf('/') + 1);
    // console.log('lastPath', formId);
    window.Tally.openPopup(formId, {
      layout: 'modal',
      onPageView: (page) => {
        // 페이지 이동시 페이지 번호가 리턴된다.
        // console.log('page', page);
        Tracker['View Tally Survey']({
          pageNumber: page,
          ...eventProperties,
        });
      },
      onSubmit: (payload) => {
        // console.log('onSubmit', payload);
        const { formId, formName, fields } = payload;
        Tracker['Complete Tally Survey']({
          formId,
          userId,
          ...fields.reduce((acc, { title, answer }, index) => {
            acc[`question${index}`] = title;
            acc[`answer${index}`] = answer.value;
            return acc;
          }, {}),
          ...eventProperties,
          // question: string; answer: string
        });
      },
      ...options,
    });
  };

  onMounted(() => {
    // console.log('mounted:useTally');
  });

  return { openTallyPopup };
}
