import type { ApiResponse } from '~/types/global';

import BaseService from '../BaseService';
import { type ReferralCodeResponse, type UserCreatorDisplaySeq } from './types';

class UserCreatorService extends BaseService {
  constructor() {
    super('/user/creator');
  }

  getCreatorLecture(creatorId: number | string) {
    return this.get<{ items: UserCreatorDisplaySeq }>(`/${creatorId}/lectures`);
  }
  // (크리에이터 여부 && 공유자 === 컨텐츠 작성자 여부)를 판단해주고, 조건 충족하면 referralCode를 반환하는 API
  getCommunityCreatorReferralCode(contentId: string) {
    return this.getV2<ApiResponse<ReferralCodeResponse>>(
      `/referral-code?referralType=COMMUNITY&contentId=${contentId}`,
    );
  }

  // (크리에이터 여부 && 공유자 === 컨텐츠 작성자 여부)를 판단해주고, 조건 충족하면 referralCode를 반환하는 API
  getClassCreatorReferralCode(classId: string) {
    return this.getV2<ApiResponse<ReferralCodeResponse>>(`/referral-code?referralType=CLASS&classId=${classId}`);
  }
}

export const userCreatorService = new UserCreatorService();
