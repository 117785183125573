import { datadogRum } from '@datadog/browser-rum';

// TODO: DATADOG 모니터링 사용자 세션 정보 설정(도입 여부에 따라 유지 또는 삭제 결정)
export default defineNuxtPlugin(() => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  // * 운영 환경에서만 Datadog 모니터링을 사용하도록 설정
  const runtime = useRuntimeConfig();
  if (runtime.public.app_env !== 'prod') {
    return;
  }

  datadogRum.init({
    applicationId: 'e9eede1a-d065-40b7-a9cc-3ba81cfc7ceb',
    clientToken: 'pub2ff59615ebfceddf6232eb9c151e207d',
    site: 'datadoghq.com',
    service: 'weolbu-frontend',
    env: 'prd', // * Datadog 에서 env로 필터링 하기 용이하게 백엔드와 맞춤
    sessionSampleRate: 0,
    sessionReplaySampleRate: 10,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    compressIntakeRequests: true,
    version: runtime.public.releaseVersion,
  });
});
