import * as Sentry from '@sentry/vue';
import axios, { type AxiosRequestConfig, type AxiosResponse } from 'axios';

import { safeDateParse } from '~/libs/dateUtils';

import { Provider } from '../policy';
import { StorageItem } from '../shared/StorageItem';
import { PROVIDER_STATE_KEY_PREFIX } from '../shared/auth/client';

const DEBUG = false;

const URL_NEW_ACCESS_TOKEN = '/v1/user/auth/newAccessToken';
let isRefreshing = false;
let refreshSubscribers: Array<Function> = [];

// helper function to add subscriber
function subscribeTokenRefresh(cb: Function) {
  refreshSubscribers.push(cb);
}

// helper function to notify subscribers and clear them
function onRrefreshed(token: string) {
  refreshSubscribers.map((cb) => cb(token));
  refreshSubscribers = [];
}
const instance = axios.create({
  timeout: 60000, // * 백엔드 timeout에 맞춰서 요청에 따라 1분으로 설정
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});
instance.interceptors.request.use(
  async function (config) {
    if (process.client === false) {
      return config;
    }
    const { useUserAuthStore } = await import('~/services/userAuth');
    const userAuth = useUserAuthStore();
    const runtime = useRuntimeConfig();
    config.baseURL = runtime.public.apiBase;

    if (config.url === URL_NEW_ACCESS_TOKEN) {
      return config;
    }
    const tokenData = userAuth.token;
    DEBUG && console.log('Request:', config.url, tokenData);
    if (tokenData && isTokenExpired(tokenData.accessTokenExpiresIn)) {
      const retryOriginalRequest = new Promise((resolve) => {
        subscribeTokenRefresh((token: string) => {
          config.headers.authorization = `Bearer ${token}`;
          resolve(config);
        });
      });

      if (isRefreshing === false) {
        isRefreshing = true;
        try {
          await newAccessToken(config);
        } catch (error) {
          console.log('!! newAccessToken error', error);
        } finally {
          isRefreshing = false;
        }
      }

      // refresh 하고있는애들이 이함수가 호출ㄷ되면 다시 요청을 보내준다
      return retryOriginalRequest as any;
    }
    if (tokenData) {
      config.headers.authorization = `Bearer ${tokenData.accessToken}`;
    }
    return config;
  },
  function (error) {
    try {
      Sentry.setExtra('calling-location', 'interceptors.request');
      Sentry.setExtra('error-request', error?.request);
      Sentry.setExtra('error-response', error?.response);
      Sentry.setExtra('error-response-data', error?.response?.data);
      Sentry.captureException(error);
    } catch (err) {}

    $alert('API 발송요청 중 에러가 발생했습니다. 콘솔을 확인해주세요.');
    if (error.config) {
      const config = error.config as AxiosRequestConfig;
    }

    return Promise.reject(error);
  },
);

// Add a response interceptor
instance.interceptors.response.use(
  async function (response) {
    const { data } = response;

    DEBUG && console.log('Response:', data);
    const provider = StorageItem.local(PROVIDER_STATE_KEY_PREFIX).get() as Provider;
    // "ERROR_0014"
    if (
      [Provider.GOOGLE, Provider.KAKAO, Provider.NAVER].includes(provider) &&
      data.status?.errorCode === 'ERROR_0014'
    ) {
      return response;
    }

    if (provider === Provider.GOOGLE && data.status?.errorCode === 'ERROR_1508' && data.status.errorMessage === '') {
      return response;
    }
    // "ERROR_1508"
    if (
      data.status?.errorCode === 'ERROR_1513' ||
      (data.status?.errorCode === 'ERROR_1508' && data.status.errorMessage === '')
    ) {
      return response;
    }

    // 서버에서 상태 에러코드와 에러메시지를 내려주는 경우 그대로 표시
    if (data.status?.errorCode) {
      // 에러코드 0065은 사용하지 않아서 제거처리하고 0077(대기신청)인 경우에는 팝업 나오도록 처리
      // @ts-ignore
      if (!response.config.slientAlert) {
        try {
          await $alert(`${data.status.errorMessage}`);
        } catch (e) {
          alert(`${data.status.errorMessage}`);
        }
      }
    }

    return response;
  },
  async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    try {
      const response = error.response as AxiosResponse;
      const config = error.config;
      // 419 / 401 에러는 트랙킹 하지 않음
      if (![419, 401].includes(response.status)) {
        Sentry.setExtra('calling-location', 'interceptors.response');
        Sentry.setExtra('method', config?.method);
        Sentry.setExtra('baseURL', config?.baseURL);
        Sentry.setExtra('method', config?.url);
        Sentry.setExtra('user-data', config?.data);
        Sentry.setExtra('error-request', error?.request);
        Sentry.setExtra('error-response', error?.response);
        Sentry.setExtra('error-response-data', error?.response?.data);
        Sentry.setExtra('error-response-status', error?.response?.status);
        Sentry.captureException(error);
      }
    } catch (e) {}
    if (error.config && error.response) {
      const config = error.config as AxiosRequestConfig;
      const response = error.response as AxiosResponse;

      if (isDeviceTokenUrl(config.url)) {
        return Promise.resolve();
      }

      if (response.status === 419) {
        const { useUserAuthStore } = await import('~/services/userAuth');
        const userAuth = useUserAuthStore();
        userAuth.logout(419);

        // return Promise.reject(error);
      }

      if (response.status === 401) {
        // 401 이 떨어질일이 거의없지만 서버 상황에 따라 문제가 발생할수 있으므로
        // 401 이 떨어지면 토큰을 갱신한다.
        await newAccessToken(config);
      }

      // await $alert(`상태코드 : ${response.status} \n ${response.statusText}`);
    }

    // _nuxt static 파일 로딩중에 오류 발생시 에러 메시지 무시
    if (
      error.message.includes('Unable to preload CSS for') ||
      error.message.includes('Failed to fetch dynamically imported module') ||
      error.message.includes('Request aborted')
    ) {
      return error;
    }
    return Promise.reject(error);
  },
);

async function newAccessToken(config: any) {
  const { useUserAuthStore } = await import('~/services/userAuth');
  const userAuth = useUserAuthStore();
  const runtime = useRuntimeConfig();
  config.baseURL = runtime.public.apiBase;

  const tokenData = userAuth.token;
  const res = await instance.post(URL_NEW_ACCESS_TOKEN, {
    refreshToken: tokenData?.refreshToken,
  });
  if (res.status === 200 && $isEmpty(res.data.status.errorCode)) {
    const newTokenData = res.data.data.token;
    if (userAuth && userAuth.token && newTokenData) {
      userAuth.token.accessToken = newTokenData.accessToken;
      userAuth.token.accessTokenExpiresIn = newTokenData.accessTokenExpiresIn;

      onRrefreshed(newTokenData.accessToken);
    }
  } else {
    console.log('Error refreshing token:', res.data.status.errorMessage);
    userAuth.logout(419);
    return Promise.reject(res.data.status.errorMessage);
  }
}
function isTokenExpired(expiresIn: number) {
  const now = Date.now();

  // 20초 앞당겨서 토큰 expire 시켜버림. 그래야 서버와 타이밍이 맞음.
  // 서버 시간에 딱맞게 expire 체크하면 타이밍상 401이슈가 발생.
  const expirationTime = safeDateParse(expiresIn).add(-20, 'seconds');
  const nowFormatted = safeDateParse(now).format('YYYY-MM-DD HH:mm:ss');
  const expirationTimeFormatted = safeDateParse(expirationTime).format('YYYY-MM-DD HH:mm:ss');
  // @ts-ignore
  const isExpired = now >= expirationTime;

  DEBUG &&
    console.log(
      `isTokenExpired now: ${nowFormatted}, expirationTime: ${expirationTimeFormatted}, isExpired:${isExpired}`,
    );

  return isExpired;
}

export default instance;

function isDeviceTokenUrl(url?: string) {
  return url?.includes('/device/token');
}
