export const DEFAULT_PUBLISHER = {
  '@type': 'Organization',
  name: '월급쟁이부자들',
  sameAs: 'https://weolbu.com',
};

export const DEFAULT_ORGANIZATION = {
  '@type': 'Organization',
  name: '월급쟁이부자들',
  sameAs: 'https://weolbu.com',
};
