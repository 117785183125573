import BaseService from '~/services/BaseService';
import { type LevelNotification } from '~/services/level/type';
import { useUserAuthStore } from '~/services/userAuth';

import { type ApiResponse, type ApiResponseInner, type PaginationOptions } from '~/types/global';

import {
  type ArticleCounts,
  type BoardBanner,
  type BoardContent,
  type BoardContentCommentsData,
  type BoardContentDetail,
  type BoardContentTag,
  type BoardContentV2,
  type BoardContentsData,
  type BoardContentsDraftData,
  type BoardKeyword,
  type BoardMeta,
  type ChallengeRankingOptions,
  type ChallengeRankingResponse,
  type CommmunityAttentionRank,
  type CommunityCategories,
  type CommunityCommentRank,
  type CommunityLastRegDatesOfBoards,
  type CommunitySurveyMember,
  type GetBoardContentCommentOptions,
  type GetBoardContentOptions,
  type GetReviewOptions,
  type InvestmentQnasData,
  type LikeBoardContentCommentOptions,
  type PostBoardContentCommentOptions,
  type PostBoardContentLikeOptions,
  type PostBoardContentOptions,
  type PutBoardContentComment,
  type PutBoardContentOptions,
  type ReviewLikeOptions,
  type WeolbuChallengeTemplate,
} from './type';

class CommunityService extends BaseService {
  constructor() {
    super('/user/community');
  }

  insertBoardContentsShare(boardSeq: number, boardContentsSeq: number) {
    return this.post<{ items: { boardContentsSeq: number } }>('/board/contents/share', { boardSeq, boardContentsSeq });
  }

  // 게시판 조회 (게시글 X)
  // NOTE: 사용하는 곳이 없음
  getBoardList() {
    return this.get<{ items: BoardMeta[] }>('/board');
  }

  // 개인 게시판 조회 (게시글 X)
  getPersonalBoardList() {
    return this.get<{ items: BoardContent[] }>('/board/personal');
  }

  // 커뮤니티 댓글 랭킹 조회
  getCommentRankList({ pageNo, pageSize }: Partial<PaginationOptions>) {
    return this.getV2<ApiResponse<ApiResponseInner<CommunityCommentRank[]>>>(
      `/rank/comments?pageNo=${pageNo}&pageSize=${pageSize}`,
    );
  }

  // 커뮤니티 주목받는 멤버 조회
  getAttentionRankList() {
    return this.getV2<ApiResponse<ApiResponseInner<CommmunityAttentionRank[]>>>(`/rank/follower`);
  }

  getBoardContentEssentialList() {
    return this.getV2<ApiResponse<ApiResponseInner<BoardContent[]>>>(`/essential`);
  }

  // 같은 조원 목록 조회
  getSurveyMembers(params: PaginationOptions) {
    return this.get<{ items: CommunitySurveyMember[] }>('/survey/group', { params });
  }

  // 게시글 목록 조회 - legacy
  getBoardContentList(params: GetBoardContentOptions) {
    return this.get<BoardContentsData>('/board/contents', { params, slientAlert: true });
  }

  // 임시 저장된 게시글 목록 조회
  getBoardContentDraftList(params: PaginationOptions) {
    return this.get<BoardContentsDraftData>('/board/contents/archive', { params });
  }

  // 게시글 컨텐츠 조회 - new : NOTE: 본문 정보 내려주지 않음
  getBoardContents(boardCategoryCd: string, boardSeq: number, params: PaginationOptions) {
    return this.getV2<ApiResponse<BoardContentsData>>(`/category/${boardCategoryCd}/board/${boardSeq}/contents`, {
      params,
    });
  }

  // 게시글 컨텐츠 조회 - NOTE: 본문 정보 일부 제공
  getBoardContentsWithContentText(boardCategoryCd: string, boardId: number, params: PaginationOptions) {
    return this.getV2<ApiResponse<ApiResponseInner<BoardContentV2[]>>>(
      `/category/${boardCategoryCd}/board/${boardId}/content-details`,
      { params },
    );
  }

  // 메인 재테크 QNA
  getInvestmentQnaContents(params: PaginationOptions) {
    return this.getV2<ApiResponse<InvestmentQnasData>>(`/category/board/qna/hot/contents`, {
      params,
    });
  }

  // 게시글 컨텐츠 조회 - 최상위 카테고리
  getBoardParentContents(boardCategoryCd: string, params: PaginationOptions) {
    return this.get<BoardContentsData>(`/category/${boardCategoryCd}/board/contents`, { params });
  }

  // 최신전문가 컬럼 조회
  getExpertContents(boardCategoryCd: string, params: PaginationOptions) {
    return this.get<BoardContentsData>(`/category/${boardCategoryCd}/board/2/contents`, { params });
  }

  // 인기 게시글 컨텐츠 조회
  getBoardHotContents(params: PaginationOptions) {
    return this.getV2<ApiResponse<BoardContentsData>>(`/category/hot/contents`, { params });
  }

  // 팔로우 게시글 컨텐츠 조회
  getBoardFollowContents(params: PaginationOptions) {
    return this.get<BoardContentsData>(`/category/follow/contents`, { params });
  }

  // 게시글 공지사항 조회
  getBoardNotice(boardSeq: string | number) {
    return this.getV2<ApiResponse<ApiResponseInner<BoardContent>>>(`/board/${boardSeq}/notice`);
  }

  // 베스트글 - Legacy
  getBoardContentBestList(params: PaginationOptions) {
    return this.getV2<ApiResponse<BoardContentsData>>(`/board/contents/best`, { params });
  }

  getReviewList(params: GetReviewOptions) {
    return this.get<BoardContentsData>('/review', { params });
  }

  getChallengeRanking(params: ChallengeRankingOptions) {
    return this.getV2<ApiResponse<ApiResponseInner<ChallengeRankingResponse>>>(`/rank/challenge`, {
      params,
    });
  }

  getWeolbuchallIndex() {
    return this.get<{ items: WeolbuChallengeTemplate }>('/category/board/33/contents/counting');
  }

  getBoardContentAnotherMostSeenContents(boardContentId: number | string) {
    return this.getV2<ApiResponse<ApiResponseInner<BoardContentV2[]>>>(
      `/board/contents/${boardContentId}/recommendations/similar-contents`,
    );
  }

  getBoardContentAnotherHotContents() {
    return this.getV2<ApiResponse<ApiResponseInner<BoardContentV2[]>>>(`/board/contents/another-hot-contents`);
  }

  getBoardContentsRelated(boardContentId: number | string) {
    return this.getV2<ApiResponse<ApiResponseInner<{ contents: BoardContentV2[]; tagNames: BoardContentTag[] }>>>(
      `/board/contents/${boardContentId}/tags/related-contents`,
    );
  }

  postBoardContentLikeType(params: PostBoardContentLikeOptions) {
    return this.post('/board/contents/like', params);
  }

  // 게시글 조회수 증가
  postBoardContentViewCount(boardContentsSeq: number | string) {
    return this.post(`/board/contents/${boardContentsSeq}/views`);
  }

  postCommunityUserFollow(targetUserId: string | number) {
    return this.postV2('/follow', { body: { targetUserId } });
  }

  postCommunityBanner() {
    return this.getV2<ApiResponse<ApiResponseInner<BoardBanner>>>('/board/contents/banner');
  }

  postBoardContent(params: PostBoardContentOptions) {
    return this.post<{ items: { boardContentsSeq: number }; notifications: LevelNotification[] }>(
      '/board/contents/insert',
      params,
    );
  }

  postBoardContentDraft(params: PostBoardContentOptions) {
    return this.post<{ items: { boardContentsSeq: number }; notifications: LevelNotification[] }>(
      '/board/contents/archive',
      params,
    );
  }

  postBoardContentDraftPublish(params: PostBoardContentOptions) {
    return this.post<{ items: { boardContentsSeq: number }; notifications: LevelNotification[] }>(
      `/board/contents/${params.boardContentsSeq}/archive-publish`,
      params,
    );
  }

  putBoardContent(params: PutBoardContentOptions) {
    return this.put('/board/contents/update', params);
  }

  deleteBoardContent(boardContentsSeq: number | string) {
    return this.put(`/board/contents/delete/${boardContentsSeq}`);
  }

  // 게시글 상세 조회
  getBoardContentDetail(seq: string | number) {
    return this.get<{ items: BoardContentDetail }>(`/board/contents/${seq}`);
  }

  // 카테고리 조회
  getCommunityCategories() {
    return this.getV2<ApiResponse<ApiResponseInner<CommunityCategories[]>>>(`/category`);
  }

  // 개인
  getCommunityPersonalCategories() {
    return this.get<{ items: CommunityCategories[] }>('/category/personal');
  }

  // 보드 별 가장 최근 게시글의 작성일 조회
  getLastRegDatesOfBoards() {
    return this.getV2<ApiResponse<ApiResponseInner<CommunityLastRegDatesOfBoards[]>>>(`/category/board/latest-dates`);
  }

  // 댓글 목록 조회
  getBoardContentCommentList(params: GetBoardContentCommentOptions) {
    return this.get<BoardContentCommentsData>('/board/contents/comment', { params });
  }

  // 댓글 삭제
  deleteBoardContentComment(commentSeq: number | string) {
    return this.delete(`/board/contents/comment/delete/${commentSeq}`);
  }

  // 댓글 등록
  postBoardContentComment(params: PostBoardContentCommentOptions) {
    return this.post<{
      items: {
        boardContentsSeq: number;
        boardSeq: number;
        commentParentSeq: number;
        commentSeq: number;
        commentText: string;
        regId: string;
      };
      notifications: LevelNotification[];
    }>('/board/contents/comment/insert', {
      ...params,
      commentParentSeq: `${params.commentParentSeq}` === '0' ? null : params.commentParentSeq,
    });
  }

  // 댓글 수정
  putBoardContentComment(params: PutBoardContentComment) {
    return this.put('/board/contents/comment/update', params);
  }

  // 댓글 좋아요/싫어요
  likeBoardContentComment(params: LikeBoardContentCommentOptions) {
    return this.post('/board/contents/comment/like', params);
  }

  likeReview(params: ReviewLikeOptions) {
    return this.post('/review/like', params);
  }

  // 좋아요, 파일다운로드, 조회수
  getArticleCounts(params: { contentId: string }) {
    return this.getV2<ApiResponse<ApiResponseInner<ArticleCounts>>>(`/board/contents/${params.contentId}/count-infos`);
  }

  // 게시글 스크랩 - body 없음
  scrapArticle(params: { contentId: string }) {
    return this.postV2(`/board/contents/${params.contentId}/scraps`);
  }

  // 스크랩 취소
  unscrapArticle(params: { contentId: string }) {
    return this.deleteV2(`/board/contents/${params.contentId}/scraps`);
  }
}

export const communityService = new CommunityService();
