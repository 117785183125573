class IndexedDB {
  private db: any = null;
  dbName = 'weolbu';
  dbVersion = 2;
  dbObjectStoreName = 'clickedNotifications';

  async init(storeName = 'clickedNotifications') {
    this.dbObjectStoreName = storeName;
    this.db = await this.openDB();
  }

  openDB = () => {
    return new Promise((resolve, reject) => {
      const request = window.indexedDB.open(this.dbName, this.dbVersion);

      request.onupgradeneeded = (event: any) => {
        this.db = event.target.result;

        // 추가할 store 가 있을 경우, 아래에 추가하고 dbVersion 을 올려주면 배포 후에 반영된다.
        if (!this.db.objectStoreNames.contains('clickedNotifications')) {
          this.db.createObjectStore('clickedNotifications', { keyPath: 'key' });
        }
        if (!this.db.objectStoreNames.contains('userInterestRecords')) {
          this.db.createObjectStore('userInterestRecords', { keyPath: 'key' });
        }
      };

      request.onsuccess = (event: any) => {
        this.db = event.target.result;
        resolve(event.target.result);
      };

      request.onerror = (event: any) => {
        reject(event.target.error);
      };
    });
  };

  // 데이터 읽기
  readData = async (key) => {
    if (this.db === null) {
      this.db = await this.openDB();
    }

    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.dbObjectStoreName], 'readonly');
      const objectStore = transaction.objectStore(this.dbObjectStoreName);
      const request = objectStore.get(key);

      request.onsuccess = () => {
        if (request.result) {
          resolve(request.result.value);
        } else {
          resolve(null); // 데이터가 없을 경우 null 반환
        }
      };

      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  };

  // 데이터 삭제
  deleteData = (key) => {
    // const db = await openDB();

    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.dbObjectStoreName], 'readwrite');
      const objectStore = transaction.objectStore(this.dbObjectStoreName);
      const request = objectStore.delete(key);

      request.onsuccess = () => {
        resolve('!! Data successfully deleted');
      };

      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  };

  // 데이터 저장
  setData = (key, value) => {
    // const db = await openDB();

    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.dbObjectStoreName], 'readwrite');
      const objectStore = transaction.objectStore(this.dbObjectStoreName);
      const request = objectStore.put({ key, value });

      request.onsuccess = () => {
        resolve('!! Data successfully added');
      };

      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  };

  public async read(key) {
    return await this.readData(key);
  }

  public async delete(key) {
    return await this.deleteData(key);
  }

  public async set(key, value) {
    return await this.setData(key, value);
  }
}

export default new IndexedDB();
