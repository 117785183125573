import { safeLocalStorage } from '~/libs/safeLocalStorage';

export class StorageItem {
  key: string;
  storage: Storage;

  constructor(key: string, storage: Storage) {
    this.key = key;
    this.storage = storage;
  }

  static local(key: string): StorageItem {
    return new StorageItem(key, safeLocalStorage);
  }

  static session(key: string): StorageItem {
    return new StorageItem(key, window.sessionStorage);
  }

  get(): string | null {
    return this.storage.getItem(this.key);
  }

  set(value: string): void {
    this.storage.setItem(this.key, value);
  }

  remove(): void {
    this.storage.removeItem(this.key);
  }
}
