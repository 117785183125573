import { userCommonService } from '.';

interface State {
  authCode: string | null;
  email: string | null;
  authBtnName: string | null;
  serverTime: number;
}

export const useUserCommonStore = defineStore('user-common', {
  state: (): State => ({
    authCode: null,
    email: null,
    authBtnName: null,
    serverTime: 0,
  }),
  actions: {
    async fetchServerTime() {
      const { data } = await userCommonService.getServerTime();
      this.serverTime = data.data.items;
      return data;
    },
    async petchMarketingYn(marketingYn: 'Y' | 'N') {
      const res = await userCommonService.petchMarketingYn(marketingYn);
      return res;
    },
  },
  getters: {
    getChangeInfo(state): any {
      return {
        authCode: state.authCode,
        email: state.email,
        authBtnName: state.authBtnName,
      };
    },
  },
});
