import { type ApiResponseInner } from '~/types/global';

import BaseService from '../BaseService';
import { type CommonCode, type s3FileInfo } from './type';

class CommonService extends BaseService {
  constructor() {
    super('/admin/common');
  }

  getAllCommonCode() {
    return this.get<{ items: CommonCode[] }>('/code/list');
  }

  async getS3upload(params: any) {
    const res = await this.post<ApiResponseInner<s3FileInfo>>('/file/s3upload', params, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return res;
  }

  async getS3uploadEditor(params: any) {
    const res = await this.post<ApiResponseInner<s3FileInfo>>('/file/s3upload/editor', params, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return res;
  }
}

export const commonService = new CommonService();
