import { safeDateParse } from '~/libs/dateUtils';

export default (value: any, format: string = 'YYYY.MM.DD') => {
  if (value === undefined || value === null || value === '') {
    return '';
  } else {
    // https://day.js.org/docs/en/display/format
    return safeDateParse(value).format(format);
  }
};
