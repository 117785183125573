export default {
  PRODUCT_THUMBNAIL: {
    s: 'md',
  },
  PRODUCT_MAIN_THUMBNAIL: {
    s: 'lg',
    q: 100,
  },
  PRODUCT_DETAIL_CONTENT: {
    s: 'original',
    maxW: 1200,
  },
  COMMUNITY_THUMBNAIL: {
    s: 'sm',
    q: 60,
  },
  COMMUNITY_CONTENT: {
    s: 'original',
    q: 80,
    maxW: 1200,
  },
  COMMUNITY_EDIT_CONTENT: {
    q: 50,
  },
  PROFILE_IMG: {
    w: 196,
    h: 196,
    q: 100,
  },
  EVENT_IMG: {
    q: 80,
  },
  BANNER_IMG: {
    s: 'original',
    q: 100,
  },
};

export const ImageTypeEnums = {
  PRODUCT_THUMBNAIL: 'PRODUCT_THUMBNAIL',
  PRODUCT_MAIN_THUMBNAIL: 'PRODUCT_MAIN_THUMBNAIL',
  PRODUCT_DETAIL_CONTENT: 'PRODUCT_DETAIL_CONTENT',
  COMMUNITY_THUMBNAIL: 'COMMUNITY_THUMBNAIL',
  COMMUNITY_CONTENT: 'COMMUNITY_CONTENT',
  COMMUNITY_EDIT_CONTENT: 'COMMUNITY_EDIT_CONTENT',
  PROFILE_IMG: 'PROFILE_IMG',
  EVENT_IMG: 'EVENT_IMG',
  BANNER_IMG: 'BANNER_IMG',
} as const;

export type ImageType = keyof typeof ImageTypeEnums;
