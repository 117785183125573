<template>
  <i>
    <svg width="100%" height="100%" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.375 1.375C4.09683 1.375 2.25 3.22183 2.25 5.5V16.5C2.25 18.7782 4.09683 20.625 6.375 20.625H17.375C19.6532 20.625 21.5 18.7782 21.5 16.5V5.5C21.5 3.22183 19.6532 1.375 17.375 1.375H6.375ZM0.875 5.5C0.875 2.46243 3.33743 0 6.375 0H17.375C20.4126 0 22.875 2.46243 22.875 5.5V16.5C22.875 19.5376 20.4126 22 17.375 22H6.375C3.33743 22 0.875 19.5376 0.875 16.5V5.5Z"
        fill="currentColor" />
      <path
        d="M16.5316 10.4301C16.7116 10.4301 16.8749 10.4134 17.0216 10.3801C17.1749 10.3468 17.3049 10.2901 17.4116 10.2101C17.5249 10.1301 17.6116 10.0268 17.6716 9.9001C17.7382 9.76676 17.7716 9.60343 17.7716 9.4101C17.7716 9.03676 17.6716 8.77676 17.4716 8.6301C17.2716 8.48343 16.9716 8.4101 16.5716 8.4101H15.6016V10.4301H16.5316ZM16.7216 13.5701C17.1416 13.5701 17.4616 13.4801 17.6816 13.3001C17.9016 13.1134 18.0116 12.8268 18.0116 12.4401C18.0116 12.0601 17.8949 11.7868 17.6616 11.6201C17.4349 11.4534 17.1049 11.3701 16.6716 11.3701H15.6016V13.5701H16.7216ZM14.1016 7.3501H16.8316C17.6649 7.3501 18.2782 7.5101 18.6716 7.8301C19.0649 8.14343 19.2616 8.59676 19.2616 9.1901C19.2616 9.58343 19.1682 9.91676 18.9816 10.1901C18.7949 10.4568 18.4849 10.6568 18.0516 10.7901V10.8301C18.5182 10.9101 18.8849 11.0868 19.1516 11.3601C19.4249 11.6268 19.5616 12.0268 19.5616 12.5601C19.5616 12.8601 19.5082 13.1368 19.4016 13.3901C19.3016 13.6434 19.1449 13.8634 18.9316 14.0501C18.7249 14.2301 18.4582 14.3734 18.1316 14.4801C17.8116 14.5868 17.4316 14.6401 16.9916 14.6401H14.1016V7.3501Z"
        fill="currentColor" />
      <path
        d="M4.07422 7.3501H5.59422L6.75422 12.5201H6.79422L8.18422 7.3501H9.42422L10.7842 12.5201H10.8342L12.0142 7.3501H13.3542L11.5942 14.6401H10.0642L8.76422 9.5601H8.73422L7.36422 14.6401H5.84422L4.07422 7.3501Z"
        fill="currentColor" />
    </svg>
  </i>
</template>
<style scoped lang="scss">
i {
  width: 22px;
  height: 22px;
  display: inline-block;
}
</style>
