import validate from "/home/runner/work/weolbu-frontend/weolbu-frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/middleware/auth.global.ts";
import manifest_45route_45rule from "/home/runner/work/weolbu-frontend/weolbu-frontend/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/middleware/auth.ts")
}