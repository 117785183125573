import Utility from '~/services/Utility';
import { useUserAuthStore } from '~/services/userAuth';

import {
  type GetUserGnbCategory,
  type GetUserGnbCategoryTree,
  type MenuItem,
  type UserGnbCategory,
  userGnbService,
} from '.';

interface State {
  categorys: UserGnbCategory[];
  treeCategorys: MenuItem[];
}

export const useUserGnbStore = defineStore('user-gnb', {
  state: (): State => ({
    categorys: [],
    treeCategorys: [],
  }),
  actions: {
    // any 보다는 interface를 사용하면 좋습니다..!
    async fetchUserGnbList() {
      const res = await userGnbService.getCategoryList();
      this.categorys = res.data.items;
      this.treeCategorys = Utility.categoryToTree(this.categorys);
      return res;
    },
    async fetchUserLastProgress() {
      const userAuthStore = useUserAuthStore();
      const res = await userGnbService.getLastProgress();
      if (userAuthStore.user != null) {
        userAuthStore.user!.lastProgress = res.data?.data?.progress;
      }
      return res;
    },
  },
  getters: {
    getGnbListForStructuredData({ categorys }): GetUserGnbCategory[] {
      return categorys
        .filter(({ useYn }) => useYn === 'Y')
        .map((item) => {
          return {
            ...item,
            title: `${item.cateName} | 월급쟁이부자들`,
            linkUrl: `/class?cateSeq=${item.cateSeq}`,
          };
        });
    },
    getUserGnbList({ categorys }): GetUserGnbCategory[] {
      return categorys
        .filter(({ useYn }) => useYn === 'Y')
        .map((item) => {
          return { ...item, label: item.cateName };
        });
    },
    getUserGnbTree({ categorys }): GetUserGnbCategoryTree[] {
      const clone: GetUserGnbCategoryTree[] = categorys
        .filter(({ useYn }) => useYn === 'Y')
        .map((item) => {
          return { ...item, label: '', items: [], to: `/class?cateSeq=${item.cateSeq}` };
        });
      const tree = clone.filter(({ catePSeq }) => catePSeq === null);
      const childs = clone.filter(({ catePSeq }) => catePSeq !== null);
      childs.forEach((item) => {
        item.label = item.cateName;
        item.to = `/class?cateSeq=${item.cateSeq}`; // Add url field to child items as well
        const exist = tree.findIndex(({ cateSeq }) => cateSeq === item.catePSeq);
        if (exist > -1) tree[exist].items.push(item);
      });
      return tree;
    },
  },
});
