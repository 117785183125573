<script setup lang="ts">
import { Modal } from 'jenesius-vue-modal';

import { type ModalActionType } from '~/types/global';

const action = ref<ModalActionType>('close');
withDefaults(
  defineProps<{
    title?: string;
    message?: string;
  }>(),
  {
    title: '확인',
    message: '',
  },
);
const emit = defineEmits<{
  // @ts-ignore
  (e: Modal.EVENT_PROMPT, action: string): void;
}>();
const route = useRoute();
watch(
  () => route,
  () => {
    handleVisible.value = false;
  },
  { deep: true },
);

const handleVisible = computed<boolean>({
  get: () => {
    return true;
  },
  set: (val) => {
    if (!val) {
      nextTick(() => {
        emit(Modal.EVENT_PROMPT, action.value);
      });
    }
  },
});
</script>

<template>
  <Dialog
    :header="title"
    v-model:visible="handleVisible"
    @update:visible="handleVisible"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :modal="true"
    class="wb-confirm">
    <p class="text-center whitespace-pre-line" v-html="message"></p>
    <template #footer>
      <Button
        label="취소"
        text
        class="w-full"
        @click="
          action = 'reject';
          handleVisible = false;
        " />
      <hr />
      <Button
        label="확인"
        text
        class="w-full"
        @click="
          action = 'accept';
          handleVisible = false;
        "
        autofocus />
    </template>
  </Dialog>
</template>
