<script setup lang="ts">
import { isIOS, isMobileDevice } from '~/utils/browserUtil';

import { providePWAPromptEventContext } from '../context';
import type { BeforeInstallPromptEvent } from '../type';
import { isPWA } from '../utils/isPWA';

const promptEvent = ref<BeforeInstallPromptEvent | null>(null);

const isInstallable = computed<boolean>(() => {
  if (isIOS()) {
    // iOS는 브라우저에서 PWA 설치 여부를 알 수 없기 때문에, PWA로 실행하지 않은 경우에만 설치 가능하다고 판단한다.
    return !isPWA();
  }
  if (isMobileDevice()) {
    // Android는 PWA 설치 여부를 beforeinstallprompt 이벤트 실행 여부를 통해 알 수 있기 때문에, 이벤트가 발생하지 않은 경우에만 설치 가능하다고 판단한다.
    return !!promptEvent?.value;
  }
  return false;
});

const installPWAForNotIOSDevices = () => {
  promptEvent?.value?.prompt();
  promptEvent?.value?.userChoice.then((choiceResult: any) => {
    if (choiceResult.outcome === 'accepted') {
      // User accepted the A2HS prompt
    } else {
      // User dismissed the A2HS prompt
    }
  });
};

const handleBeforeInstallPrompt = (e: Event) => {
  e.preventDefault();
  promptEvent.value = e as BeforeInstallPromptEvent;
};

onBeforeMount(() => {
  window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
});

onBeforeUnmount(() => {
  window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
});

providePWAPromptEventContext({ promptEvent, isInstallable, installPWAForNotIOSDevices });
</script>

<template>
  <slot />
</template>
