import { type SubBanner } from '~/services/userCommon';

import BaseService from '../BaseService';

class UserCommonService extends BaseService {
  constructor() {
    super('/user/common');
  }

  getSubBannerInfo(designSeq: number | string) {
    return this.get<{ items: SubBanner }>(`/banner/info/${designSeq}`);
  }

  getServerTime() {
    return this.get<{ items: number }>('/server-time');
  }
  // 마케팅 수신여부 수정
  petchMarketingYn(marketingYn: 'Y' | 'N') {
    return this.patchV2<unknown>(`/marketing`, { body: { marketingYn } });
  }
}

export const userCommonService = new UserCommonService();
