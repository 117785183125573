export default () => ({
  authorize: () => `/authorize`,
  classEventId: (id: string | number) => `/class/event/${id}`,
  classEventDiscount: () => `/class/event/discount`,
  classEvent: () => `/class/event`,
  classEventIntroduceFriend: () => `/class/event/introduce-friend`,
  classEventLunarNewYearPromotionTeaser: () => `/class/event/lunar-new-year-promotion-teaser`,
  classEventLunarNewYearPromotion: () => `/class/event/lunar-new-year-promotion`,
  classEventSummerDiscount: () => `/class/event/summer-discount`,
  classEventWithHj: () => `/class/event/with-hj`,
  classEventWithNnwee: () => `/class/event/with-nnwee`,
  class: () => `/class`,
  communityId: (id: string | number) => `/community/${id}`,
  communityForm: () => `/community/form`,
  communityFormId: (id: string | number) => `/community/form/${id}`,
  community: () => `/community`,
  communityStudygroup: () => `/community/studygroup`,
  curriculum: () => `/curriculum`,
  findChangePw: () => `/find/change-pw`,
  findCheckId: () => `/find/check-id`,
  find: () => `/find`,
  findPw: () => `/find/pw`,
  footerCopyright: () => `/footer/copyright`,
  footerFaq: () => `/footer/faq`,
  footerNoticeNoticeSeq: (noticeSeq: string | number) => `/footer/notice/${noticeSeq}`,
  footerNotice: () => `/footer/notice`,
  footerPrivacy: () => `/footer/privacy`,
  footerTerms: () => `/footer/terms`,
  footer: () => `/footer`,
  footerMarketing: () => `/footer/marketing`,
  index: () => `/`,
  marketing: () => `/marketing`,
  migration: () => `/migration`,
  mypageCoupon: () => `/mypage/coupon`,
  mypageFaq: () => `/mypage/faq`,
  mypageFavorite: () => `/mypage/favorite`,
  mypageHonors: () => `/mypage/honors`,
  mypage: () => `/mypage`,
  mypageInfo: () => `/mypage/info`,
  mypageLeave: () => `/mypage/leave`,
  mypagePayment: () => `/mypage/payment`,
  mypagePoint: () => `/mypage/point`,
  mypageReview: () => `/mypage/review`,
  mypageStudyroom: () => `/mypage/studyroom`,
  mypageStudyroomLecture: () => `/mypage/studyroom/lecture`,
  mypageStudyroomReport: () => `/mypage/studyroom/report`,
  mypageReportEdit: () => `/mypage/report/edit`,
  notifications: () => `/notifications`,
  payment: () => `/payment`,
  product: () => `/product`,
  profileIdBadge: (id: string | number) => `/profile/${id}/badge`,
  profileIdFollow: (id: string | number) => `/profile/${id}/follow`,
  profileId: (id: string | number) => `/profile/${id}`,
  searchKeyword: (keyword: string | number) => `/search/${keyword}`,
  signup: () => `/signup`,
  wbtestId: (id: string | number) => `/wbtest/${id}`,
});
