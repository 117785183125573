import BaseService from '../BaseService';

class BascisService extends BaseService {
  constructor() {
    super('/admin/base/manage');
  }
  async getSearchRecommend() {
    const { apiBase } = useRuntimeConfig().public;
    return $fetch(`${apiBase}/v1/admin/base/manage/search/recommand`);
  }
}

export const bascisService = new BascisService();
