import BaseService from '~/services/BaseService';

import {
  type AuthMigrationSignRes,
  type AuthSignInRes,
  type FindIdOptions,
  type GetUserChannelTalkProfile,
  type LoginOptions,
  type PostUserJoinOptions,
  type PutOldIdUpdateOptions,
  type SiteMeta,
  type SocialLoginType,
  type SocialSignInPayload,
  type UserCheckOptions,
  type UserInfo,
  type UserPwChangeOptions,
} from '.';

class UserAuthService extends BaseService {
  constructor() {
    super('/user/auth');
  }

  // 회원가입
  // v1/user/auth/join
  // join(params: PostUserJoinOptions) {
  //   return this.post('/join', params);
  // }

  signUp(params: PostUserJoinOptions) {
    return this.post('/sign-up', params);
  }

  // v1/user/auth/sign-in
  login(params: LoginOptions) {
    const { responseType, ...rest } = params;
    return this.post<AuthSignInRes | AuthMigrationSignRes>('/sign-in', rest, {
      slientAlert: true,
      responseType,
    });
  }

  // 비밀번호 변경일 갱신
  renewPasswordDate() {
    return this.patchV2('/password-change-date');
  }

  session() {
    return this.post<{ items: UserInfo }>('/getLoginUserInfo');
  }

  findId(params: FindIdOptions) {
    return this.post<{ userId: string }>('/find/id', params);
  }

  phoneAuth(phone: string) {
    return this.get(`/phone/${phone}`);
  }

  requestPhoneAuth(phone: string) {
    return this.get(`/phone/${phone}`);
  }

  prePhoneAuth(name: string, phone: string, slientAlert = false) {
    return this.get(`/phone/pre/${name}/${phone}`, { slientAlert });
  }

  phoneAuthCheck(phone: string, authCode: string, slientAlert = false) {
    return this.get(`/phone/${phone}/${authCode}`, { slientAlert });
  }

  requestPhoneAuthCheck(phone: string, authCode: string, slientAlert = false) {
    return this.get(`/phone/${phone}/${authCode}`, { slientAlert });
  }

  getAccounts(phone: string, token: string) {
    return this.get(`?phoneNumber=${phone}&onetimeToken=${token}`);
  }

  userCheck(params: UserCheckOptions) {
    return this.post('/change/password/check', params);
  }

  pwdChange(params: UserPwChangeOptions) {
    return this.post('/change/password', params);
  }

  getSiteMeta() {
    return this.get<{ items: SiteMeta }>('/site');
  }

  // /v1/user/auth/sign-in/social
  socialLogin(payload: SocialSignInPayload) {
    return this.post('/sign-in/social', payload);
  }

  migrationSocial(payload: any) {
    return this.put('/migration/social', payload);
  }

  linkSocial(payload: any) {
    return this.put('/link/social', payload);
  }

  deleteSocial(socialType: SocialLoginType) {
    return this.delete('/link/social', { data: { socialType } });
  }

  duplicateNickName(nickname: string, slientAlert = false) {
    return this.get(`/duplicate/nick/${nickname}`, { slientAlert });
  }

  duplicatePhone(phone: string, slientAlert = false) {
    return this.get(`/duplicate/phone/${phone}`, { slientAlert });
  }

  duplicatePhoneOld(phone: string, slientAlert = false) {
    return this.get(`/duplicate/phone/old/${phone}`, { slientAlert });
  }

  duplicateEmail(email: string, slientAlert = false) {
    return this.get(`/duplicate/id/${email}`, { slientAlert });
  }

  duplicateEmailWidthOldId(email: string, oldId: string, socialType: string, slientAlert = false) {
    return this.get(`/duplicate/id/move`, { slientAlert, params: { email, oldId, socialType } });
  }

  duplicatePhoneWithOldId(phone: string, oldId: string, socialType: string, slientAlert = false) {
    return this.get(`/duplicate/phone/move`, { slientAlert, params: { phone, oldId, socialType } });
  }

  putOldIdUpdate(params: PutOldIdUpdateOptions) {
    return this.put('/oldid/update', params);
  }

  getUserChannelTalkProfile() {
    return this.get<{ items: GetUserChannelTalkProfile }>('/user/channelTalk');
  }

  requestCaptchaImg() {
    return this.post('/captcha', {}, { responseType: 'blob' });
  }
}

export const userAuthService = new UserAuthService();
