export const isIosChrome = () => {
  return window.navigator.userAgent.toLocaleLowerCase().match(/crios/i);
};

export const isInAppBrowser = () => {
  return window.navigator.userAgent
    .toLocaleLowerCase()
    .match(
      /naver|kakao|inapp|snapchat|line|wirtschaftswoche|thunderbird|instagram|everytimeapp|whatsapp|electron|wadiz|aliapp|zumapp|whale|band|twitter|fb_iab|fb4a|fban|fbios|fbss/i,
    );
};

export const isMobileDevice = () => {
  return /iPhone|iPad|iPod|Android/i.test(window?.navigator.userAgent);
};

export const isIOS = () => {
  return /iPhone|iPad|iPod/i.test(window?.navigator.userAgent);
};
