// @ts-nocheck

/**
 * vuelidate 빌트인 룰을 커스텀 메세지로 사용하기 위한 처리..
 * @author : Yongbum
 */
import * as validators from '@vuelidate/validators';
import _cloneDeep from 'lodash-es/cloneDeep';

import josa from './josa';

/**
 * $message arg 예시..
   {
    "$pending": false,
    "$invalid": true,
    "$params": {
        "type": "required"
    },
    "$model": "",
    "$response": false,
    "$validator": "required",
    "$propertyPath": "userId",
    "$property": "userId"
  }
   */

const copy = {};

for (let key of Object.keys(validators)) {
  const validate = validators[key];
  if (typeof validate === 'function') {
    // function일떄
    copy[key] = (...args) => {
      const options = validate(...args);
      options.$message = copy[key].$message || options.$message;
      return options;
    };
  } else {
    copy[key] = _cloneDeep(validate);
  }
}

/**
 * TODO: 메세지 변환..
 */

copy.required.$message = () => {
  return 'Value은/는 필수 입력입니다.';
};

copy.sameAs.$message = (ref: any) => {
  return `입력하신 ${ref.$params.otherName}${josa(ref.$params.otherName.trim(), '이/가')} 일치하지 않습니다.`;
};

copy.requiredIf.$message = () => {
  return 'Value은/는 필수 입력입니다.';
};

copy.minLength.$message = (ref: any) => {
  return `최소 ${ref.$params.min}자 이상 입력하시기 바랍니다.`;
};

copy.maxLength.$message = (ref: any) => {
  return `최대 ${ref.$params.max}자 이하 입력하시기 바랍니다.`;
};

export const required = copy.required;
export const requiredIf = copy.requiredIf;
export const requiredUnless = copy.requiredUnless;
export const minLength = copy.minLength;
export const maxLength = copy.maxLength;
export const maxValue = copy.maxValue;
export const minValue = copy.minValue;
export const between = copy.between;
export const alpha = copy.alpha;
export const alphaNum = copy.alphaNum;
export const numeric = copy.numeric;
export const integer = copy.integer;
export const decimal = copy.decimal;
export const email = copy.email;
export const ipAddress = copy.ipAddress;
export const macAddress = copy.macAddress;
export const sameAs = copy.sameAs;
export const url = copy.url;
export const or = copy.or;
export const and = copy.and;
export const not = copy.not;
