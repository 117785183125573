import { promptModal } from 'jenesius-vue-modal';

import { type CustomConfirmOptions } from '~/types/global';

import AppCustomConfirm from '~/components/AppCustomConfirm.vue';

export default (customConfirmOptions: CustomConfirmOptions): Promise<boolean | unknown> => {
  return new Promise(async (resolve) => {
    // SSR 에서 모달을 여는 경우, 에러 발생
    if (!$isClient()) return;

    await nextTick(); // 컨테이너가 늦게생성되는 케이스가 있기때문에 nextTick을 걸어준다.
    resolve(await promptModal(AppCustomConfirm, { customConfirmOptions }));
  });
};
