import { promptModal } from 'jenesius-vue-modal';

import AppAlert from '~/components/AppAlert.vue';

export default (message: string, helpText?: string | null): Promise<'close'> => {
  return new Promise(async (resolve) => {
    // SSR 에서 모달을 여는 경우, 에러 발생
    if (!$isClient()) {
      return;
    }

    await nextTick(); // 컨테이너가 늦게생성되는 케이스가 있기때문에 nextTick을 걸어준다.
    resolve((await promptModal(AppAlert, { message, helpText })) as 'close');
  });
};
