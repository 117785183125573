import { useCommunityStore } from '~/services/community';

import { useCommonStore } from '../services/common';

export default (type: 'kakao' | 'clipboard') => {
  const currentUrl = window.location.href;

  if (type === 'kakao') {
    if (useCommonStore().shareKakaoParams.fromPath === 'community') {
      const store = useCommunityStore();
      store.insertBoardContentsShare(
        useCommonStore().shareKakaoParams.boardSeq as number,
        useCommonStore().shareKakaoParams.boardContentsSeq as number,
      );
    }

    try {
      // kakao domain 뒤에는 무조건 '/' 이 붙어서 path 와 같이 "//" 로 돼버림. 그래서 맨처음 "/" 을 지워줌.
      if (useCommonStore().shareKakaoParams.path) {
        useCommonStore().shareKakaoParams.path = useCommonStore().shareKakaoParams.path!.startsWith('/')
          ? useCommonStore().shareKakaoParams.path!.replace('/', '')
          : useCommonStore().shareKakaoParams.path;
      }
    } catch (e) {
      console.error(e);
    }

    const { templateId, serverCallbackArgs = null, ...params } = useCommonStore().shareKakaoParams;

    // 자세한 옵션은 https://developers.kakao.com/sdk/reference/js/release/Kakao.Share.html
    window.Kakao.Share.sendCustom({
      templateId: Number(templateId),
      // 카카오톡이 설치 되지 않았을때 마켓으로 이동
      installTalk: true,
      templateArgs: params,
      serverCallbackArgs,
    });
  } else {
    navigator.clipboard.writeText(currentUrl).then(async () => {
      await $alert(`URL이 클립보드에 복사되었습니다.`);
    });
  }
};
