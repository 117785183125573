<template>
  <i>
    <svg width="100%" height="100%" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_17592_15444)">
        <path
          id="Mask"
          d="M11.0977 9.625C8.81948 9.625 6.97266 7.77817 6.97266 5.5C6.97266 3.22183 8.81948 1.375 11.0977 1.375C13.3758 1.375 15.2227 3.22183 15.2227 5.5C15.2227 7.77817 13.3758 9.625 11.0977 9.625Z"
          fill="currentColor" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.0977 2.0625C9.19918 2.0625 7.66016 3.60152 7.66016 5.5C7.66016 7.39848 9.19918 8.9375 11.0977 8.9375C12.9961 8.9375 14.5352 7.39848 14.5352 5.5C14.5352 3.60152 12.9961 2.0625 11.0977 2.0625ZM6.28516 5.5C6.28516 2.84213 8.43979 0.6875 11.0977 0.6875C13.7555 0.6875 15.9102 2.84213 15.9102 5.5C15.9102 8.15787 13.7555 10.3125 11.0977 10.3125C8.43979 10.3125 6.28516 8.15787 6.28516 5.5Z"
          fill="currentColor" />
        <path
          d="M1.01636 19.4326C1.45062 15.0576 5.79055 12.8333 11.0803 12.8333C16.4445 12.8333 20.8517 14.9354 21.18 19.4333C21.193 19.6125 21.18 20.1667 20.3383 20.1667C16.1867 20.1667 10.0175 20.1667 1.8307 20.1667C1.54972 20.1667 0.9927 19.6709 1.01636 19.4326Z"
          fill="currentColor" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.71973 19.3304C1.73421 19.3454 1.75051 19.3612 1.76831 19.3772C1.81873 19.4224 1.86858 19.4578 1.90594 19.4792H20.3383C20.411 19.4792 20.4609 19.4728 20.493 19.4661C20.3425 17.4772 19.3072 16.0213 17.6649 15.0362C15.9928 14.0331 13.6852 13.5208 11.0803 13.5208C8.52139 13.5208 6.24887 14.0604 4.58666 15.0786C2.99176 16.0556 1.95573 17.4699 1.71973 19.3304ZM3.86841 13.9061C5.80716 12.7185 8.34949 12.1458 11.0803 12.1458C13.8396 12.1458 16.4177 12.6846 18.3723 13.8571C20.352 15.0446 21.6832 16.8831 21.8657 19.3833C21.8756 19.5198 21.8875 19.8933 21.6524 20.2474C21.3844 20.6511 20.9209 20.8542 20.3383 20.8542H1.83071C1.58725 20.8542 1.37694 20.7575 1.25054 20.688C1.1058 20.6085 0.968212 20.5066 0.850303 20.4008C0.73359 20.2962 0.615198 20.169 0.521949 20.029C0.450008 19.921 0.301595 19.6734 0.332234 19.3647C0.573478 16.9343 1.91062 15.1054 3.86841 13.9061Z"
          fill="currentColor" />
      </g>
      <defs>
        <clipPath>
          <rect width="22" height="22" fill="currentColor" transform="translate(0.0976562)" />
        </clipPath>
      </defs>
    </svg>
  </i>
</template>
<style scoped lang="scss">
i {
  width: 22px;
  height: 22px;
  display: inline-block;
}
</style>
