<template>
  <i>
    <svg width="100%" height="100%" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.875 5.5C0.875 2.46243 3.33743 0 6.375 0H17.375C20.4126 0 22.875 2.46243 22.875 5.5V16.5C22.875 19.5376 20.4126 22 17.375 22H6.375C3.33743 22 0.875 19.5376 0.875 16.5V5.5ZM5.59259 7.35012H4.07259L5.84259 14.6401H7.36259L8.73259 9.56012H8.76259L10.0626 14.6401H11.5926L13.3526 7.35012H12.0126L10.8326 12.5201H10.7826L9.42259 7.35012H8.18259L6.79259 12.5201H6.75259L5.59259 7.35012ZM17.0199 10.3801C16.8733 10.4135 16.7099 10.4301 16.5299 10.4301H15.5999V8.41012H16.5699C16.9699 8.41012 17.2699 8.48345 17.4699 8.63012C17.6699 8.77678 17.7699 9.03678 17.7699 9.41012C17.7699 9.60345 17.7366 9.76678 17.6699 9.90012C17.6099 10.0268 17.5233 10.1301 17.4099 10.2101C17.3033 10.2901 17.1733 10.3468 17.0199 10.3801ZM17.6799 13.3001C17.4599 13.4801 17.1399 13.5701 16.7199 13.5701H15.5999V11.3701H16.6699C17.1033 11.3701 17.4333 11.4535 17.6599 11.6201C17.8933 11.7868 18.0099 12.0601 18.0099 12.4401C18.0099 12.8268 17.8999 13.1135 17.6799 13.3001ZM16.8299 7.35012H14.0999V14.6401H16.9899C17.4299 14.6401 17.8099 14.5868 18.1299 14.4801C18.4566 14.3735 18.7233 14.2301 18.9299 14.0501C19.1433 13.8635 19.2999 13.6435 19.3999 13.3901C19.5066 13.1368 19.5599 12.8601 19.5599 12.5601C19.5599 12.0268 19.4233 11.6268 19.1499 11.3601C18.8833 11.0868 18.5166 10.9101 18.0499 10.8301V10.7901C18.4833 10.6568 18.7933 10.4568 18.9799 10.1901C19.1666 9.91678 19.2599 9.58345 19.2599 9.19012C19.2599 8.59678 19.0633 8.14345 18.6699 7.83012C18.2766 7.51012 17.6633 7.35012 16.8299 7.35012Z"
        fill="currentColor" />
    </svg>
  </i>
</template>
<style scoped lang="scss">
i {
  width: 22px;
  height: 22px;
  display: inline-block;
}
</style>
