<script setup lang="ts">
import type { IconProps, IconThicknesses } from '../../types';

withDefaults(defineProps<IconProps>(), {
  thickness: 'extra-light',
  filled: false,
});

const iconThicknessVariants: IconThicknesses = {
  thin: 'stroke-1',
  'extra-light': 'stroke-1.5',
  light: 'stroke-2',
  regular: 'stroke-2.5',
  medium: 'stroke-3',
};

/** 12, "12" 등은 "12px" 로 변환, "12rem" 등은 그대로 반환한다. */
const getSize = (value: string | number) => {
  if (!value) {
    return 0;
  }
  return Number.isNaN(Number(value)) ? value : `${value}px`;
};
</script>

<template>
  <!-- tailwind 옵션에 important 가 켜져있기 때문에 여기에도 important 를 추가했습니다. -->
  <i :class="{ filled }" :style="size ? `width: ${getSize(size)} !important; height: ${getSize(size)} !important` : ''">
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="shrink-0"
      :class="{ [iconThicknessVariants[thickness!]]: true }">
      <slot />
    </svg>
  </i>
</template>

<style scoped>
i {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  display: inline-flex;
  :deep(*) {
    stroke: currentColor;
    vector-effect: non-scaling-stroke;
  }
  &.filled {
    :deep(*) {
      stroke: none;
      fill: currentColor;
    }
  }
}
</style>
