import * as Sentry from '@sentry/vue';
import { Provider } from '~/policy';

import { safeDateParse } from '~/libs/dateUtils';

import type { Token } from '~/services/auth';

import { StorageItem } from '~/shared/StorageItem';
import { PROVIDER_STATE_KEY_PREFIX } from '~/shared/auth/client';

import type { ApiResponse } from '~/types/global';

const DEBUG = false;

const URL_NEW_ACCESS_TOKEN = '/v1/user/auth/newAccessToken';
let isRefreshing = false;
let refreshSubscribers: Array<Function> = [];

const instance = $fetch.create({
  timeout: 60000,
  headers: { 'Content-Type': 'application/json' },
  credentials: 'include',
  onRequest: async (context) => {
    const runtime = useRuntimeConfig();

    if (context.options.useKotlinBaseUrl) {
      context.options.baseURL = runtime.public.apiBaseKotlin;
    } else {
      context.options.baseURL = runtime.public.apiBase;
    }

    // 서버 사이드에서는 토큰 관련 로직 실행하지 않음(로컬스토리지 접근 불가)
    if (!$isClient()) {
      return;
    }

    const { useUserAuthStore } = await import('~/services/userAuth');
    const userAuth = useUserAuthStore();

    const tokenData = userAuth.token;

    if (DEBUG) {
      console.log('Request:', context.request, tokenData);
    }

    // 토큰이 있지만 만료된 경우
    if (tokenData && isTokenExpired(tokenData.accessTokenExpiresIn)) {
      const retryOriginalRequest = new Promise((resolve) => {
        subscribeTokenRefresh((token: string) => {
          context.options.headers = {
            ...context.options.headers,
            authorization: `Bearer ${token}`,
          };

          resolve(context.options);
        });
      });

      // 토큰 리프레시중이 아니라면
      if (!isRefreshing) {
        isRefreshing = true;

        try {
          // 새로운 토큰 발급
          await newAccessToken(context.options);
        } catch (error) {
          console.log('!! newAccessToken error', error);
        } finally {
          isRefreshing = false;
        }
      }

      // 토큰이 리프레시중이라면, 리프레시가 완료될때까지 대기
      await retryOriginalRequest;
    }

    // 토큰 정보가 있는 경우 헤더에 추가
    if (tokenData) {
      context.options.headers = {
        ...context.options.headers,
        authorization: `Bearer ${tokenData.accessToken}`,
      };
    }
  },
  onRequestError: (context) => {
    try {
      Sentry.setExtra('calling-location', 'interceptors.request');
      Sentry.setExtra('error-request', context.request);
      Sentry.setExtra('error-response', context.response);
      Sentry.setExtra('error-response-data', context.response?._data);
      Sentry.captureException(context.error);
    } catch (err) {}

    if (context?.error?.stack.includes('TypeError: Failed to fetch')) {
      return;
    }
    $alert('API 발송요청 중 에러가 발생했습니다. 콘솔을 확인해주세요.');
  },
  onResponse: async (context) => {
    const { options, response } = context;

    // ofetch on response 핸들러는 에러 발생케이스에도 호출됨
    if (!response.ok) {
      return;
    }

    const { _data: data } = response;

    // 하위호환성...?
    // context.response._data.data = { ...context.response._data.data, data: { ...context.response._data.data } };

    if (DEBUG) {
      console.log('Response:', data);
    }

    // 서버사이드에서는 동작 X
    if (!$isClient()) {
      return;
    }

    const provider = StorageItem.local(PROVIDER_STATE_KEY_PREFIX).get() as Provider;

    // "ERROR_0014"
    if (
      [Provider.GOOGLE, Provider.KAKAO, Provider.NAVER].includes(provider) &&
      data.status?.errorCode === 'ERROR_0014'
    ) {
      return;
    }

    if (provider === Provider.GOOGLE && data.status?.errorCode === 'ERROR_1508' && data.status.errorMessage === '') {
      return;
    }

    // "ERROR_1508"
    if (
      data.status?.errorCode === 'ERROR_1513' ||
      (data.status?.errorCode === 'ERROR_1508' && data.status.errorMessage === '')
    ) {
      return;
    }

    // 서버에서 상태 에러코드와 에러메시지를 내려주는 경우 그대로 표시
    if (data.status?.errorCode) {
      // 에러코드 0065은 사용하지 않아서 제거처리하고 0077(대기신청)인 경우에는 팝업 나오도록 처리
      // @ts-ignore
      if (!options.silentAlert) {
        try {
          await $alert(`${data.status.errorMessage}`);
        } catch (e) {
          alert(`${data.status.errorMessage}`);
        }
      }
    }
  },
  onResponseError: async (context) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (DEBUG) {
      console.log('Response Error:', context);
    }

    const { error, options, request, response } = context;
    try {
      // 419 / 401 에러는 트랙킹 하지 않음
      if (![419, 401].includes(response.status)) {
        Sentry.setExtra('calling-location', 'interceptors.response');
        Sentry.setExtra('method', options.method);
        Sentry.setExtra('baseURL', options.baseURL);
        Sentry.setExtra('method', response.url);
        // Sentry.setExtra('user-data', config?.data);
        Sentry.setExtra('error-request', request);
        Sentry.setExtra('error-response', response);
        Sentry.setExtra('error-response-data', response?._data);
        Sentry.setExtra('error-response-status', response?.status);
        Sentry.captureException(response._data);
      }
    } catch (e) {}

    if (options && response) {
      if (isDeviceTokenUrl(response.url)) {
        return Promise.resolve();
      }

      if (response.status === 419) {
        const { useUserAuthStore } = await import('~/services/userAuth');
        const userAuth = useUserAuthStore();
        userAuth.logout(419);
      }

      if (response.status === 401) {
        // 401 이 떨어질일이 거의없지만 서버 상황에 따라 문제가 발생할수 있으므로
        // 401 이 떨어지면 토큰을 갱신한다.
        await newAccessToken(options);
      }

      // await $alert(`상태코드 : ${response.status} \n ${response.statusText}`);
    }
  },
});

// helper function to add subscriber
function subscribeTokenRefresh(cb: Function) {
  refreshSubscribers.push(cb);
}

// helper function to notify subscribers and clear them
function onRefreshed(token: string) {
  refreshSubscribers.map((cb) => cb(token));
  refreshSubscribers = [];
}

async function newAccessToken(config: any) {
  const { useUserAuthStore } = await import('~/services/userAuth');
  const userAuth = useUserAuthStore();
  const runtime = useRuntimeConfig();

  const tokenData = userAuth.token;

  const res = await $fetch<ApiResponse<{ token: Token }>>(URL_NEW_ACCESS_TOKEN, {
    baseURL: runtime.public.apiBase,
    method: 'POST',
    body: {
      refreshToken: tokenData?.refreshToken,
    },
  });

  if (res.status.code === 200 && $isEmpty(res.status.errorCode)) {
    const newTokenData = res.data.token;

    if (userAuth && userAuth.token && newTokenData) {
      userAuth.token.accessToken = newTokenData.accessToken;
      userAuth.token.accessTokenExpiresIn = newTokenData.accessTokenExpiresIn;

      onRefreshed(newTokenData.accessToken);
    }
  } else {
    console.log('Error refreshing token:', res.status.errorMessage);
    userAuth.logout(419);
    return Promise.reject(res.status.errorMessage);
  }
}

function isTokenExpired(expiresIn: number) {
  const now = Date.now();

  // 20초 앞당겨서 토큰 expire 시켜버림. 그래야 서버와 타이밍이 맞음.
  // 서버 시간에 딱맞게 expire 체크하면 타이밍상 401이슈가 발생.
  const expirationTime = safeDateParse(expiresIn).add(-20, 'seconds');
  const nowFormatted = safeDateParse(now).format('YYYY-MM-DD HH:mm:ss');
  const expirationTimeFormatted = safeDateParse(expirationTime).format('YYYY-MM-DD HH:mm:ss');
  // @ts-ignore
  const isExpired = now >= expirationTime;

  if (DEBUG) {
    console.log(
      `isTokenExpired now: ${nowFormatted}, expirationTime: ${expirationTimeFormatted}, isExpired:${isExpired}`,
    );
  }

  return isExpired;
}

function isDeviceTokenUrl(url?: string) {
  return url?.includes('/device/token');
}

export default instance;
