<script setup lang="ts">
import * as Sentry from '@sentry/browser';
import { useDebounceFn } from '@vueuse/core';
import { container } from 'jenesius-vue-modal';
import { NEXT_MIGRATED_PATHNAMES, NEXT_MIGRATED_PATHNAME_REGEXPS } from '~~/src/constants/next-migration';

import CriteoTag from '~/libs/CriteoTag';
import '~/libs/initializeDayjs';

import Utility from '~/services/Utility';
import { useBasicsStore } from '~/services/base';
import { useCommonStore } from '~/services/common';
import { useCouponStore } from '~/services/coupon/store';
import { useInterestStore } from '~/services/interest';
import { PWAInstallPromptEventProvider } from '~/services/pwaV2';
import { userAuthService } from '~/services/userAuth';
import { useUserAuthStore } from '~/services/userAuth/store';

import AdditionalUserInterestDialog from '~/components/common/AdditionalUserInterestDialog.vue';
import LoginDialog from '~/components/common/LoginDialog.vue';
import ShareDialog from '~/components/common/ShareDialog.vue';
import ClassWelcomeDialog from '~/components/domain/dialog/welcomeCoupon/ClassWelcomeDialog.vue';
import DefaultWelcomeDialog from '~/components/domain/dialog/welcomeCoupon/DefaultWelcomeDialog.vue';
import AppBottomNav from '~/components/layouts/default/AppBottomNav.vue';

import ABTest, { Campaign } from './libs/ABTest';
import Analytics from './libs/Analytics';
import Broadcast from './libs/Broadcast';
import ChannelService from './libs/ChannelService';
import GoogleAds from './libs/GoogleAds';
import IndexedDB from './libs/IndexedDB';
import KakaoPixel from './libs/KakaoPixel';
import NaverPremium from './libs/NaverPremium';
import Pixel from './libs/Pixel';
import TagManager from './libs/TagManager';
import Tracker from './libs/Tracker';
import { init as firebaseInit } from './libs/firebaseConfig';
import { useUserGnbStore } from './services/gnb/store';
import { useMainStore } from './services/main/store';

const runtime = useRuntimeConfig();
const router = useRouter();
const route = useRoute();
const couponStore = useCouponStore();
const userAuthStore = useUserAuthStore();
const basicStore = useBasicsStore();
const interestStore = useInterestStore();
const isBottomNavActive = ref(false);
const shareDialogRef = ref();

provide('openMobileSnsShare', () => {
  basicStore.shareMobileOpen = true;
});
provide('openPcSnsShare', (e: any) => {
  shareDialogRef.value.$refs.pcSns.toggle(e);
});

if ($isClient()) {
  // * 사용자 개인정보를 막는 크롬 익스텐션 등 문제로 에러가 발생, 에외처리 안하면 에러 페이지로 이동하는 이슈 대응
  try {
    if (!window.Kakao.isInitialized()) {
      window.Kakao.init(runtime.public.kakaoJsKey);
      ABTest.init();
    }
    Analytics.init();
    ChannelService.boot({ pluginKey: '024154f0-22c0-444c-a8a2-3a563b72323c', hideChannelButtonOnBoot: true });
    Broadcast.init();
    Pixel.init();
    NaverPremium.init();
    TagManager.init();
    KakaoPixel.init();
    CriteoTag.init();
    GoogleAds.init();
    firebaseInit();
    IndexedDB.init();
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
  }
}

const WidgetContainerModal = container;

if ($isClient()) {
  // 최초 인입에 대한 PageView
  Analytics.page({
    path: route.path,
    title: route.name?.toString(),
    url: route.fullPath,
    search: route.hash,
    extraData: {
      user: userAuthStore.user,
    },
  });

  router.onError((error, to) => {
    if (
      error.message.includes(['Failed to fetch dynamically imported module']) ||
      error.message.includes(['Avoided redundant navigation to current location']) ||
      error.message.includes(['Cannot read properties of null'])
    ) {
      window.location.assign(to.fullPath);
    }
  });
}

// Nuxt -> Next 로그인 세션 유지를 위한 쿠키
watch(
  () => [userAuthStore.token?.accessToken],
  () => {
    if (!$isClient()) {
      return;
    }

    $fetch('/nuxt-api/set-weolbu-at', { method: 'POST', body: { token: userAuthStore.token || null } });
  },
  { immediate: true, flush: 'pre' },
);

router.beforeEach((to, from, next) => {
  if (
    NEXT_MIGRATED_PATHNAMES.has(to.path) ||
    NEXT_MIGRATED_PATHNAMES.has(from.path) ||
    NEXT_MIGRATED_PATHNAME_REGEXPS.some((r) => r.test(to.path)) ||
    NEXT_MIGRATED_PATHNAME_REGEXPS.some((r) => r.test(from.path))
  ) {
    window.location.href = window.origin + to.fullPath;

    return;
  }

  next();
});

// 최초 인입이후 라우트 변경에 대한 PageView
router.afterEach((to, from, failure) => {
  // console.log('router.afterEach', to, from, failure);
  const userAuthStore = useUserAuthStore();
  // 뒤로 가기시..
  if (userAuthStore.user && basicStore.isOpen) {
    location.reload();
  }

  if (failure) {
    return;
  }

  Analytics.page({
    path: to.path,
    title: to.name?.toString(),
    url: to.fullPath,
    search: to.hash,
    extraData: {
      user: userAuthStore.user,
    },
  });

  // routing 이동시마다 channel talk check
  Utility.onChangeRoutingForChannelTalk();
});

// 카카오톡 인앱 브라우져 회피
onBeforeMount(() => {
  if (navigator && navigator.userAgent) {
    const useragt = navigator.userAgent.toLowerCase();
    const targetUrl = location.href;
    if (useragt.match(/kakaotalk/i)) {
      location.href = `kakaotalk://web/openExternal?url=${encodeURIComponent(targetUrl)}`;
    }
  }
});

onMounted(async () => {
  function setScreenSize() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  setScreenSize();
  window.addEventListener('resize', setScreenSize);

  window.addEventListener(
    'scroll',
    useDebounceFn(() => {
      const position = Math.floor(
        (window.scrollY / (document.documentElement.scrollHeight - document.documentElement.clientHeight)) * 100,
      );

      Tracker['Scroll Position']({
        position,
      });
    }, 1000),
  );

  const bootOpt: any = {
    pluginKey: '024154f0-22c0-444c-a8a2-3a563b72323c',
    hideChannelButtonOnBoot: true,
  };

  // 현재 페이지가 상품상세 페이지인지 체크
  const isProductDetailPage = route.path?.includes('/product');

  // 현재 페이지가 결제페이지인지 체크
  const isPaymentPage = route.path?.includes('/payment');

  if (!isPaymentPage && !isProductDetailPage) {
    const commonStore = useCommonStore();
    const mainStore = useMainStore();

    // common code 호출 및 admin auths 호출
    commonStore.fetchAllCommonCode();

    // 해당 API 는 index / class / community 에서만 필요함

    mainStore.fetchDesignPopup();
  }

  // 회원가입시 받는 쿠폰 조회
  couponStore.fetchSignupCoupons();

  const userGnbStore = useUserGnbStore();
  const userAuthStore = useUserAuthStore();

  // 로그인상태 및 현재 페이지가 결제페이지가 아닌경우
  if (userAuthStore.user && !isPaymentPage) {
    // ABTest 테스트 군 적용
    const expIds: Record<string, string> = {};
    try {
      for (const key of Object.keys(Campaign)) {
        const targetGroup = await ABTest.getTargetGroup(Campaign[key]);
        expIds[`ABTEST-${Campaign[key]}`] = targetGroup.toUpperCase();
      }
    } catch (error) {}

    Analytics.identify({ ...userAuthStore.user, ...expIds });
    Sentry.setUser({
      email: userAuthStore.user.email,
      id: userAuthStore.user.userId,
      username: userAuthStore.user.nickName,
    });

    const res = await userAuthService.getUserChannelTalkProfile();
    // request 가 abort 된 경우
    if (!res?.data) {
      return;
    }
    const { data } = res;
    const {
      userId,
      availablePoint,
      email,
      marketingYn,
      nickName,
      totalPaymentCount,
      totalPaymentPrice,
      userName,
      userPhone,
    } = data.data.items || {};
    bootOpt.memberId = userId;
    bootOpt.unsubscribeEmail = marketingYn === 'Y';
    bootOpt.unsubscribeTexting = marketingYn === 'Y';
    bootOpt.profile = {
      email,
      availableMileage: availablePoint,
      name: userName,
      nick: nickName,
      mobileNumber: userPhone,
      totalPurchaseCount: totalPaymentCount,
      totalPurchaseAmount: totalPaymentPrice,
    };
  }
  // 위에 로그인에서 Analytices.identify 를 호출한 경우 해당 코드가 호출되지 않아서 조건 추가
  if (!userAuthStore.user) {
    // ABTest 테스트 군 적용
    const expIds: Record<string, string> = {};
    try {
      for (const key of Object.keys(Campaign)) {
        const targetGroup = await ABTest.getTargetGroup(Campaign[key]);
        expIds[`ABTEST-${Campaign[key]}`] = targetGroup.toUpperCase();
      }
    } catch (error) {}
    Analytics.identify(expIds);
  }

  bootOpt.zIndex = 1100;
  ChannelService.boot(bootOpt, () => {
    // ssr 때문에 최초 여기서 change check
    Utility.onChangeRoutingForChannelTalk();
  });
  ChannelService.onShowMessenger(() => {
    Tracker['Click Channeltalk Floating']();
  });
});
</script>

<template>
  <div id="root" :class="{ 'bottom-nav': isBottomNavActive }">
    <WidgetContainerModal />

    <NuxtLoadingIndicator color="linear-gradient(90deg, var(--primary-200) 0%, var(--primary-600) 100%)" />

    <PWAInstallPromptEventProvider>
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>
      <ClientOnly>
        <AppBottomNav
          @active-nav="
            (active) => {
              isBottomNavActive = active;
            }
          " />
      </ClientOnly>
    </PWAInstallPromptEventProvider>

    <ClientOnly>
      <!-- 카카오 & 주소 복사 팝업 -->
      <ShareDialog ref="shareDialogRef" />

      <!-- 로그인 팝업 -->
      <LoginDialog />

      <!-- 가입 축하 쿠폰 팝업 -->
      <DefaultWelcomeDialog />
      <!-- 상페 쿠폰 팝업 -->
      <ClassWelcomeDialog />

      <!-- 관심있는 주제 수집 팝업 -->
      <AdditionalUserInterestDialog />
    </ClientOnly>

    <div v-if="runtime.public.app_env !== 'prod'" class="fixed right-0 bottom-[60px] md:right-2 md:bottom-2">
      <img src="/temp-nuxt-logo.png" width="24" height="24" />
    </div>
  </div>
</template>
<style lang="scss"></style>
