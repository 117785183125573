import axios, { type AxiosRequestConfig, type AxiosResponse } from 'axios';

// console.log("$api runtime!");

// const runtime = useRuntimeConfig();
const instance = axios.create({
  // baseURL: runtime.public.apiBase,
  // baseURL: "http://localhost:8000",
  timeout: 60 * 1000 * 5, // 동영상 업로드 등에 쓰이므로 timeout 주면 안됨.
  headers: { 'Content-Type': 'application/json' },
});

export default instance;
