import MobileDetect from 'mobile-detect';
import { type RuntimeConfig } from 'nuxt/schema';
import { Provider } from '~/policy';

import { StorageItem } from '~/shared/StorageItem';

const STATE_KEY_PREFIX = 'wb:auth:state:';
export const REDIRECT_URI = 'wb:auth:redirectUri:';
export const PROVIDER_STATE_KEY_PREFIX = 'wb:auth:provider:state:';
const PROVIDER_AUTH_URLS = {
  [Provider.GOOGLE]: 'https://accounts.google.com/o/oauth2/v2/auth',
  [Provider.NAVER]: 'https://nid.naver.com/oauth2.0/authorize',
  [Provider.KAKAO]: 'https://kauth.kakao.com/oauth/authorize',
};
// REDIRECT_URI 을 받아서 path에 따라 CLASS, COMMUNITY, ETC로 분기
export const getSignUpPath = (path: string) => {
  if (path.startsWith('/product')) {
    return 'CLASS';
  }
  if (path.startsWith('/community')) {
    return 'COMMUNITY';
  }
  return 'ETC';
};

export default class AuthClient {
  // private redirectUriItem: StorageItem;
  private stateStorageItem: StorageItem;
  private providerStateStorageItem: StorageItem;
  private runtime: RuntimeConfig = useRuntimeConfig();

  constructor() {
    // this.redirectUriItem = StorageItem.local(REDIRECT_URI);
    this.stateStorageItem = StorageItem.session(STATE_KEY_PREFIX);
    this.providerStateStorageItem = StorageItem.local(PROVIDER_STATE_KEY_PREFIX);
  }

  signIn({ provider, redirectUri }: { provider: Provider; redirectUri: string }) {
    const md = new MobileDetect(navigator.userAgent);
    const isIphone = md.is('iPhone');
    const providerUrl = PROVIDER_AUTH_URLS[provider];
    if (!providerUrl) {
      throw new Error(`Unsupported provider: ${provider}`);
    }
    // 메모 - 현재 아이폰 17 버전이상에서 리다이렉이 정상적으로 진행되지 않는 이슈가 존재함
    if (provider === 'KAKAO' && !isIphone) {
      this.providerStateStorageItem.set(provider);
      window.Kakao.Auth.authorize({
        redirectUri: this.runtime.public.PROVIDER_REDIRECT_URI,
      });
      return;
    }
    const params = this.buildAuthParams(provider);
    window.location.href = `${providerUrl}?${params.toString()}`;
  }

  private buildAuthParams(provider: Provider): URLSearchParams {
    const baseParams = {
      response_type: 'code',
      client_id: this.getClientKey(provider),
      redirect_uri: this.runtime.public.PROVIDER_REDIRECT_URI,
      state: this.generateAuthorizeState(),
    };
    if (provider === Provider.GOOGLE) {
      this.providerStateStorageItem.set(provider);
      return new URLSearchParams({
        ...baseParams,
        scope: 'openid profile email',
      });
    }
    this.providerStateStorageItem.set(provider);
    return new URLSearchParams(baseParams);
  }

  private getClientKey(provider: Provider): string {
    switch (provider) {
      case Provider.GOOGLE:
        return this.runtime.public.googleKey;
      case Provider.NAVER:
        return this.runtime.public.naverKey;
      case Provider.KAKAO:
        return this.runtime.public.kakaoKey;
      default:
        throw new Error(`Unsupported provider: ${provider}`);
    }
  }

  private generateAuthorizeState(): string {
    const state = Date.now().toString(36) + Math.random().toString(36).substring(2);
    this.stateStorageItem.set(state);
    return state;
  }

  static create(): AuthClient {
    return new AuthClient();
  }
}
