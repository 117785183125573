import { useUserProfileStore } from '~/services/profile';
import { useUserAuthStore } from '~/services/userAuth';

class Broadcast {
  bc?: BroadcastChannel = null;

  init() {
    // check if broadcast channel is supported
    if (!('BroadcastChannel' in window)) {
      return;
    }
    this.bc = new BroadcastChannel('weolbu-bc');
    this.bc.onmessage = this.broadcastEventHandler;
  }

  postMessage = (message: string) => {
    this.bc?.postMessage(message);
  };

  // broadcast event - 같은 탭에서는 발생하지 않음
  broadcastEventHandler = (event: MessageEvent) => {
    console.log('BroadcastEventHandler :>>', event);
    const { data } = event;

    switch (data) {
      case 'logout':
        useUserAuthStore().$reset();
        useUserProfileStore().$reset();
        localStorage.removeItem('user-auth');
        break;
      case 'login':
        window.location.reload();
        break;
    }
  };
}

export default new Broadcast();
