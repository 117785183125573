<template>
  <i>
    <svg width="100%" height="100%" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_17592_184)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.791351 14.6667C0.791351 10.9963 3.76679 8.02083 7.43718 8.02083C11.1076 8.02083 14.083 10.9963 14.083 14.6667C14.083 18.3371 11.1076 21.3125 7.43718 21.3125C6.50851 21.3125 5.62283 21.1216 4.8185 20.7765L2.35707 21.2346C1.9115 21.3175 1.45369 21.1755 1.13322 20.8551L1.61935 20.3689L1.13322 20.8551C0.812744 20.5346 0.670776 20.0768 0.753701 19.6312L1.21955 17.1281C1.22397 17.1044 1.22899 17.0809 1.23461 17.0576C0.948152 16.3149 0.791351 15.5084 0.791351 14.6667ZM7.43718 9.39583C4.52618 9.39583 2.16635 11.7557 2.16635 14.6667C2.16635 15.4153 2.32201 16.1257 2.60206 16.7689L2.7384 17.082L2.57133 17.3797L2.57129 17.3798L2.57134 17.3797L2.10549 19.8828L4.60858 19.4169L4.6086 19.417L4.89399 19.2951L5.17417 19.4285C5.85902 19.7546 6.62579 19.9375 7.43718 19.9375C10.3482 19.9375 12.708 17.5777 12.708 14.6667C12.708 11.7557 10.3482 9.39583 7.43718 9.39583Z"
          fill="currentColor" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.332 2.0625C9.29658 2.0625 5.99899 5.22363 5.78102 9.20426C5.7734 9.34347 5.76953 9.48375 5.76953 9.625H4.39453C4.39453 9.45866 4.39908 9.29331 4.40808 9.12908C4.66575 4.42338 8.56238 0.6875 13.332 0.6875C18.2681 0.6875 22.2695 4.68895 22.2695 9.625C22.2695 10.9516 21.9801 12.2123 21.4603 13.3459L22.0652 16.5957C22.1481 17.0413 22.0061 17.4991 21.6857 17.8196C21.3652 18.1401 20.9074 18.282 20.4618 18.1991L17.3361 17.6174C16.1309 18.2223 14.7703 18.5625 13.332 18.5625C13.0369 18.5625 12.745 18.5482 12.4569 18.5202L12.59 17.1516C12.834 17.1753 13.0815 17.1875 13.332 17.1875C14.6254 17.1875 15.841 16.8634 16.9041 16.2925L17.1173 16.1781L20.7134 16.8473L20.0684 13.3815L20.0681 13.3801L20.0257 13.1554L20.1264 12.95C20.6181 11.9473 20.8945 10.8194 20.8945 9.625C20.8945 5.44835 17.5087 2.0625 13.332 2.0625Z"
          fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_17592_184">
          <rect width="22" height="22" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  </i>
</template>
<style scoped lang="scss">
i {
  width: 22px;
  height: 22px;
  display: inline-block;
}
</style>
