<script setup lang="ts">
import { useBasicsStore } from '~/services/base';

const props = defineProps<{
  captchaUrl: string;
}>();
const emits = defineEmits(['signin', 'signup']);

const router = useRouter();

const basicStore = useBasicsStore();

const { isMobile } = useMobile();

const onGotoPage = (path: string) => {
  basicStore.setLoginModalState(false);
  router.push({
    path,
  });
};
</script>

<template>
  <div
    class="wb-dialog-login__block wb-dialog-login__block--margin-top-16"
    :class="[
      props.captchaUrl && isMobile ? 'wb-dialog-login__block--margin-top-20' : 'wb-dialog-login__block--margin-top-32',
    ]">
    <Button label="로그인" class="w-full wb-dialog-login__normal-login-btn" @click="emits('signin')" />
  </div>
  <div class="wb-dialog-login__block wb-dialog-login__block--pale-sky">
    <Button link label="아이디(계정)" @click="onGotoPage('/find')" />
    <span
      style="color: rgba(101, 111, 133, 1); display: inline-block; padding: 0 4px; font-weight: 500; font-size: 16px">
      ·
    </span>
    <Button link label="비밀번호 찾기" @click="onGotoPage('/find/pw')" />
    <span style="color: rgba(9, 30, 66, 0.16); display: inline-block; padding: 0 8px"> ㅣ </span>
    <Button link label="이메일 회원가입" @click="emits('signup')" />
  </div>
</template>

<style lang="scss"></style>
