/**
 * beforeRouterEnter 전역
 * 전역을 사용하지 않고 싶다면, .global suffix 를 빼고 작성하면됩니다.
 * 아닌경우 그냥 작성
 */
import { useSeo } from '~/composables/useSeo';

import { useBasicsStore } from '~/services/base';

import { useCommunityStore } from '../services/community';
import { useUserAuthStore } from '../services/userAuth';

const ONLY_ANONYMOUS = [
  'login',
  'login-other',
  'signup',
  'signup-pre',
  'find-index',
  'find-index-pw',
  'find-check-id',
  'find-change-pw',
];

// const SCROLL_TOP = []
export default defineNuxtRouteMiddleware((to, from) => {
  const userAuth = useUserAuthStore();
  const communityStore = useCommunityStore();
  const basicsStore = useBasicsStore();

  const nuxtApp = useNuxtApp();
  if (from.name !== 'community-form-id') {
    // TODO: 클라이언트 사이드이고, initial client render가 아닐 경우에만 from을 기반으로 세팅
    if (typeof window !== 'undefined') {
      sessionStorage.setItem('prevRoute', from.name);
      sessionStorage.setItem('prevPath', from.fullPath);
    }
  }

  // routing 캐싱용으로 사용. 과거 라우팅 정보를 저장해둠.
  // @ts-ignore
  Object.assign(basicsStore.route, {
    to,
    from,
  });
  // 페이지 푸터만 상단 처리 수정
  if (to.path.startsWith('/footer')) {
    if ($isClient() == true) {
      window.scrollTo(0, 0);
    }
  }
  if ((to.path == '/product' || to.path == '/') == false) {
    useSeo().setSeoMeta(null);
  }

  // @ts-ignore
  if (to.name !== from.name && ['community-id', 'community'].includes(from.name)) {
    communityStore.contentCache = true;
  } else {
    communityStore.contentCache = false;
  }

  const userStore = useUserAuthStore();
  if (!$isEmpty(userStore.user) && typeof to.name === 'string' && ONLY_ANONYMOUS.includes(to.name)) {
    return navigateTo('/');
  }
});
