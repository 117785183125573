import dayjs from 'dayjs';

import { safeDateParse } from '~/libs/dateUtils';

export const getRemainingTimeToMidnight = (currentDate = new Date()) => {
  const current = safeDateParse(currentDate);
  const midnight = safeDateParse(currentDate).add(1, 'day').startOf('date');

  const diff = midnight.diff(current);
  return dayjs.duration(diff).format('HH:mm:ss');
};

export const moveArrayItem = (array: any[], to: number, from: number) => {
  const item = array[from];
  array.splice(from, 1);
  array.splice(to, 0, item);
  return array;
};

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const defer = <T = unknown>() => {
  let resolve: (value?: T) => void = () => {};
  let reject = () => {};
  const promise = new Promise((_resolve, _reject) => {
    [resolve, reject] = [_resolve, _reject];
  });
  return { promise, reject, resolve };
};
