import { isNumber } from 'lodash-es';

import { safeDateParse } from '~/libs/dateUtils';

/**
 * @summary client-side cache를 사용하기 위한 option을 설정
 * @description _fetchedAt: cache된 데이터의 fetch 시간
 */
const withCacheOption = <T>(options: UseAsyncDataWrapperOptions<T>) => {
  if (!isNumber(options.cache)) {
    return options;
  }

  const nuxtApp = useNuxtApp();
  const cacheMinutes = options.cache;
  const cacheConfig: AsyncDataOptions<T> = {
    transform: async (data) => {
      return {
        ...((await options.transform?.(data)) || data),
        _fetchedAt: safeDateParse(0),
      };
    },
    getCachedData: (key) => {
      const cachedData = nuxtApp.payload.data[key] || nuxtApp.static.data[key];
      if (!cachedData) {
        return null;
      }

      const expirationDate = safeDateParse(cachedData._fetchedAt).add(cacheMinutes, 'minute');
      const isExpired = safeDateParse(cachedData._fetchedAt).isAfter(expirationDate);
      return isExpired ? null : cachedData;
    },
  };

  return { ...options, ...cacheConfig } as UseAsyncDataWrapperOptions<T>;
};

export type UseAsyncDataWrapperOptions<T> = Partial<
  Pick<AsyncDataOptions<T>, 'lazy' | 'server' | 'dedupe' | 'watch' | 'transform' | 'immediate'> & {
    cache: 'no-store' | number; // 'no-store' | number (cache minutes for store)
  }
>;

const useAsyncDataWrapper = <T>(
  key: string,
  handler: () => Promise<T>,
  options: UseAsyncDataWrapperOptions<T> = {},
) => {
  return useAsyncData(key, handler, { dedupe: 'defer', ...withCacheOption(options) });
};

export default useAsyncDataWrapper;
