import { type ApiResponse } from '~/types/global';

import BaseService from '../BaseService';
import type { MyProfile } from './type';

class UserProfileService extends BaseService {
  constructor() {
    super('/user/profile');
  }

  fetchMe() {
    return this.get(`/me`, { slientAlert: true });
  }

  fetchMeV2() {
    return this.getV2<ApiResponse<{ meResponse: MyProfile }>>(`/me`);
  }

  // 사용자 프로필 정보 업데이트
  updateMyProfile(params) {
    return this.put(`/me`, params);
  }
}

export const userProfileService = new UserProfileService();
