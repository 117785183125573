import Utility from '~/services/Utility';

import { type Coupon, couponService } from '.';

interface State {
  welcomePopupVisible: boolean;
  classWelcomePopupVisible: boolean;
  couponList: Coupon[] | null;
  totalDiscountedPrice: number;
  mostDiscountedCoupon: Coupon | null;
}

export const useCouponStore = defineStore('user-coupon', {
  state: (): State => ({
    welcomePopupVisible: false,
    classWelcomePopupVisible: false, // 상페 페이지에서만 사용
    couponList: [],
    totalDiscountedPrice: 0,
    mostDiscountedCoupon: null,
  }),
  actions: {
    async fetchSignupCoupons() {
      const res = await couponService.getSignupCoupons();
      if (!res?.data) {
        return {};
      }
      const { data } = res;
      this.couponList = data.data.items;
      this.totalDiscountedPrice = getTotalDiscountedPrice(data.data.items);
      this.mostDiscountedCoupon = getMostDiscountedCoupon(data.data.items);
      return data;
    },
    setWelcomePopup(visible) {
      this.welcomePopupVisible = visible;
    },
    setClassWelcomePopup(visible) {
      this.classWelcomePopupVisible = visible;
    },
  },
  getters: {
    getWelcomePopupVisible(state): any {
      return state.welcomePopupVisible;
    },
    getClassWelcomePopupVisible(state): any {
      return state.classWelcomePopupVisible;
    },
    getCouponList(state): any {
      return {
        couponList: state.couponList,
      };
    },
    getTotalDiscountedPrice(state, isShort = false) {
      return `${Utility.setCommaPer3Digit(state.totalDiscountedPrice)}`;
    },
    getMostDiscountedCouponPrice(state) {
      return `${state.mostDiscountedCoupon?.discountPrice / 10000}만원`;
    },
    getMostDiscountedCoupon(state) {
      return state.mostDiscountedCoupon;
    },
  },
});

const getTotalDiscountedPrice = (couponList) => {
  if (couponList?.length === 0) {
    return 0;
  }

  let totalDiscounted = 0;
  couponList?.forEach((coupon) => {
    let discount = 0;

    if (coupon.discountType === 'P') {
      // 정률 타입 쿠폰
      discount = Number(coupon.maxDiscountPrice);
    } else if (coupon.discountType === 'W') {
      // 정액 타입 쿠폰
      discount = Number(coupon.discountPrice);
    }
    totalDiscounted += discount;
  });
  return totalDiscounted;
};

const getMostDiscountedCoupon = (couponList) => {
  if (couponList?.length === 0) {
    return null;
  }

  let maxDiscount = 0;
  let mostDiscountCoupon: Coupon | null = null;

  couponList?.forEach((coupon) => {
    let discount = 0;

    // 정률 타입 쿠폰
    if (coupon.discountType === 'P') {
      // 상품의 가격을 알수 없으므로 maxDiscount 를 최대 할인가로 측정
      discount = coupon.maxDiscount ?? 0;
    } else if (coupon.discountType === 'W') {
      // 정액 타입 쿠폰
      discount = Number(coupon.discountPrice);
    }
    if (discount > maxDiscount) {
      maxDiscount = discount;
      mostDiscountCoupon = coupon;
    }
  });
  return mostDiscountCoupon;
};
