import { defineNuxtPlugin, useRequestHeaders } from '#app';

import { useMainStore } from '~/services/main';

export default defineNuxtPlugin(({ $router }) => {
  const router = $router;
  router.beforeEach((to, from, next) => {
    const mainStore = useMainStore();

    // 모바일 베스트 리뷰 팝업이 열려있는 경우, 라우트 이동을 중단하고 팝업을 닫는다
    if (mainStore.getMobileBestReviewPopup) {
      mainStore.setMobileBestReviewPopup(false);
      // 라우트 이동을 중단
      next(false);
    } else {
      // 정상적으로 라우트 이동
      next();
    }
  });
});
