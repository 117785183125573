import { type Coupon } from '~/services/coupon';

import BaseService from '../BaseService';

class CouponService extends BaseService {
  constructor() {
    super('/user/coupons');
  }

  getSignupCoupons() {
    return this.get<{ items: Coupon }>(`/sign-up`);
  }
}

export const couponService = new CouponService();
