const ASYNC_DATA_KEYS = {
  '/v1/user/community/category': () => '/v1/user/community/category',
  '/v1/user/community/category/board/latest-dates': () => '/v1/user/community/category/board/latest-dates',
  '/v1/user/community/board/personal': () => '/v1/user/community/board/personal',
  '/v1/user/community/board/contents/:contentId/guides': (params: { contentId: number }) =>
    `/v1/user/community/board/contents/${params.contentId}/guides`,
  '/v1/user/community/survey/group': (params: { pageNo: number; pageSize: number }) =>
    `/v1/user/community/survey/group_${params.pageNo}_${params.pageSize}`,
  '/v1/user/community/rank/follower': () => '/v1/user/community/rank/follower',
  '/v1/user/community/rank/comments': (params: { pageNo: number; pageSize: number }) =>
    `/v1/user/community/rank/comments_${params.pageNo}_${params.pageSize}`,
  [`/v1/user/community/category/:boardCategoryCd/board/:boardSeq/contents`]: (params: {
    boardCategoryCd: number | string;
    boardSeq: number | string;
    pageNo: number;
    pageSize: number;
  }) =>
    `/v1/user/community/category/${params.boardCategoryCd}/board/${params.boardSeq}/contents_${params.pageNo}_${params.pageSize}`,
  '/v1/user/community/rank/challenge': (params: { pageNo: number; pageSize: number }) =>
    `/v1/user/community/rank/challenge_${params.pageNo}_${params.pageSize}`,
  '/v1/user/community/category/board/qna/hot/contents': (params: { pageNo: number; pageSize: number }) =>
    `/v1/user/community/category/board/qna/hot/contents_${params.pageNo}_${params.pageSize}`,
  '/v1/user/community/category/follow/contents': (params: { pageNo: number; pageSize: number }) =>
    `/v1/user/community/category/follow/contents_${params.pageNo}_${params.pageSize}`,
  '/v1/user/community/essentials': () => '/v1/user/community/essentials',
  '/v1/user/community/category/hot/contents': (params: { pageNo: number; pageSize: number }) =>
    `/v1/user/community/category/hot/contents_${params.pageNo}_${params.pageSize}`,
  '/v1/user/community/board/contents/best': (params: { pageNo: number; pageSize: number }) =>
    `/v1/user/community/board/contents/best_${params.pageNo}_${params.pageSize}`,
  [`/v1/user/community/board/:boardSeq/notice`]: (params: { boardSeq: number | string }) =>
    `/v1/user/community/board/${params.boardSeq}/notice`,

  [`/v1/notifications/categories`]: () => `/v1/notifications/categories`,
  [`/v1/users/notifications`]: () => `/v1/users/notifications`,
  [`/v1/users/notifications/counts`]: () => `/v1/users/notifications/counts`,

  [`/v1/communities/contents/:contentId/comments`]: (params: { contentId: number }, options?: { server?: boolean }) =>
    `/v1/communities/contents/${params.contentId}/comments?server=${String(options?.server ?? false)}`,
  [`/v1/communities/contents/:contentId/comments?parentId`]: (params: { contentId: number; parentId: number }) =>
    `/v1/communities/contents/${params.contentId}/comments?parentId=${params.parentId}`,

  '/v1/user/notification/counts': () => '/v1/user/notification/counts',

  [`/v1/wb-tests/for-home`]: () => `/v1/wb-tests/for-home`,
  [`/v1/wb-tests/:wbTestId`]: (wbTestId: number) => `/v1/wb-tests/for-home/${wbTestId}`,
  [`/v1/wb-tests/:wbTestId/me`]: (wbTestId: number) => `/v1/wb-tests/for-home/${wbTestId}/me`,

  ['v1/user/community/board/contents/:contentId/count-infos']: (params: { contentId: string }) =>
    `v1/user/community/board/contents/${params.contentId}/count-infos`,

  ['/referral-code?referralType=COMMUNITY&contentId=${contentId}']: (params: { contentId: string }) =>
    `/referral-code?referralType=COMMUNITY&contentId=${params.contentId}`,

  ['/v1/search/communites']: (params: {
    keyword: string;
    pageType?: string;
    page: number;
    pageSize: number;
    orderType?: string;
    searchType?: string;
    boardId?: number;
  }) =>
    `/v1/search/communities_${params.keyword}_${params.pageType}_${params.page}_${params.pageSize}_${params.orderType}_${params.searchType}_${params.boardId}`,
  ['/v1/search/communities/searchable-boards']: () => '/v1/search/communities/searchable-boards',
  ['/v1/user/displays/:displaySeq/complete-payment-count']: (displaySeq: number) =>
    `/v1/user/displays/${displaySeq}/complete-payment-count`,
  ['community-authed-recommendations']: (contentId: number | string) => `community-authed-recommendations-${contentId}`,
  ['community-authed-recommendations-userinteresttags']: (contentId: number | string) =>
    `community-authed-recommendations-userinteresttags-${contentId}`,

  ['community-anonymous-recommendations']: (contentId: number | string) =>
    `community-anonymous-recommendations-${contentId}`,
  ['/v1/reviews/product-review-statistics:productId']: (productId: number | string) =>
    `/v1/reviews/product-review-statistics:${productId}`,
  '/v1/user/interests?version=2': () => '/v1/user/interests?version=2',
  'user-board-banners': (id: string) => `board-banners-${id}`,
  'api-oneminute-lecture': () => 'api-oneminute-lecture',

  ['abtest:/v1/product/${displaySeq}/comments?page=${page}']: (displaySeq: number | string, page: number) =>
    `abtest:/v1/product/${displaySeq}/commentspage=${page}`,
  ['abtest:/v1/product?displaySeq=${displaySeq}']: (displaySeq: number | string) =>
    `abtest:/v1/product?displaySeq=${displaySeq}`,
  ['abtest:/v1/product/review?displaySeq=${displaySeq}']: (displaySeq: number | string) =>
    `abtest:/v1/product/review?displaySeq=${displaySeq}`,
  ['abtest:/v1/product/review-stat?displaySeq=${displaySeq}']: (displaySeq: number | string) =>
    `abtest:/v1/product/review-stat?displaySeq=${displaySeq}`,
  ['abtest:/v1/product/best-review?displaySeq=${displaySeq}']: (displaySeq: number | string) =>
    `abtest:/v1/product/best-review?displaySeq=${displaySeq}`,
  ['abtest:/v1/product/options?displaySeq=${displaySeq}']: (displaySeq: number | string) =>
    `abtest:/v1/product/options?displaySeq=${displaySeq}`,
  ['abtest:/v1/product/waitlist?displaySeq=${displaySeq}']: (displaySeq: number | string) =>
    `abtest:/v1/product/waitlist?displaySeq=${displaySeq}`,
  ['abtest:/product/${displaySeq}/cdn-resource']: (displaySeq: number | string) =>
    `abtest:/product/${displaySeq}/cdn-resource`,
};

export default ASYNC_DATA_KEYS;
