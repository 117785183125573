import { type AxiosRequestConfig } from 'axios';
import { type NitroFetchOptions, type NitroFetchRequest } from 'nitropack';

import { type ApiResponse } from '~/types/global';

export interface AppRequestConfig extends AxiosRequestConfig {
  slientAlert?: boolean;
}

export default class BaseService {
  readonly prefix: string = '/v1';
  resource: string;

  constructor(resource: string) {
    this.resource = `${this.prefix}${resource}`;
  }

  get<T>(url = '', config?: AppRequestConfig) {
    return $api.get<ApiResponse<T>>(`${this.resource}${url}`, config);
  }

  getV2<T>(
    url = '',
    options?: NitroFetchOptions<NitroFetchRequest> & { silentAlert?: boolean; useKotlinBaseUrl?: boolean },
  ) {
    return $apiV2<T>(`${this.resource}${url}`, options);
  }

  post<T>(url = '', data?: any, config?: AppRequestConfig) {
    return $api.post<ApiResponse<T>>(`${this.resource}${url}`, data, config);
  }

  postV2<T>(
    url = '',
    options?: NitroFetchOptions<NitroFetchRequest> & { silentAlert?: boolean; useKotlinBaseUrl?: boolean },
  ) {
    return $apiV2<T>(`${this.resource}${url}`, { ...options, method: 'POST' });
  }

  postForm<T>(url = '', data?: any, config?: AppRequestConfig) {
    return $api.postForm<ApiResponse<T>>(`${this.resource}${url}`, data, config);
  }

  postFormV2<T>(
    url = '',
    options?: NitroFetchOptions<NitroFetchRequest> & { silentAlert?: boolean; useKotlinBaseUrl?: boolean },
  ) {
    return $apiV2<T>(`${this.resource}${url}`, {
      ...options,
      method: 'POST',
      headers: { ...options?.headers, 'Content-Type': 'multipart/form-data' },
    });
  }

  put<T>(url = '', data?: any, config?: AppRequestConfig) {
    return $api.put<ApiResponse<T>>(`${this.resource}${url}`, data, config);
  }

  putV2<T>(
    url = '',
    options?: NitroFetchOptions<NitroFetchRequest> & { silentAlert?: boolean; useKotlinBaseUrl?: boolean },
  ) {
    return $apiV2<T>(`${this.resource}${url}`, { ...options, method: 'PUT' });
  }

  patch<T>(url = '', data?: any, config?: AppRequestConfig) {
    return $api.patch<ApiResponse<T>>(`${this.resource}${url}`, data, config);
  }

  patchV2<T>(
    url = '',
    options?: NitroFetchOptions<NitroFetchRequest> & { silentAlert?: boolean; useKotlinBaseUrl?: boolean },
  ) {
    return $apiV2<T>(`${this.resource}${url}`, { ...options, method: 'PATCH' });
  }

  delete<T>(url = '', config?: AppRequestConfig) {
    return $api.delete<ApiResponse<T>>(`${this.resource}${url}`, config);
  }

  deleteV2<T>(
    url = '',
    options?: NitroFetchOptions<NitroFetchRequest> & { silentAlert?: boolean; useKotlinBaseUrl?: boolean },
  ) {
    return $apiV2<T>(`${this.resource}${url}`, { ...options, method: 'DELETE' });
  }

  postExternal<T>(url = '', data?: any, config?: AxiosRequestConfig) {
    return $apiExternal.post<ApiResponse<T>>(url, data, config);
  }

  getExternal<T>(url = '', data?: any) {
    return $apiExternal.get<ApiResponse<T>>(url, data);
  }
}
