<script setup lang="ts">
import { useThrottleFn } from '@vueuse/core';
import { isEmpty } from 'lodash-es';
import ASYNC_DATA_KEYS from '~~/src/constants/async-data-keys';
import Tracker from '~~/src/libs/Tracker';
import { useUserAuthStore } from '~~/src/services/userAuth';

import { Campaign } from '~/libs/ABTest';

import { useCommonStore } from '~/services/common';
import { interestInfo, interestInfoB } from '~/services/interest/composable/useInterestInfo';
import { useInterestStore } from '~/services/interest/store';
import { useUserProfileStore } from '~/services/profile/store';
import { useUserCommonStore } from '~/services/userCommon';

withDefaults(defineProps<{ title?: string }>(), { title: '확인' });

const router = useRouter();
const route = useRoute();
const interestStore = useInterestStore();
const userAuthStore = useUserAuthStore();
const userProfileStore = useUserProfileStore();
const userCommonStore = useUserCommonStore();
const commonStore = useCommonStore();

const isLoggedIn = computed(() => !isEmpty(userAuthStore.user));
const isInterestCheckedUser = computed(() => !isEmpty(userProfileStore.getMyProfile?.selectedInterest));
const isClassUserSegment = computed(() => userProfileStore.getMyProfile?.signUpPath === 'CLASS');
const isNonePurcahseUser = computed(() => userProfileStore.getMyProfile?.paymentFunnel === 'ZERO');
const isGroupB = useIsGroupB(Campaign['FS-460']);
const interestInfoAB = computed(() => (isGroupB.value ? interestInfoB : interestInfo));

const marketingYn = ref('N');
const interestSelect = ref(null);

const { data: interests, pending: interestsPending } = useAsyncDataWrapper(
  ASYNC_DATA_KEYS['/v1/user/interests?version=2'](),
  async () => {
    if (!isLoggedIn.value) {
      return [];
    }

    const interestsMeta = await interestStore.fetchInterestMeta();
    return interestsMeta || [];
  },
  { server: false, immediate: true, watch: [isLoggedIn] },
);

const handleVisible = computed<boolean>({
  get: () => {
    // 로그인되어 있지 않으면 항상 노출하지 않는다.
    if (!isLoggedIn.value) {
      return false;
    }

    // 관심사 목록이 로드되지 않았으면 노출하지 않는다.
    if (interestsPending.value || !interests.value || interests.value?.length <= 0) {
      return false;
    }

    // 회원가입 페이지에서는 노출하지 않는다. & 이벤트 페이지에서는 노출하지 않는다.
    if (route.path === '/signup' || route.path.startsWith('/class/event')) {
      return false;
    }

    if (
      route.path === '/' && //메인페이지라면,
      !isInterestCheckedUser.value && // 관심사가 선택되지 않았으며
      isClassUserSegment.value && // CLASS 세그먼트이며
      isNonePurcahseUser.value // 첫구매를 하지 않은 유저인 경우에 노출한다.
    ) {
      return true;
    }

    return interestStore.getInterestPopupVisible;
  },
  set: (val) => {
    interestStore.setInterestPopup(val);
  },
});

const closeModal = (e: PopStateEvent) => {
  console.log('closeModal');
  e.preventDefault();
  window.history.go(1);
};

const save = useThrottleFn(async () => {
  Tracker['Click Button']('interest-popup-button', '저장하고 추천받기', {
    selectedInterest: interestSelect.value,
    marketingYn: marketingYn.value,
    version: 2,
  });

  window.removeEventListener('popstate', closeModal);
  await interestStore.updateMyInterest([{ id: interestSelect.value, version: 2 }]);
  if (marketingYn.value === 'Y') {
    await userCommonStore.petchMarketingYn('Y');
  }
  await userProfileStore.fetchMyProfile();
  await nextTick();
  const { selectedInterest } = userProfileStore.getMyProfile;

  // 강의 추천 페이지로 이동
  if (selectedInterest) {
    await router.push(interestInfoAB.value[selectedInterest.interestId]?.redirectUrl);
    setTimeout(() => {
      interestStore.setInterestPopup(false);
      commonStore.showToast({
        text: '고객님의 관심사를 저장했어요!',
        duration: 3000,
        align: 'left',
      });
    }, 500);
  }
}, 500);

/**
 * 노출되었을 때,
 * 트래킹과
 * 브라우저 뒤로가기하면 모달 끄는 이벤트를 추가한다.
 */
watch(
  () => handleVisible.value,
  async (visible) => {
    if (visible) {
      window.history.pushState(null, '', location.href);
      window.addEventListener('popstate', closeModal);

      interestStore.setInterestPopup(true);

      Tracker['View Popup']({ popupType: 'interest-popup' });
      return;
    }

    window.removeEventListener('popstate', closeModal);
  },
  { immediate: true },
);
</script>

<template>
  <Dialog
    v-model:visible="handleVisible"
    :header="title"
    :breakpoints="{ '1199px': '500px', '960px': '100dvw', '640px': '100dvw' }"
    :modal="true"
    :closable="false"
    :draggable="false"
    :block-scroll="false"
    :base-z-index="2000"
    :auto-z-index="true"
    class="additional-interest-dialog z-[3000]">
    <template #header>
      <div class="text-neutral-1000 text-2xl font-bold md:text-center md:text-3xl">
        고객님에게 맞는<br />강의와 콘텐츠를 추천해드려요.
      </div>
    </template>
    <div class="text-base mb-6">
      <div class="mt-[48px] text-base font-bold leading-normal text-neutral-1000 mb-2 md:mt-[44px]">
        어떤 분야에 관심이 있으세요?
      </div>
      <div class="space-y-5 md:space-y-2">
        <div v-for="(parentItem, index) in interests" :key="index" class="flex flex-col gap-2">
          <label
            v-for="(item, index) in parentItem?.items"
            :key="index"
            class="p-4 border rounded cursor-pointer border-neutral-alpha-300"
            :class="{ 'border-primary-600': interestSelect === item.id }">
            <RadioButton v-model="interestSelect" :value="item.id" />
            <span class="pl-2 font-semibold text-neutral-1000 leading-normal">{{ item.title }}</span>
            <div class="text-neutral-500 text-sm font-normal leading-tight pl-8">
              {{ interestInfo[item.id]?.subTitle || '' }}
            </div>
          </label>
        </div>
        <div v-if="userProfileStore.profile?.marketingYn === 'N'" class="flex flex-row gap-2 mb-10 py-4 items-center">
          <Checkbox
            v-model="marketingYn"
            class="checkbox"
            input-id="marketing-yn"
            binary
            true-value="Y"
            false-value="N" />
          <label for="marketing-yn">
            <strong class="text-neutral-1000 text-base">할인쿠폰, 신규강의오픈 등 혜택/정보 수신 동의</strong>
          </label>
        </div>
      </div>
    </div>

    <template #footer>
      <Button
        label="저장하고 추천 받기"
        text
        class="w-full flex flex-col justify-center items-center flex-[1_0_0] text-white rounded bg-primary-600 disabled:opacity-20"
        autofocus
        :disabled="!interestSelect"
        @click="save" />
    </template>
  </Dialog>
</template>

<style lang="scss">
.additional-interest-dialog {
  width: 500px;
  @media screen and (max-width: $md) {
    min-width: 100dvw;
    width: 100%;
    margin: 0 !important;
    max-height: 100% !important;
    height: 100dvh;
  }
  .p-dialog-header {
    padding: 44px 40px 0 40px;
    display: flex;
    justify-content: center;
    position: relative;
    @media screen and (max-width: $md) {
      justify-content: flex-start;
      // border-bottom: 1px solid #eee;
      border: 0;
      padding: 24px 16px 0 16px;
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
    }
  }
  .p-dialog-content {
    padding: 0;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 0;
    @media screen and (max-width: $md) {
      height: calc(var(--vh, 1vh) * 100 - 105px);
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  .p-dialog-footer {
    padding: 0px 40px 44px;
    @media screen and (max-width: $md) {
      padding: 0px 16px 16px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
}
</style>
