import _uniq from 'lodash-es/uniq';
import type { Component } from 'vue';

import { type SeoData } from '~/services/seo';

import { type CommonCode, commonService, type s3FileInfo } from '.';

interface State {
  commonCodes: CommonCode[];
  searchKeywords: string[];
  shareKakaoParams: {
    templateId: number;
    thumb?: string;
    prod_title?: string;
    discount?: number | null;
    percent?: number | null;
    path?: string;
    domain?: string;
    summary?: string | null;
    fromPath?: string | null; // 게시글용.
    invite_code?: string | null; // 추천코드
    favorite?: number | null;
    comm_title?: string | null;
    contentnumber?: string | number | null;
    comment?: number | null;
    boardSeq?: number | null;
    boardContentsSeq?: number | null;
    user_nickname?: string | null;
    profile_path?: string | null;
    displaySeq?: string | null; // 추천코드
    optionSeq?: string | null; // 추천코드
    serverCallbackArgs?: {
      displaySeq: string;
      referralCode: string;
    };
    inviteCode?: string | null; // 추천코드
    utm_source?: string | null;
    utm_medium?: string | null;
    utm_campaign?: string | null;
  };
  shareWithInviteCodeParams: {
    referralCode: string | null;
  };
  mainSeo: SeoData | null;
  bottomOfTopButton: number | null; // wb-topbtn 의 bottom style 값
  toast: {
    text: string;
    component: Component | null;
    subText?: string;
    duration: number;
    enabled: boolean;
    icon?: boolean; // 아이콘 사용 여부. 기본값은 false. 추후 아이콘 커스텀 가능하도록 타입 변경 필요
    clickPath: string | null; // 클릭시 path. onClick으로 바뀌어야 함.
    align: 'left' | 'right';
  };
}

export const useCommonStore = defineStore('commons', {
  persist: {
    storage: process.client ? localStorage : undefined,
  },
  state: (): State => ({
    commonCodes: [],
    searchKeywords: [],
    shareKakaoParams: {} as any,
    shareClipboardParams: {} as any,
    mainSeo: null,
    bottomOfTopButton: null,
    toast: {
      text: '',
      component: null,
      duration: 3000,
      enabled: false,
      icon: true,
      subText: undefined,
      clickPath: null,
      align: 'right',
    },
    youtubeShorts: {
      isOpen: false,
      startVideoIndex: 0,
    },
  }),
  actions: {
    showToast({ text, component, subText, duration, icon, clickPath, align }: Partial<State['toast']>) {
      this.toast = {
        text: text || '',
        component: component || null,
        subText: subText || '',
        duration: duration || 3000,
        enabled: true,
        icon: icon || true,
        clickPath: clickPath || null,
        align: align || 'right',
      };

      if (duration) {
        setTimeout(() => {
          this.toast = {
            text: '',
            component: null,
            subText: '',
            duration: 3000,
            enabled: false,
            clickPath: null,
            align,
          };
        }, duration);
      }
    },
    // any 보다는 interface를 사용하면 좋습니다..!
    async fetchAllCommonCode() {
      const { data } = await commonService.getAllCommonCode();
      if (!data.status.errorCode) {
        this.commonCodes = data.data.items;
      }
    },

    async getS3upload(payload: any) {
      const { data } = await commonService.getS3upload(payload);
      return data.data.items;
    },

    async getS3uploadEditor(payload: any) {
      const { data } = await commonService.getS3uploadEditor(payload);
      return data.data.items;
    },

    getCodeName(code: string) {
      const result = this.commonCodes.find((v) => v.cd === code);
      return $isEmpty(code) || result === undefined ? '' : result.cdNm;
    },
    appendSearchKeyword(keyword: string) {
      this.searchKeywords.unshift(keyword);
      this.searchKeywords = _uniq(this.searchKeywords);
      this.searchKeywords = this.searchKeywords.slice(0, 5);
    },
    removeSearchKeyword(keyword: string) {
      this.searchKeywords = this.searchKeywords.filter((v) => v !== keyword);
    },
    setBottomOfTopButton(bottom: number | null) {
      this.bottomOfTopButton = bottom;
    },
  },
  getters: {
    getLogCodes({ commonCodes }) {
      return commonCodes.filter(({ parentCd }) => parentCd === '100000');
    },
    getJoinCodes({ commonCodes }) {
      return commonCodes.filter(({ parentCd }) => parentCd === '100001');
    },
    getInterestCodes({ commonCodes }) {
      return commonCodes.filter(({ parentCd }) => parentCd === '100002');
    },
    getPaymentTypeCodes({ commonCodes }) {
      return commonCodes.filter(({ parentCd }) => parentCd === '100003');
    },
    getPaymentStatusCodes({ commonCodes }) {
      return commonCodes.filter(({ parentCd }) => parentCd === '100004');
    },
    getPaymentRefundStatusCodes({ commonCodes }) {
      return commonCodes.filter((v) => v.parentCd === '100004' && (v.cd === '100015' || v.cd === '100016'));
    },
    getCurPosCodes({ commonCodes }) {
      return commonCodes.filter(({ parentCd }) => parentCd === '100006');
    },
    getBoardReadAuthCodes({ commonCodes }) {
      return commonCodes.filter(({ parentCd }) => parentCd === '100007');
    },
    getBoardWriteAuthCodes({ commonCodes }) {
      return commonCodes.filter(({ parentCd }) => parentCd === '100008');
    },
    getBoardCommentAuthCodes({ commonCodes }) {
      return commonCodes.filter(({ parentCd }) => parentCd === '100009');
    },
    getBoardTypeCodes({ commonCodes }) {
      return commonCodes.filter(({ parentCd }) => parentCd === '100010');
    },
    getStudyStatusCodes({ commonCodes }) {
      return commonCodes.filter(({ parentCd }) => parentCd === '100012');
    },
    getStudyStatusCodesWithAll({ commonCodes }) {
      return [{ cd: '', cdNm: '전체' }, ...commonCodes.filter(({ parentCd }) => parentCd === '100012')];
    },
    getStudyProgressStatusCodes({ commonCodes }) {
      return commonCodes.filter(({ parentCd }) => parentCd === '100013');
    },
    getPageSizes() {
      return [
        { value: 10, label: '10개씩' },
        { value: 20, label: '20개씩' },
        { value: 30, label: '30개씩' },
        { value: 40, label: '40개씩' },
        { value: 50, label: '50개씩' },
      ];
    },
    getMyPagePaymentStatusCodes({ commonCodes }) {
      // 100112 - 결제대기 제외
      return [{ cd: '', cdNm: '전체' }, ...commonCodes.filter((v) => v.parentCd === '100004' && v.cd !== '100112')];
    },
    getMyPagePreBtnPathList() {
      return [
        '/mypage/studyroom',
        '/mypage/studyroom/report',
        '/mypage/favorite',
        '/mypage/payment',
        '/mypage/review',
        '/mypage/invite',
        '/mypage/info',
        '/mypage/leave',
        '/mypage/faq',
        '/mypage/coupon',
        '/mypage/point',
        '/mypage/honors', // 아너스
      ];
    },
    getMyPageHeadHidePathList() {
      return [
        '/mypage/studyroom', // 내강의실
        '/mypage/studyroom/lecture', // 내강의실>클래스선택
        '/mypage/studyroom/report', // 내강의실>과제선택
        '/mypage/favorite', // 관심목록
        '/mypage/payment', // 구매내역
        '/mypage/review', // 후기관리
        '/mypage/invite', // 친구초대
        '/mypage/info', // 회원정보수정
        '/mypage/leave', // 회원탈퇴안내
        '/mypage/faq', // FAQ
        '/mypage/coupon', // 쿠폰
        '/mypage/point', // 포인트
        '/mypage/honors', // 아너스
      ];
    },
    getMyPageSnbHidePathList() {
      return [
        '/mypage/studyroom', // 내강의실
        '/mypage/studyroom/lecture', // 내강의실>클래스선택
        '/mypage/studyroom/report', // 내강의실>과제선택
        '/mypage/favorite', // 관심목록
        '/mypage/payment', // 구매내역
        '/mypage/review', // 후기관리
        '/mypage/invite', // 친구초대
        '/mypage/info', // 회원정보수정
        '/mypage/leave', // 회원탈퇴안내
        '/mypage/faq', // FAQ
        '/mypage/coupon', // 쿠폰
        '/mypage/point', // 포인트
        '/mypage/honors', // 아너스
      ];
    },
    getProfilePreBtnPathList() {
      return ['/follow', '/badge'];
    },
    getProfileHeadHidePathList() {
      return ['/follow', '/badge'];
    },
    getMobileGnbViewList() {
      return [
        'mypage-index', // 마이페이지-메인
        'mypage-index-studyroom-lecture', // 마이페이지-내강의실
        'profile-id', // 마이페이지-프로필
      ];
    },
    getMobileFooterViewList() {
      return [
        'mypage-index', // 마이페이지-메인
        'mypage-index-studyroom-lecture', // 마이페이지-내강의실
        'mypage-index-studyroom-report', // 마이페이지-내강의실
        'mypage-index-faq', // 마이페이지-자주묻는질문
        'profile-id', // 마이페이지-프로필
        'mypage-report-edit', // 마이페이지-과제제출
      ];
    },
    getBottomOfTopButton({ bottomOfTopButton }) {
      return bottomOfTopButton;
    },
  },
});
