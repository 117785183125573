import { defineNuxtPlugin, watch } from '#imports';
import { createGlobalState, reactiveComputed, useLocalStorage } from '@vueuse/core';
import BadgeDirective from 'primevue/badgedirective';
import ConfirmationService from 'primevue/confirmationservice';
import Ripple from 'primevue/ripple';
import StyleClass from 'primevue/styleclass';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import Vue3Toastify, { type ToastContainerOptions } from 'vue3-toastify';
import VueSocials from 'vue-socials';

import CodeHighlight from '~/directives/code';

declare interface AppState {
  theme?: string;
  darkTheme?: boolean;
}

export default defineNuxtPlugin(({ vueApp: app }) => {
  // app.use(ConfirmationService); // !! $confirm 충돌되어 주석처리
  app.use(ToastService);

  app.use(Vue3Toastify, {
    autoClose: 3000,
  } as ToastContainerOptions);

  app.use(VueSocials);

  app.directive('tooltip', Tooltip);
  app.directive('ripple', Ripple);
  app.directive('code', CodeHighlight);
  app.directive('badge', BadgeDirective);
  app.directive('styleclass', StyleClass);

  const appState = createGlobalState(() =>
    useLocalStorage<AppState>('app-state', {
      theme: 'weolbu',
      darkTheme: false,
    }),
  )();

  watch(
    () => appState.value.theme,
    (theme) => {
      useAppTheme(theme!);
    },
    { immediate: true },
  );

  return {
    provide: {
      appState: reactiveComputed(() => appState.value),
    },
  };
});

declare module '@nuxt/schema' {
  interface NuxtApp {
    $appState: AppState;
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $appState: AppState;
    outsideClickListener?: ((event: Event) => void) | null;
  }
}
