import { ref } from 'vue';

// SSR 일때 서버에서는 무조껀 false이다. 그래서 클라이언트에서만 정상 작동 한다. 서버에서 랜더링 조차 안되게 처리 하고 싶다면 $isClient() && isDesktop 으로 처리해야 한다.
// 이게 클라이언트에서만 사용해야 하니까 기본값이 false 가 아니라 'server' | 'desktop' | 'mobile' | 'tablet' 이렇게 리턴했어야 하지 않나 싶다.
// 하지만, SSR 단에서 user-agent 값으로 체크하는 로직 추가해서 이제 괜찮지 않을까 싶다.
export function useMobile() {
  const isMobile = ref(false);
  const isDesktop = ref(false);
  const isTablet = ref(false);
  const nuxtApp = useNuxtApp();

  // 클라이언트 단에서 화면으로 판단이 가능한 경우
  if ($isClient()) {
    isMobile.value = window.innerWidth <= 768;
    isDesktop.value = window.innerWidth > 992;
    isTablet.value = window.innerWidth >= 768 && window.innerWidth < 992;
  } else {
    // SSR 에서 화면으로 판단이 불가능한 경우, User-Agent로 판단
    isMobile.value = nuxtApp.$isMobileDevice();
    isDesktop.value = nuxtApp.$isDesktopDevice();
    isTablet.value = nuxtApp.$isTabletDevice();
  }

  // 컴포저블은 시간이 지남에 따라 관리되는 상태를 업데이트할 수 있습니다.
  function update() {
    isMobile.value = window.innerWidth <= 768;
    isDesktop.value = window.innerWidth > 992;
    isTablet.value = window.innerWidth >= 768 && window.innerWidth < 992;
  }

  // 컴포저블은 또한 이것을 사용하는 컴포넌트의 생명주기에 연결되어
  // 사이드 이펙트를 설정 및 해제할 수 있습니다.
  onMounted(() => window.addEventListener('resize', update));
  onUnmounted(() => window.removeEventListener('resize', update));

  // 관리 상태를 반환 값으로 노출
  return { isMobile, isDesktop, isTablet };
}
