import { createContext } from 'radix-vue';
import type { Ref } from 'vue';

import type { BeforeInstallPromptEvent } from './type';

export interface PWAPromptEventContext {
  promptEvent: Ref<BeforeInstallPromptEvent | null>;
  isInstallable: Ref<boolean>;
  installPWAForNotIOSDevices: () => void;
}

export const [injectPWAPromptEventContext, providePWAPromptEventContext] =
  createContext<PWAPromptEventContext>('promptEvent');
