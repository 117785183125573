declare global {
  interface Window {
    gtag: (...args: any[]) => void;
  }
}

class GoogleAds {
  private isEnabled: boolean = false;
  private ga4Id: string | null = null;
  private purchaseConversionTagId = 'AW-16498316025/bOlACIG3t9QZEPmlgbs9';
  private signupConversionTagId = 'AW-16498316025/Of8RCP62t9QZEPmlgbs9';

  init() {
    const {
      public: { app_env, ga4Id },
    } = useRuntimeConfig();

    if (process.env.NODE_ENV === 'production' && app_env === 'prod') {
      // 운영 환경에서만 활성화
      this.isEnabled = true;
    }

    if (ga4Id) {
      this.ga4Id = String(ga4Id);
    }
  }

  // 구매 완료 전환
  public purchaseConversion({
    value = 0,
    currency = 'KRW',
    transactionId = '',
    url = '',
  }: {
    value: number;
    currency: string;
    transactionId?: string;
    url?: string;
  }) {
    if (!this.isEnabled) {
      return;
    }

    if (!this.ga4Id) {
      console.error('Google Ads GA4 ID is not set. Please call init() first.');
      return;
    }

    if (!this.purchaseConversionTagId) {
      console.error('Purchase Complete Conversion Tag ID is not set. Please call init() first.');
      return;
    }

    if (typeof window === 'undefined' || typeof window.gtag !== 'function') {
      console.error('gtag is not defined or this is not running in a browser context.');
      return;
    }

    window.gtag('event', 'conversion', {
      send_to: this.purchaseConversionTagId,
      value: value,
      currency: currency,
      transaction_id: transactionId,
      event_callback: () => {
        if (url) {
          window.location.href = url;
        }
      },
    });
  }

  // 회원가입 완료 전환
  public signupConversion(url: string = '') {
    if (!this.isEnabled) {
      return;
    }

    if (!this.ga4Id) {
      console.error('Google Ads GA4 ID is not set. Please call init() first.');
      return;
    }

    if (!this.signupConversionTagId) {
      console.error('Signup Complete Conversion Tag ID is not set. Please call init() first.');
      return;
    }

    if (typeof window === 'undefined' || typeof window.gtag !== 'function') {
      console.error('gtag is not defined or this is not running in a browser context.');
      return;
    }

    window.gtag('event', 'conversion', {
      send_to: this.signupConversionTagId,
      event_callback: () => {
        if (url) {
          window.location.href = url;
        }
      },
    });
  }
}

export default new GoogleAds();
