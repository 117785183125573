import unhead_KgADcZ0jPj from "/home/runner/work/weolbu-frontend/weolbu-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/weolbu-frontend/weolbu-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/weolbu-frontend/weolbu-frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/weolbu-frontend/weolbu-frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/runner/work/weolbu-frontend/weolbu-frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/weolbu-frontend/weolbu-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/weolbu-frontend/weolbu-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import floating_vue_EIcJ7xiw0h from "/home/runner/work/weolbu-frontend/weolbu-frontend/.nuxt/floating-vue.mjs";
import primevue_config_nHZizNYDVK from "/home/runner/work/weolbu-frontend/weolbu-frontend/.nuxt/primevue.config.mjs";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/weolbu-frontend/weolbu-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/home/runner/work/weolbu-frontend/weolbu-frontend/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import datadog_client_no7EPD7FXN from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/plugins/datadog.client.ts";
import app_dgZTk2sWoI from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/plugins/app.ts";
import device_7hnZDrMchp from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/plugins/device.ts";
import global_dialog_module_ul7OWHvaHd from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/plugins/global-dialog.module.js";
import handling_error_rXfel481Ln from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/plugins/handling-error.ts";
import log_filter_UXa0xs5Snp from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/plugins/log-filter.ts";
import primevue_oKQ3MEGbO2 from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/plugins/primevue.ts";
import routerGuard_QbDjpzLhDr from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/plugins/routerGuard.ts";
import sentry_client_KAXFuL2wum from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/plugins/sentry.client.ts";
export default [
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  floating_vue_EIcJ7xiw0h,
  primevue_config_nHZizNYDVK,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  datadog_client_no7EPD7FXN,
  app_dgZTk2sWoI,
  device_7hnZDrMchp,
  global_dialog_module_ul7OWHvaHd,
  handling_error_rXfel481Ln,
  log_filter_UXa0xs5Snp,
  primevue_oKQ3MEGbO2,
  routerGuard_QbDjpzLhDr,
  sentry_client_KAXFuL2wum
]