export enum NaverPremiumTrackType {
  // NOTE: 1 구매완료, 2 회원가입, 3 장바구니 담기, 4 신청/예약, 5 기타
  FINISHED_PAYMENT = 1,
  SIGN_UP = 2,
  CART = 3,
  REQUEST_OR_RESERVATION = 4,
  ETC = 5,
}

class NaverPremium {
  naverPremiumId = '';
  init() {
    const {
      public: { naverPremiumId },
    } = useRuntimeConfig();
    this.naverPremiumId = naverPremiumId;
    window.wcs_add = { wa: this.naverPremiumId };
    this.track(NaverPremiumTrackType.ETC, 'load');
  }

  track(trackType: NaverPremiumTrackType, value: string) {
    if (window.wcs) {
      window.wcs.inflow();
      window.wcs_do({ cnv: window.wcs.cnv(trackType, value) });
    }
  }

  purchase(options: { resultOrderPrice: number }) {
    this.track(NaverPremiumTrackType.FINISHED_PAYMENT, String(options.resultOrderPrice || 0));
  }
}

export default new NaverPremium();
