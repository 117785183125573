import * as Sentry from '@sentry/browser';

const EXPIRES_KEY_SUFFIX = '@expires';

const serializer = JSON.stringify;

const deserializer = (value: string) => {
  try {
    const parsedValue = JSON.parse(value);
    return parsedValue;
  } catch (error) {
    // safeLocalStorage를 사용하기 이전 코드에 대한 대응.
    if (error instanceof Error && error.name === 'SyntaxError') {
      return JSON.parse(JSON.stringify(value));
    }
    Sentry.captureMessage(`deserializer 예외처리 실패: ${value}`);
    return null;
  }
};

const isLocalStorageAvailable = (() => {
  let storage: Storage | undefined;

  // Access is denied for this document. 에러 대응(모든 브라우저 쿠키 차단 시)
  try {
    storage = window.localStorage;
  } catch (e) {
    storage = undefined;
  }

  if (storage === undefined) return false;

  // Test for safari private mode.
  const key = '__storageChecker__';
  try {
    storage.setItem(key, 'Can I use localStorge?');
    storage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
})();

export const safeLocalStorage = {
  removeExpires() {
    if (!isLocalStorageAvailable) return;

    const expiresKeys = Object.keys(localStorage).filter((key) => key.includes(EXPIRES_KEY_SUFFIX));

    expiresKeys.forEach((expiresKey) => {
      const expirationTime = this.getItem(expiresKey);
      if (expirationTime === null) return;

      const isExpired = expirationTime < new Date().getTime();

      if (isExpired) {
        const key = expiresKey.replace(EXPIRES_KEY_SUFFIX, '');
        this.removeItem(key);
      }
    });
  },

  get length() {
    if (!isLocalStorageAvailable) return 0;

    return localStorage.length;
  },

  key(index: number) {
    if (!isLocalStorageAvailable) return null;

    return localStorage.key(index);
  },

  getItem(key: string) {
    if (!isLocalStorageAvailable) return null;

    const value = localStorage.getItem(key);

    const currentTimestamp = Date.now();
    const expires = localStorage.getItem(`${key}${EXPIRES_KEY_SUFFIX}`);

    if (value !== null && expires && Number(expires) < currentTimestamp) {
      this.removeItem(key);
      return null;
    }

    return value === null ? null : deserializer(value);
  },

  setItem(key: string, value: unknown, expiredAt?: Date) {
    if (!isLocalStorageAvailable) return;

    localStorage.setItem(key, serializer(value));

    if (expiredAt) {
      const timestamp = expiredAt.getTime().toString();
      localStorage.setItem(`${key}${EXPIRES_KEY_SUFFIX}`, timestamp);
    } else {
      localStorage.removeItem(`${key}${EXPIRES_KEY_SUFFIX}`);
    }
  },

  removeItem(key: string) {
    if (!isLocalStorageAvailable) return;

    localStorage.removeItem(key);
    localStorage.removeItem(`${key}${EXPIRES_KEY_SUFFIX}`);
  },

  clear() {
    if (!isLocalStorageAvailable) return;

    localStorage.clear();
  },
};
