<script setup lang="ts">
import { Provider } from '~/policy';

import { StorageItem } from '~/shared/StorageItem';
import AuthClient, { PROVIDER_STATE_KEY_PREFIX, REDIRECT_URI } from '~/shared/auth/client';

import { useBasicsStore } from '../../base';

const router = useRouter();
const props = defineProps<{ createdAt: string; number: any; items?: Array<any> }>();
const authClient = ref(null);
const basicStore = useBasicsStore();

const onLogin = (provider: Provider) => {
  StorageItem.local(PROVIDER_STATE_KEY_PREFIX).set(provider);

  if (provider === Provider.EMAIL) {
    const redirectUri = StorageItem.local(REDIRECT_URI).get() as string;
    const toRedirectUri = redirectUri || '/';

    if (toRedirectUri === router.currentRoute.value.fullPath) {
      basicStore.setLoginModalState(true);
    } else {
      router.replace(toRedirectUri);
    }
  } else {
    StorageItem.local('PROVIDER_MODE').remove();
    authClient.value.signIn({
      provider,
    });
  }
};

onMounted(() => {
  if ($isClient()) {
    authClient.value = AuthClient.create({
      scopes: [],
    });
  }
});
</script>
<template>
  <div class="wb-account">
    <div class="wb-account__info">
      <span class="wb-account__h2">{{ props.number }}</span>
      <span class="wb-account__h3">이 휴대폰 번호로 가입된 계정입니다.</span>
      <span class="wb-account__message">
        로그인 후 마이페이지 > <u>회원정보관리</u>에서 <br />
        SNS 계정을 연결/해제하실 수 있습니다.
      </span>
    </div>
    <div class="wb-account__content">
      <span class="wb-account__message">가입 {{ props.createdAt }}</span>
      <ul class="wb-account__list">
        <li v-for="item in props.items" :key="item.createdAt" class="wb-account__item">
          <template v-if="item.provider === Provider.KAKAO">
            <img src="/icon/sns/kakao.svg" alt="카카오" />
            <span class="wb-account__item-text">연결 {{ item.createdAt }}</span>
            <Button
              label="카카오 로그인"
              severity="secondary"
              size="small"
              outlined
              class="wb-account__item-btn"
              @click="onLogin(Provider.KAKAO)" />
          </template>
          <template v-if="item.provider === Provider.NAVER">
            <img src="/icon/sns/naver.svg" alt="네이버" />
            <span class="wb-account__item-text">연결 {{ item.createdAt }}</span>
            <Button
              label="네이버 로그인"
              severity="secondary"
              size="small"
              outlined
              class="wb-account__item-btn"
              @click="onLogin(Provider.NAVER)" />
          </template>
          <template v-if="item.provider === Provider.GOOGLE">
            <img src="/icon/sns/google.svg" alt="구글" />
            <span class="wb-account__item-text">연결 {{ item.createdAt }}</span>
            <Button
              label="구글 로그인"
              severity="secondary"
              size="small"
              outlined
              class="wb-account__item-btn"
              @click="onLogin(Provider.GOOGLE)" />
          </template>
          <template v-if="item.provider === Provider.EMAIL">
            <img src="/icon/sns/wb.svg" alt="wb" />
            <span class="wb-account__item-text">{{ item.id }}</span>
            <Button
              label="이메일 로그인"
              severity="secondary"
              size="small"
              outlined
              class="wb-account__item-btn"
              @click="onLogin(Provider.EMAIL)" />
          </template>
        </li>
      </ul>
      <hr class="wb-account__divider" />
      <p class="wb-account__message">* 내가 가입한 계정이 아니라면 <u>고객센터</u>로 문의해주세요.</p>
    </div>
  </div>
</template>
<style scoped lang="scss">
.wb-account {
  $this: &;
  text-align: center;
  color: #788194;
  &__h2 {
    display: inline-block;
    width: 100%;
    font-size: 20px;
    font-weight: 400;
    color: #1c2a4b;
    line-height: 23px;
  }
  &__h3 {
    display: inline-block;
    width: 100%;
    margin-bottom: 6px;
    font-size: 16px;
    font-weight: 700;
    color: #1c2a4b;
    line-height: 19px;
  }
  &__message {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
  &__info {
    padding: 16px 20px;
    border-radius: 8px;
    background-color: #f5f8ff;
  }
  &__content {
    text-align: left;

    #{$this}__message {
      margin-top: 20px;
    }
  }
  &__list {
    margin-top: 20px;
  }
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    img {
      margin-right: 12px;
    }
  }
  &__item-btn {
    width: 100px;
    height: 40px;
    min-height: auto;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    padding: 18px 49px;
    border-radius: 8px;
  }
  &__divider {
    border: 1px solid #091e420d;
    margin: 0;
  }
  &__item-text {
    display: inline-block;
    width: 100%;
    margin-right: auto;
    max-width: 260px;
    word-break: break-all;
  }
}
</style>
