import AnalyticsModule, { type AnalyticsInstance, type PageData } from 'analytics';

import { type PixelEventProps, type ViewProductProps } from './Tracker';

declare global {
  interface Window {
    fbq(command: string, eventName: string, props?: Record<string, any>): void;
  }
}

class Pixel {
  pixelId = '';
  init() {
    const {
      public: { pixelId },
    } = useRuntimeConfig();
    this.pixelId = pixelId;
    this.pixelId && this.loadScript();
  }

  loadScript() {
    const script = document.createElement('script');
    script.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${this.pixelId}');
    `;
    document.head.appendChild(script);
  }

  pageView(pageData: PageData<string>) {
    window.fbq?.('track', 'PageView');
  }

  completeRegistration() {
    window.fbq?.('track', 'CompleteRegistration');
  }

  addToWishlist() {
    window.fbq?.('track', 'AddToWishlist');
  }

  search() {
    window.fbq?.('track', 'Search');
  }

  initiateCheckout(payload: PixelEventProps) {
    const options = {
      content_ids: [payload.displayId],
      content_name: payload.displayName,
      content_type: 'product',
      value: payload.value,
      currency: 'KRW',
    };
    window.fbq?.('track', 'InitiateCheckout', options);
  }

  addToCart(payload: PixelEventProps) {
    const options = {
      content_ids: [payload.displayId],
      content_name: payload.displayName,
      content_type: 'product',
      value: payload.value,
      currency: 'KRW',
    };
    window.fbq?.('track', 'AddToCart', options);
  }

  purchase({ value, id, name, category }: { value: number; id: number; name: string; category: string }) {
    window.fbq?.('track', 'Purchase', {
      content_ids: [`${id}`],
      content_name: name,
      content_type: 'product',
      currency: 'KRW',
      num_items: 0,
      value,
    });
  }

  viewContent(payload: PixelEventProps) {
    const options = {
      content_ids: [payload.displayId],
      content_name: payload.displayName,
      content_type: 'product',
      value: payload.value,
      currency: 'KRW',
    };
    window.fbq?.('track', 'ViewContent', options);
  }

  trackCustom(eventName: string, props?: Record<string, any>) {
    if (!eventName) return;
    // console.log('PIXEL trackCustom :>> ', eventName, props);
    window.fbq?.('trackCustom', eventName, props);
  }
}

export default new Pixel();
