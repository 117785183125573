import empty from 'just-is-empty';

type ReturnTrueValues = null | undefined | '' | [] | number | boolean | Symbol;

export default (value: any): value is ReturnTrueValues => {
  // 숫자는 무조건 true 이므로 따로 처리.
  if (typeof value === 'number') {
    return false;
  }

  return empty(value);
};
