// 로그 필터링 플러그인
export default defineNuxtPlugin((nuxtApp) => {
  // 운영 환경에서만 에러 로그 필터링을 사용하도록 설정(개발 환경에서는 에러 로그 필터링을 사용하지 않음)
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  const originalWarn = console.warn;
  console.warn = (...args: any[]) => {
    // TODO: 에러 로그가 발생하는 근본적인 원인을 찾아 수정 필요(https://weolbu-company.atlassian.net/browse/FE-166)
    if (args[0] && typeof args[0] === 'string' && args[0].includes('Cannot stringify a function')) {
      return;
    }
    originalWarn(...args);
  };
});
