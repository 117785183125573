<template>
  <i>
    <svg width="100%" height="100%" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_17592_14824)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.984375 5.04167C0.984375 2.63693 2.9338 0.6875 5.33854 0.6875H17.2552C19.6599 0.6875 21.6094 2.63693 21.6094 5.04167V12.8333C21.6094 15.2381 19.6599 17.1875 17.2552 17.1875H5.33854C2.9338 17.1875 0.984375 15.2381 0.984375 12.8333V5.04167ZM5.33854 2.0625C3.69319 2.0625 2.35938 3.39632 2.35938 5.04167V12.8333C2.35938 14.4787 3.69319 15.8125 5.33854 15.8125H17.2552C18.9006 15.8125 20.2344 14.4787 20.2344 12.8333V5.04167C20.2344 3.39632 18.9006 2.0625 17.2552 2.0625H5.33854Z"
          fill="currentColor" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.10938 19.9375C5.10938 19.5578 5.41718 19.25 5.79688 19.25H16.7969C17.1766 19.25 17.4844 19.5578 17.4844 19.9375C17.4844 20.3172 17.1766 20.625 16.7969 20.625H5.79688C5.41718 20.625 5.10938 20.3172 5.10938 19.9375Z"
          fill="currentColor" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.67188 5.04167C1.67188 3.01662 3.3135 1.375 5.33854 1.375H17.2552C19.2803 1.375 20.9219 3.01662 20.9219 5.04167V12.8333C20.9219 14.8584 19.2803 16.5 17.2552 16.5H5.33854C3.3135 16.5 1.67187 14.8584 1.67188 12.8333V5.04167ZM9.92188 6.74326C9.92188 6.43632 10.1506 6.1875 10.4328 6.1875C10.5316 6.1875 10.6283 6.21869 10.7112 6.27729L13.8144 8.47149C14.0509 8.63877 14.118 8.983 13.9643 9.24035C13.9253 9.30552 13.8743 9.36108 13.8144 9.40344L10.7112 11.5976C10.4746 11.7649 10.1582 11.6919 10.0044 11.4346C9.95055 11.3444 9.92188 11.2392 9.92188 11.1317V8.68842V6.74326Z"
          fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_17592_14824">
          <rect width="22" height="22" fill="white" transform="translate(0.296875)" />
        </clipPath>
      </defs>
    </svg>
  </i>
</template>
<style scoped lang="scss">
i {
  width: 22px;
  height: 22px;
  display: inline-block;
}
</style>
