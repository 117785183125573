declare global {
  interface Window {
    dataLayer(): [];
  }
}
class TagManager {
  gtmId = '';
  GTM: null | unknown | any = null;
  init() {
    const {
      public: { gtmId },
    } = useRuntimeConfig();
    this.gtmId = gtmId;
    this.gtmId && this.loadScript();

    this.GTM = window.dataLayer = window.dataLayer || [];
    this.GTM.push({ js: new Date() });
    this.GTM.push({ config: gtmId });
  }

  loadScript() {
    useHead({
      script: [
        {
          type: 'text/javascript',
          innerHTML: `
          (function(w,d,s,l,i){w[l]=w[l]||[];
            w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
            var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
            j.async=true;
            j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
            f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${this.gtmId}');
          `,
          tagPosition: 'head',
        },
      ],
    });
  }
  purchase(options: { orderId: number; resultOrderPrice: number }) {
    this.GTM.push({ ecommerce: null });
    this.GTM.push({
      event: 'purchase-dv',
      ecommerce: {
        transaction_id: options.orderId,
        value: options.resultOrderPrice,
      },
    });
  }
  completeRegistration() {
    this.GTM.push({ event: 'signup_ok' });
  }
}

export default new TagManager();
