import { promptModal } from 'jenesius-vue-modal';

import { ModalActionType } from '~/types/global';

import AppConfirm from '~/components/AppConfirm.vue';

export default (message: string): Promise<ModalActionType> => {
  return new Promise(async (resolve) => {
    // SSR 에서 모달을 여는 경우, 에러 발생
    if (!$isClient()) return;

    await nextTick(); // 컨테이너가 늦게생성되는 케이스가 있기때문에 nextTick을 걸어준다.
    resolve((await promptModal(AppConfirm, { message })) as ModalActionType);
  });
};
