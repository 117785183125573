/**
 *
 *
 * https://github.com/e-/Josa.js/
 * 출처
 */

const _f = [
  function (str: string) {
    //을/를 구분
    return _hasJong(str) ? '을' : '를';
  },
  function (str: string) {
    //은/는 구분
    return _hasJong(str) ? '은' : '는';
  },
  function (str: string) {
    //이/가 구분
    return _hasJong(str) ? '이' : '가';
  },
];
const _formats: any = {
  '을/를': _f[0],
  을: _f[0],
  를: _f[0],
  을를: _f[0],
  '은/는': _f[1],
  은: _f[1],
  는: _f[1],
  은는: _f[1],
  '이/가': _f[2],
  이: _f[2],
  가: _f[2],
  이가: _f[2],
};

function _hasJong(str: string): boolean {
  //string의 마지막 글자가 받침을 가지는지 확인
  let _str = str.charCodeAt(str.length - 1);
  return (_str - 0xac00) % 28 > 0;
}

export default (word: string, format: string) => {
  if (typeof _formats[format] === 'undefined') throw 'Invalid format!';
  return _formats[format](word);
};
