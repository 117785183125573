<script setup lang="ts">
const props = defineProps<{
  email: string;
  userPwd: string;
  captchaUrl: string;
  captchaValue: string;
}>();
const emits = defineEmits(['signin', 'fetchCaptchaImg', 'update:email', 'update:userPwd', 'update:captchaValue']);
</script>

<template>
  <div class="wb-dialog-login__block wb-dialog-login__block--error-message">
    <p>
      아이디(이메일), 비밀번호 또는 자동 입력 방지 문자를<br />
      잘못 입력했습니다. 입력하신 내용을 확인해 주세요.
    </p>
  </div>
  <div class="wb-dialog-login__block wb-dialog-login__block--margin-top-24">
    <InputText
      type="text"
      placeholder="이메일 또는 아이디"
      autofocus
      class="w-full"
      :value="props.email"
      @change="emits('update:email', $event.target?.value)" />
  </div>
  <div class="wb-dialog-login__block wb-dialog-login__block--margin-top-8">
    <InputText
      type="password"
      placeholder="비밀번호"
      class="w-full"
      :value="props.userPwd"
      @change="emits('update:userPwd', $event.target?.value)"
      @keydown.enter="emits('signin')" />
  </div>
  <div class="wb-dialog-login__block wb-dialog-login__block--margin-top-20 wb-dialog-login__block--text-align-left">
    <label for="captcha">자동 입력 방지 문자</label>
    <div class="wb-dialog-login__block-captcha">
      <div class="wb-dialog-login__block-captcha__img">
        <img v-if="props.captchaUrl" :src="props.captchaUrl" alt="captcha" />
      </div>
      <Button label="새로고침" icon="pi pi-refresh" outlined @click="emits('fetchCaptchaImg')" />
    </div>
    <InputText
      id="captcha"
      type="text"
      placeholder="위 이미지의 문자를 그대로 입력해 주세요."
      class="w-full"
      :value="props.captchaValue"
      @change="emits('update:captchaValue', $event.target?.value)"
      @keydown.enter="emits('signin')" />
  </div>
</template>

<style lang="scss" scoped></style>
