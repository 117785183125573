<script setup lang="ts">
import { useMobile } from '~/composables/useMobile';

import Tracker from '~/libs/Tracker';

import { useCouponStore } from '~/services/coupon/store';

withDefaults(
  defineProps<{
    title?: string;
  }>(),
  {
    title: '확인',
  },
);

const { isDesktop } = useMobile();
const couponStore = useCouponStore();
const { openTallyPopup } = useTally();
const route = useRoute();

const handleVisible = computed<boolean>({
  get: () => {
    if (route.path === '/signup') {
      return false;
    }

    return couponStore.getWelcomePopupVisible;
  },
  set: (val) => {
    couponStore.setWelcomePopup(val);
  },
});

watch(
  () => handleVisible.value,
  (visible) => {
    if (visible) {
      Tracker['View Popup']({ popupType: 'welcome-popup' });
    }
  },
);

const closePopup = () => {
  Tracker['Click Button']('welcome-popup-close', '웰컴 팝업 닫기');
  handleVisible.value = false;
};

const gooTallyPopup = (title) => {
  Tracker['Click Button']('welcome-popup-button', title);
  openTallyPopup('https://tally.so/r/31MJbg');
  handleVisible.value = false;
};
</script>

<template>
  <Dialog
    v-model:visible="handleVisible"
    :header="title"
    :breakpoints="{ '1199px': '500px', '960px': '500px', '640px': '100vw' }"
    :modal="true"
    :closable="false"
    :draggable="false"
    :position="isDesktop ? 'center' : 'bottom'"
    :base-z-index="2000"
    :auto-z-index="true"
    class="welcome-coupon-dialog">
    <template #header>
      <div></div>
    </template>
    <div class="welcome-message-container type-b">
      <div class="header-message">
        <div>
          <b class="blue-text">47만 누적 수강생</b>이 선택한
          <br />
        </div>
        <div>
          <b class="gray-text sub-title">강의 추천 받고 <br />할인 쿠폰도 받으세요</b>
        </div>
      </div>
      <div class="image">
        <img src="/images/welcome-popup/welcome_coupon_b_3x.webp" />
        <div class="img-info-text">
          <div>강의 할인 쿠폰</div>
          <div class="boby-text">총 {{ couponStore.totalDiscountedPrice / 10000 }}만원 쿠폰</div>
          <div class="bottom-text">ONLY WEOLBU</div>
        </div>
      </div>
      <div class="bottom-title">할인쿠폰은 마이페이지에서 확인할 수 있어요!</div>
    </div>

    <template #footer>
      <Button label="아니요, 괜찮아요." text class="welcome-coupon-dialog-button" @click="closePopup" />
      <Button
        class="welcome-coupon-dialog-button"
        label="네, 추천 받을게요!"
        text
        autofocus
        @click="gooTallyPopup('네, 추천 받을게요!')" />
    </template>
  </Dialog>
</template>

<style lang="scss">
.welcome-coupon-dialog {
  width: 500px;
  @media screen and (max-width: $md) {
    width: 100%;
    max-width: 500px;
    margin: 0 !important;
  }
  .p-dialog-header {
    padding: 44px 40px;
    display: flex;
    justify-content: center;
    position: relative;
    .button-container {
      position: absolute;
      display: flex;
      align-items: flex-end;
      right: 16px;
      top: 16px;
      cursor: pointer;
    }
    .text-container {
      color: #1c2a4b;
      text-align: center;
      font-family: Pretendard;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      @media screen and (max-width: $md) {
        font-size: 24px;
        font-weight: 600;
      }
    }
  }
  .p-dialog-header {
    padding: 44px 40px 0px;
  }
  .p-dialog-footer {
    display: flex;
    padding: 0px 40px 44px;
    gap: 8px;
    @media screen and (max-width: $md) {
      padding: 0px 24px 24px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
    .welcome-coupon-dialog-button {
      width: 100%;
      border-radius: 8px;
      background: #1f5af2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1 0 0;
      color: #fff;
      margin: 0;
      &:hover {
        background: #1f5af2;
        color: #fff;
      }
      &:first-child {
        // margin: 0;
        border: 1px solid #eee;
        background: #fff;
        color: #000;
      }
    }
  }
}
.welcome-message-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  &.type-b {
    .header-message {
      gap: 8px;
      margin: 0 0 16px 0;
      color: #788194;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      @media screen and (max-width: $md) {
        line-height: 20px; /* 142.857% */
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }
      .sub-title {
        color: #091227;
        text-align: center;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 42px; /* 131.25% */
        @media screen and (max-width: $md) {
          color: #000;
          text-align: center;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
    .image {
      position: relative;
      img {
        width: 400px;
        height: 210px;
        @media screen and (max-width: $md) {
          width: 320px;
          height: 168px;
        }
      }
      .img-info-text {
        position: absolute;
        top: 20%;
        left: 17%;
        color: #424242;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.16px;
        @media screen and (max-width: $md) {
          font-size: 12.8px;
          letter-spacing: -0.128px;
        }
        .boby-text {
          color: #000;
          font-size: 32px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
          letter-spacing: -0.32px;
          margin-bottom: 4px;
          @media screen and (max-width: $md) {
            font-size: 25.6px;
            letter-spacing: -0.256px;
          }
        }
        .bottom-text {
          font-weight: 400;
        }
      }
    }
    .bottom-title {
      color: #788194;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      margin: 16px 0 32px 0;
      @media screen and (max-width: $md) {
        color: #b4b9c3;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
        margin: 16px 0;
      }
    }
  }
  .header-message {
    gap: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 24px 0;
    color: #1c2a4b;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 137.5% */
    @media screen and (max-width: $md) {
      margin: 16px 0;
    }
    .blue-text {
      display: flex;
      color: #1f5af2;
    }
    .gray-text {
      display: flex;
      color: #8790a1;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }
  .header-message div {
    display: flex;
  }
  .image {
    img {
      width: 280px;
      height: 275px;
      @media screen and (max-width: $md) {
        width: 240px;
        height: 236px;
      }
    }
  }
  .footer-message {
    display: flex;
    flex-direction: column;
    margin: 24px 0;
    @media screen and (max-width: $md) {
      margin: 16px 0;
    }
    .coupon-guide-set {
      color: #788194;
      font-size: 16px;
      font-weight: 400;
      display: flex;
      gap: 4px;
      align-items: center;
      @media screen and (max-width: $md) {
        font-size: 14px;
      }
      .remained-time {
        color: #e34935;
        background: #fff1ef;
        padding: 2px 6px;
        border-radius: 4px;
        font-weight: 500;
      }
    }
  }
}
</style>
