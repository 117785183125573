import { omit } from 'lodash-es';
import qs from 'qs';

import { type ApiResponse, type ApiResponseInner } from '~/types/global';

import BaseService from '../BaseService';
import { ProductListOtherFilters } from './constant';
import {
  type AlarmDisplaySeq,
  type Captcha,
  type ClassByCategoryListOptions,
  type ClassPrice,
  type DeleteUserDeviceTokenOptions,
  type DesignDisplayCategory,
  type DesignPopup,
  type GetReviewOptions,
  type PaymentButtonCta,
  type PostUserDeviceTokenOptions,
  type Product,
  type ProductWithPaging,
  type ReviewCountType,
  type ReviewListWithPaging,
} from './type';

class MainService extends BaseService {
  constructor() {
    super('/user');
  }

  fetchClassByCategoryList(
    params: ClassByCategoryListOptions,
    extraParams: { remainingEarlyBirdQuantity?: number; productCategory?: string },
  ) {
    const { apiBase } = useRuntimeConfig().public;

    if (ProductListOtherFilters.includes(params.cateSeq as string)) {
      const queryString = qs.stringify({ ...omit(params, 'cateSeq'), ...extraParams });
      return $fetch(`${apiBase}/v1/user/class/list/other/${params.cateSeq}?${queryString}`);
    } else {
      const queryString = qs.stringify(params);
      return $fetch(`${apiBase}/v1/user/class/list/${params.cateSeq}?${queryString}`);
    }
  }

  fetchCategoryBanner(cateSeq: number) {
    return this.get<ApiResponseInner<DesignDisplayCategory>>(`/class/banner/${cateSeq}`, {});
  }

  fetchCurationList(params: ClassByCategoryListOptions) {
    return this.get<ProductWithPaging>(`/class/curation/${params.cateSeq}`, { params });
  }

  fetchDesignModuleList() {
    const { apiBase } = useRuntimeConfig().public;
    return $fetch(`${apiBase}/v1/user/main/design/module`);
  }

  fetchRecentModuleList() {
    return this.get<ApiResponseInner<Product[]>>('/main/design/module/recent/lecture', {});
  }

  fetchDesignPopup(designPopupViewArea: string | null) {
    return this.get<ApiResponseInner<DesignPopup[]>>(`/main/design/popup/${designPopupViewArea}`, {});
  }

  fetchClassDetail(displaySeq: number, abTestGroup: 'A' | 'B' = 'A') {
    const { apiBase } = useRuntimeConfig().public;
    return $fetch<ApiResponse<ApiResponseInner<Product>>>(`/v1/user/class/detail/${displaySeq}`, {
      baseURL: apiBase,
      params: { abTestGroup },
    });
  }

  fetchPaymentClassDetail(displaySeq: number, optionSeq?: number | null, referralCode?: string | null) {
    if (optionSeq === null || optionSeq === '') {
      optionSeq = 0;
    }
    const queryString = qs.stringify({ referralCode });
    return this.get<ApiResponseInner<Product>>(`/class/payment/detail/${displaySeq}/${optionSeq}?${queryString}`, {});
  }

  fetchPaymentCaptcha(displaySeq: number) {
    return this.get<ApiResponseInner<Captcha>>(`/payment/captcha/displaySeq/${displaySeq}`, {});
  }

  fetchAvailableCoupon(displaySeq: number) {
    return this.get<ApiResponseInner<any>>(`/class/coupon/available/${displaySeq}`, {});
  }

  fetchAvailableCouponOfMine(displaySeq: number, referralCode?: string | null) {
    const queryString = qs.stringify({ referralCode });
    return this.get<ApiResponseInner<any>>(`/class/coupon/available/my/${displaySeq}?${queryString}`, {});
  }

  fetchAvailablePoint() {
    return this.get<ApiResponseInner<number>>(`/common/point/available`, {});
  }

  fetchProductOptions(displaySeq: number) {
    return this.get<ApiResponseInner<any>>(`/class/detail/option/${displaySeq}`, {});
  }

  fetchProductOption(displaySeq: number, optionSeq: number) {
    return this.get<ApiResponseInner<any>>(`/class/detail/option/${displaySeq}/${optionSeq}`, {});
  }

  downloadAvailableCoupon(couponSeq: number) {
    return this.post<ApiResponseInner<any>>(`/class/coupon/download`, { couponSeq });
  }

  fetchPaymentWaitCheck(displaySeq: number, optionSeq: number) {
    // 1 : 결제가능
    // 2 : 대기
    // 3 : 결제불가
    return this.get<ApiResponseInner<{ paymentWait: number }>>(`/payment/wait/${displaySeq}/${optionSeq}`, {});
  }

  addPaymentWait(displaySeq: number, optionSeq?: number | null) {
    return this.post<ApiResponseInner<any>>(`/payment/wait`, { displaySeq, optionSeq });
  }

  fetchPaymentButtonCta(displaySeq: number) {
    return this.get<ApiResponseInner<PaymentButtonCta>>(`/payment/button/display/${displaySeq}`);
  }

  deletePaymentWait(displaySeq: number, optionSeq: number | null) {
    return this.delete<ApiResponseInner<any>>(`/payment/wait`, { data: { displaySeq, optionSeq } });
  }

  addFavorite(displaySeq: number) {
    return this.post<ApiResponseInner<any>>(`/class/favorite/${displaySeq}`, {});
  }

  deleteFavorite(displaySeq: number) {
    return this.delete<ApiResponseInner<any>>(`/class/favorite/${displaySeq}`, {});
  }

  fetchClassPrice(displaySeq: number, referralCode?: string | null) {
    const queryString = qs.stringify({ referralCode });
    return this.get<ApiResponseInner<ClassPrice>>(`/class/detail/price/${displaySeq}?${queryString}`, {});
  }

  async addPaymentAlarm(displaySeq: number) {
    const res = await this.post<ApiResponseInner<any>>(`/common/alarm/${displaySeq}`, {});
    return res;
  }

  deletePaymentAlarm(displaySeq: number) {
    return this.delete<ApiResponseInner<any>>(`/common/alarm/${displaySeq}`, {});
  }

  fetchAlarmDisplaySeq(displaySeq: number) {
    return this.get<ApiResponseInner<AlarmDisplaySeq>>(`/alarm/displaySeq/${displaySeq}`, {});
  }

  postUserDeviceToken(params: PostUserDeviceTokenOptions) {
    return this.post('/device/token', params);
  }

  deleteUserDeviceToken(params: DeleteUserDeviceTokenOptions) {
    return this.delete('/device/token', { data: { ...params }, slientAlert: true });
  }

  // GNB 및 QuickMenu에서 사용하는 API
  fetchGnbMenu() {
    const { apiBase } = useRuntimeConfig().public;
    return $fetch(`${apiBase}/v1/user/menu/gnb`, {});
  }

  fetchQuickMenu() {
    const { apiBase } = useRuntimeConfig().public;
    return $fetch(`${apiBase}/v1/user/menu/quick-menu`);
  }

  fetchProductReviews(params: GetReviewOptions) {
    return this.get<ReviewListWithPaging[]>(`/class/product-reviews`, { params });
  }

  fetchProductCopmletePurchaseCount(displaySeq: number) {
    return this.getV2<ApiResponse<ApiResponseInner<number>>>(`/displays/${displaySeq}/complete-payment-count`);
  }
}

class ReviewService extends BaseService {
  constructor() {
    super('');
  }

  fetchProductReviews(params: GetReviewOptions) {
    return this.getV2<ReviewListWithPaging[]>(`/search/reviews`, { params });
  }

  fetchProductReviewStats(productId: number | string) {
    const queryString = qs.stringify({ productId });
    return this.getV2<ReviewCountType>(`/reviews/product-review-statistics?${queryString}`, { useKotlinBaseUrl: true });
  }
}

export const mainService = new MainService();
export const reviewService = new ReviewService();
