import { bascisService } from '.';

interface State {
  sharePcOpen: boolean;
  shareMobileOpen: boolean;
  accounts: any[];
  phoneNumber: string;
  createdAt: string;
  isOpen: boolean;
  recommendKeyword: string[];
  route: { from: { name: string; path: string }; to: { name: string; path: string } } | null;
  callbackAfterLoggedIn: (() => void) | null;
}
export const useBasicsStore = defineStore('basics-store', {
  state: (): State => ({
    sharePcOpen: false,
    shareMobileOpen: false,
    isOpen: false,
    recommendKeyword: [],
    route: {} as any,
    accounts: [],
    phoneNumber: '',
    createdAt: '',
    callbackAfterLoggedIn: null,
  }),
  persist: false,
  actions: {
    setShareMobileOpen(flag: boolean) {
      this.shareMobileOpen = flag;
    },
    setLoginModalState(isOpen: boolean, callbackAfterLoggedIn?: () => void) {
      this.isOpen = isOpen;
      if (isOpen) {
        this.callbackAfterLoggedIn = callbackAfterLoggedIn ?? null;
      } else {
        this.callbackAfterLoggedIn = null;
      }
    },
    async getSearchRecommend() {
      const { data } = await bascisService.getSearchRecommend();
      this.recommendKeyword = data.items;
      return data;
    },
    setAccounts(accounts: any[], phoneNumber, createdAt) {
      this.accounts = accounts;
      this.phoneNumber = phoneNumber;
      this.createdAt = createdAt;
    },
  },
  getters: {},
});
