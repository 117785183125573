<script lang="ts">
import { defineComponent, ref } from 'vue';

const isOpen = ref(false);

export function openDialog() {
  isOpen.value = true;
}

export function closeDialog() {
  isOpen.value = false;
}

export default defineComponent({
  setup() {
    return {
      isOpen,
      closeDialog,
    };
  },
});
</script>

<template>
  <div v-if="isOpen" class="global-dialog">
    <!-- Your dialog content here -->
    <button @click="closeDialog">dddddddddddddddddddddd</button>
    <div></div>
  </div>
</template>

<style scoped>
/* Add your dialog styles here */
.global-dialog {
  position: absolute;
  top: 100px;
  z-index: 1000;
}
</style>
