<template>
  <img width="24px" heihgt="24px" class="spin" src="../../assets/images/loading.png" />
</template>

<style scoped>
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
.spin {
  animation: spin 1s linear infinite;
}
</style>
