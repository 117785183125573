<script setup lang="ts">
import { Modal } from 'jenesius-vue-modal';

withDefaults(
  defineProps<{
    title?: string;
    message?: string;
    helpText?: string | null;
  }>(),
  {
    title: '확인',
    message: '',
    helpText: null,
  },
);

const emit = defineEmits<{
  // @ts-ignore
  (e: Modal.EVENT_PROMPT, action: string): void;
}>();

const route = useRoute();

watch(
  () => route,
  () => {
    handleVisible.value = false;
  },
  { deep: true },
);

const handleVisible = computed<boolean>({
  get: () => {
    return true;
  },
  set: (val) => {
    if (!val) {
      nextTick(() => {
        emit(Modal.EVENT_PROMPT, 'close');
      });
    }
  },
});
</script>

<template>
  <Dialog
    v-model:visible="handleVisible"
    :header="title"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :modal="true"
    class="wb-alert">
    <p class="text-center whitespace-pre-line" v-html="message"></p>

    <p v-if="!!helpText" class="text-center help-text" v-html="helpText"></p>

    <template #footer>
      <Button label="확인" text class="w-full" autofocus @click="handleVisible = false" />
    </template>
  </Dialog>
</template>

<style lang="scss" scoped>
.help-text {
  margin-top: 8px;
  font-size: 13px;
  font-weight: 300;
  color: #48546e;
}
</style>
