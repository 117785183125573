import * as _ from 'lodash-es';

import { defer } from './misc';
import { safeLocalStorage } from './safeLocalStorage';

export type TestGroup = 'a' | 'b' | 'c';
export type TestMap = {
  [testGroup in TestGroup]?: number;
};

export type CampaignMap = Record<Campaign, TestMap>;

export enum Campaign {
  MAINTEST = 'MAINTEST',
  'ABTEST-PRODUCT-DETAIL' = 'ABTEST-PRODUCT-DETAIL',
  WB_TEST = 'WB_TEST',
  WB_TEST_QUESTIONS = 'WB_TEST_QUESTIONS',

  'COMM-2511' = 'COMM-2511', // 전문가칼럼 상세 기존 크리에이터 영역(A)/진행중강의(B) A/B TEST
  'FS-460' = 'FS-460', // 관심사별 추천 강의 페이지 A/B TEST
  'FS-569' = 'FS-569', // 대기신청 vs. 오픈알림 줄세우기 A/B 테스트
  'CLSQ-9' = 'CLSQ-9', // 바텀내비 A/B 테스트
  'FS-591' = 'FS-591', // 강의 구매하기 CTA TEST
  'FS-542' = 'FS-542', // 상세페이지 전체 A/B TEST
}

export const campaignMap: CampaignMap = {
  [Campaign.MAINTEST]: {
    a: 50,
    b: 50,
  },
  [Campaign['ABTEST-PRODUCT-DETAIL']]: {
    a: 50,
    b: 50,
  },
  [Campaign.WB_TEST]: {
    a: 50,
    b: 50,
  },
  [Campaign.WB_TEST_QUESTIONS]: {
    a: 50,
    b: 50,
  },
  [Campaign['COMM-2511']]: {
    a: 50,
    b: 50,
  },

  [Campaign['FS-460']]: {
    a: 50,
    b: 50,
  },
  [Campaign['FS-569']]: {
    a: 50,
    b: 50,
  },
  [Campaign['CLSQ-9']]: {
    a: 50,
    b: 50,
  },
  [Campaign['FS-591']]: {
    a: 50,
    b: 50,
  },
  [Campaign['FS-542']]: {
    a: 50,
    b: 50,
  },
};

const keysWithType = <T extends Object>(obj: T): (keyof T)[] => {
  return Object.keys(obj) as (keyof T)[];
};

class ABTest {
  testPrefix = 'abtest';
  //* 테스트 대상군 획득전에 테스트 초기화를 기다려야하기 때문에 deferred 객체 생성
  isLoaded = defer();

  init() {
    keysWithType(campaignMap).forEach((campaign) => {
      if (this.getTestGroup(campaign)) {
        return;
      }
      this.initCampaign(campaign);
    });
    this.isLoaded.resolve();
  }

  initCampaign(campaign: Campaign) {
    const ratioMap = campaignMap[campaign];
    const poolForSample = keysWithType(ratioMap).reduce<string[]>((acc, cur) => {
      const ratio = ratioMap[cur];
      const filled = _.fill(Array(ratio), cur);
      return [...acc, ...filled];
    }, []) as TestGroup[];
    const testAllocation = _.sample(poolForSample);
    if (testAllocation) {
      this.setTestGroup(campaign, testAllocation);
    }
  }

  setTestGroup(campaign: Campaign, testAllocation: TestGroup) {
    safeLocalStorage.setItem(`${this.testPrefix}_${campaign}`, testAllocation);
  }

  getTestGroup(campaign: Campaign): TestGroup {
    const testGroup = safeLocalStorage.getItem(`${this.testPrefix}_${campaign}`) as TestGroup;
    return testGroup;
  }

  getCampaign(campaign: Campaign) {
    return campaignMap[campaign];
  }

  async getTestGroupSafely(campaign: Campaign) {
    await this.isLoaded.promise;
    const testGroup = safeLocalStorage.getItem(`${this.testPrefix}_${campaign}`) as TestGroup;
    return testGroup;
  }

  async isGroupB(campaign: Campaign) {
    await this.isLoaded.promise;
    return this.getTestGroup(campaign) === 'b';
  }

  async getTargetGroup(campaign: Campaign) {
    await this.isLoaded.promise;
    return this.getTestGroup(campaign);
  }
}

export default new ABTest();
