import 'animate.css';

import { type CustomConfirmOptions, type ModalActionType } from '../types/global';
import josa from '../validate/josa';

declare module '#app' {
  interface NuxtApp {
    $vt(message: any, replaceValue: string): string;
    $alert(message: string | { title: string; message: string }): Promise<'close'>;
    $confirm(message: string | { title: string; message: string }): Promise<ModalActionType>;
    $dateFormat(value: any, format?: string): string;
    $customConfirm(customConfirmOptions: CustomConfirmOptions): Promise<Boolean>;
  }
}
declare module 'vue' {
  interface ComponentCustomProperties {
    $vt(message: any, replaceValue: string): string;
    $alert(message: string | { title: string; message: string }): Promise<'close'>;
    $confirm(message: string | { title: string; message: string }): Promise<ModalActionType>;
    $customConfirm(customConfirmOptions: CustomConfirmOptions): Promise<Boolean>;
    $dateFormat(value: any, format?: string): string;
    $share(type: 'kakao' | 'clipboard'): void;
  }
}

const validateTrans = (message: any, replaceValue: string) => {
  let _message: string = message;
  if (_message.includes('Value')) {
    if (_message.includes('은/는')) {
      _message = _message.replace('은/는', josa(replaceValue.trim(), '은/는'));
    }
  }
  return _message.replace('Value', replaceValue);
};

export default defineNuxtPlugin(({ vueApp: app }) => {
  return {
    provide: {
      vt: validateTrans,
      alert: $alert, // utils/$alert
      confirm: $confirm, // utils/$confirm
      customConfirm: $customConfirm, // utils/$customConfirm
      dateFormat: $dateFormat,
      share: $share,
    },
  };
});
