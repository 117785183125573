import dompurify from 'dompurify';
import { sortBy, union } from 'lodash-es';

import { getTimeDifference } from '~/libs/dateUtils';

import Utility from '~/services/Utility';
import {
  type BoardContent,
  type BoardContentComment,
  type CommunityCategories,
  type CommunityFollowRank,
  type CommunityRank,
  type GetBoardContentOptions,
  type GetterBoardContent,
  type GetterCommunityRank,
  type IComment,
  type SurveyMember,
  communityService,
} from '~/services/community';
import { CommunityMainTabNumbers } from '~/services/main/constant';
import { useUserAuthStore } from '~/services/userAuth';

import { type Pagination, type PaginationOptions } from '~/types/global';

interface State {
  error: {
    errorCode: null | string;
    errorMessage: string;
  };
  boardNotice: BoardContent;
  contents: {
    items: BoardContent[];
    paging?: Pagination;
  };
  contentCache: boolean;
  once: boolean;
  ranks: CommunityRank[];
  followRanks: CommunityFollowRank[];
  surveyMembers: CommunityFollowRank[];
  communityBoards: BoardContent[];
  closedBoardSeqs: number[];
  prevChallengeCount: number;
}

export const useCommunityStore = defineStore('user-community', {
  state: (): State => ({
    error: {
      errorCode: null,
      errorMessage: '',
    },
    ranks: [],
    followRanks: [],
    surveyMembers: [],
    communityBoards: [],
    closedBoardSeqs: [],
    boardNotice: {},
    contents: {
      items: [],
    },
    contentCache: false,
    once: false,
    prevChallengeCount: 0,
  }),
  actions: {
    setPrevChallengeCount(count) {
      this.prevChallengeCount = count;
    },
    async insertBoardContentsShare(boardSeq: number, boardContentsSeq: number) {
      await communityService.insertBoardContentsShare(boardSeq, boardContentsSeq);
    },
    async fetchBoardContents(params: GetBoardContentOptions, append = false) {
      const { data } = await communityService.getBoardContentList(params);
      const {
        status: { errorCode, errorMessage },
      } = data;
      if (errorCode) {
        this.error = { errorCode, errorMessage };
        return;
      }

      this.once = true;
      if (append) {
        this.contents = {
          items: this.contents.items.concat(data.data.items),
          paging: data.data.paging,
        };
      } else {
        this.contents = data.data;
      }
      return data;
    },
    async fetchCommunityRanks() {
      const params: Partial<PaginationOptions> = {
        pageNo: 1,
        pageSize: 5,
      };
      const { data } = await communityService.getCommentRankList(params);
      this.ranks = data.data.items;
      return data;
    },
    async fetchCommunityFollowerRanks() {
      const { data } = await communityService.getAttentionRankList();
      this.followRanks = data.data.items;
      // 작업을 위해 잠시 데이터 복제 처리 삭제해야함
      return data;
    },
    async fetchCommunitySurveyMembers(params: PaginationOptions) {
      const { data } = await communityService.getSurveyMembers(params);
      this.surveyMembers = data.data.items;
      return data;
    },
    saveClosedBoardSeqs(boardSeq: number) {
      this.closedBoardSeqs = union(this.closedBoardSeqs, [boardSeq]);
    },
    clearClosedBoardSeqs() {
      this.closedBoardSeqs = [];
    },
    setTitleByItemKey(itemKey: string) {
      switch (itemKey) {
        case CommunityMainTabNumbers.HOME:
          return '커뮤니티홈';
        case CommunityMainTabNumbers.FOLLOW:
          return '팔로우';
        case CommunityMainTabNumbers.MY_ACTIVITY:
          return '내가쓴글';
        case CommunityMainTabNumbers.INTRO_LECTURE:
          return '강의안내';
        default:
          return itemKey;
      }
    },
    setIconByItemKey(itemKey: string) {
      switch (itemKey) {
        case CommunityMainTabNumbers.HOME:
          return 'house';
        case CommunityMainTabNumbers.FOLLOW:
          return 'person_plus';
        case CommunityMainTabNumbers.MY_ACTIVITY:
          return 'mybox';
        case CommunityMainTabNumbers.INTRO_LECTURE:
          return 'calendar';
      }
    },
    setActiveIcon(itemKey: string, category: CommunityCategories | null, isClicked: boolean) {
      const iconName = this.setIconByItemKey(itemKey);
      if (category) {
        if (isClicked) {
          return category.selectedIcon;
        }
        return category.unselectedIcon;
      } else {
        if (isClicked) {
          return `/icon/${iconName}_clicked.png`;
        }
        return `/icon/${iconName}_empty.png`;
      }
    },
    setActiveTitle(isClicked: boolean) {
      if (isClicked) {
        return '#1F5AF2';
      }
      return '#1C2A4B';
    },
    setProfileImg(userProfileImgUrl: string) {
      const hasProfileImg = !!userProfileImgUrl && userProfileImgUrl !== '';
      if (hasProfileImg) {
        return userProfileImgUrl;
      }
      return '/icon/JohnDoe.png';
    },
    setMainBoardName(itemKey: string, boardName: string | null | undefined) {
      switch (itemKey) {
        case CommunityMainTabNumbers.HOME:
          return '홈'; // * '커뮤니티홈'이 고유명사로 되었지만 gnbName은 기존 데이터를 위해서 '홈'으로 유지;
        case CommunityMainTabNumbers.FOLLOW:
          return '팔로우';
        case CommunityMainTabNumbers.INTRO_LECTURE:
          return '강의안내';
        case CommunityMainTabNumbers.MY_ACTIVITY:
          return '내가쓴글';
        case CommunityMainTabNumbers.COLUMN:
          return '칼럼';
        case CommunityMainTabNumbers.WEOLBU_CHALLENGE:
          return '월부챌린지';
        case CommunityMainTabNumbers.HELP_WEOLBU:
          return '구해줘월부';
        case CommunityMainTabNumbers.STUDY:
          return '스터디';
        default:
          return boardName ?? '';
      }
    },
    setIsPrivateTab(boardCategoryCd: string): boolean {
      const isFollowTab = boardCategoryCd === CommunityMainTabNumbers.FOLLOW;
      const isMyActivityTab = boardCategoryCd === CommunityMainTabNumbers.MY_ACTIVITY;
      return isFollowTab || isMyActivityTab;
    },
    setSubNavigation(boardCategoryCd: string, boardSeq: number | string | null) {
      const userAuth = useUserAuthStore();

      // 모바일에서 내가쓴글 탭을 클릭할 경우 프로필로 이동(기존에는 페이지 이동 후에 리다이렉트해서 동작 어색 및 뒤로가기 이슈 존재)
      if (!!userAuth.user && boardCategoryCd === CommunityMainTabNumbers.MY_ACTIVITY) {
        return `/profile/${userAuth.user.userId}`;
      }

      const hasNoSubTab = boardSeq === '' || !boardSeq;
      if (hasNoSubTab) {
        return `/community?tab=${boardCategoryCd}`;
      }

      return `/community?tab=${boardCategoryCd}&subTab=${boardSeq}`;
    },
    setCommentListItemFormat(resItem: BoardContentComment) {
      return {
        ...resItem,
        commentText: dompurify.sanitize(resItem.commentText),
        likeCountLabel: Utility.setCommaPer3Digit(resItem.likeCount),
        historyTimeLabel: getTimeDifference(resItem.regDate),
        commentChildCountLabel: Utility.setCommaPer3Digit(resItem.commentChildCount),
        nameLabel: resItem.nickName || resItem.userName,
        creatorYn: resItem.creatorYn ?? 'N',
        more: false,
        update: false,
      };
    },
    setCommentList(isFirstList: boolean, prevItems: IComment[], resItems: BoardContentComment[]) {
      if (isFirstList) {
        return resItems.map((item) => {
          return this.setCommentListItemFormat(item);
        });
      }
      return prevItems.concat(
        resItems.map((item) => {
          return this.setCommentListItemFormat(item);
        }),
      );
    },
    filterFirstItemBoardSeq(boardCategoryCd: string, boards: BoardContent[]): string {
      const sortedBoards = sortBy(boards, 'position');
      const hasBoards: boolean = (boards.length ?? 0) > 0;
      if (hasBoards) {
        return `${sortedBoards[0].boardSeq}`;
      }
      return '';
    },
    filterFirstItemBoard(boards: BoardContent[]): BoardContent | null {
      const hasBoards: boolean = (boards.length ?? 0) > 0;
      if (hasBoards) {
        return boards[0];
      }
      return null;
    },
  },
  getters: {
    getBoardNotice({ boardNotice }) {
      return boardNotice;
    },
    getBoards({ boards }) {
      return boards.map((item) => ({ ...item }));
    },
    getBoardContents({ contents }): GetterBoardContent[] {
      return contents?.items.map((item) => {
        return Utility.handleCommunityContent(item);
      });
    },
    getClosedBoardSeqs({ closedBoardSeqs }): number[] {
      return closedBoardSeqs;
    },
    getFollowRanks({ followRanks }): CommunityFollowRank[] {
      const userAuth = useUserAuthStore();
      const currentUser = userAuth.user;
      if (followRanks.length === 0 || !currentUser) {
        return followRanks;
      }

      // 현재 로그인 한 사용자는 주목받는 멤버에서 제거처리
      const filteredRanks = followRanks.filter((item) => item.userId != currentUser.userId);
      return filteredRanks;
    },
    getSurveyMembers({ surveyMembers }): SurveyMember[] {
      const userAuth = useUserAuthStore();
      const currentUser = userAuth.user;
      if (surveyMembers.length === 0 || !currentUser) {
        return surveyMembers;
      }

      // 현재 로그인 한 사용자는 주목받는 멤버에서 제거처리
      const filteredList = surveyMembers.filter((item) => item.userId != currentUser.userId);
      return filteredList;
    },
    getRanks({ ranks }): GetterCommunityRank[] {
      return ranks.map((item) => {
        return {
          ...item,
          commentCountLabel: Utility.setCommaPer3Digit(item.commentCount),
          nameLabel: item.nickName || item.userName,
        };
      });
    },
  },
});
