import AmplitudePlugin from '@analytics/amplitude';
import GAPlugin from '@analytics/google-analytics';
import AnalyticsModule, { type AnalyticsInstance, type PageData } from 'analytics';
import { compact, isObject, omitBy } from 'lodash-es';

import CriteoTag from '~/libs/CriteoTag';

import { type UserInfo } from '../services/userAuth';
import ChannelService from './ChannelService';
import KakaoPixel from './KakaoPixel';
import Pixel from './Pixel';

/**
 * CDP로 보낼 이벤트와 마케팅 이벤트 전송을 모두 담당하는 모듈
 */
class Analytics {
  analytics?: AnalyticsInstance;
  isIdentified = false;
  anonymousId?: string; // getanalytics에서 심어주는 deviceId (localstorage에 저장됨)

  init() {
    const {
      public: { debug, amplitudeApiKey, ga4Id },
    } = useRuntimeConfig();
    this.analytics = AnalyticsModule({
      app: 'weolbu',
      debug: Boolean(debug),
      plugins: compact([
        amplitudeApiKey &&
          AmplitudePlugin({
            apiKey: amplitudeApiKey,
            // customScriptSrc: this.scriptSrc,
            //  https://bit.ly/3dRdZnE 하단의 옵션들은 옆에 링크에서 참고
            options: {
              // includeFbclid: true, // facebook click identifier
              // includeGclid: true, // google click identifier
              includeReferrer: true,
              includeUtm: true,
              disableCookies: true,
              cookieForceUpgrade: true,
              // logAttributionCapturedEvent: true,
              saveParamsReferrerOncePerSession: false, // 세션 중간에 값이 변경된 것을 반영하는 옵션 - false 시에 새로운값이 반영
              // unsetParamsReferrerOnNewSession: true, // 새로운 세션 시작시 모든 utm / referrer 값 초기화 하는 옵션 - true 시에 값들 초기화 처리
            },
          }),
        // GA v4
        ga4Id &&
          GAPlugin({
            measurementIds: ga4Id,
          }),
      ]),
    });
    this.anonymousId = this.analytics.getState('user.anonymousId');
  }

  //* 민감한 주요 정보 필터링 후 유저 식별
  async identify(
    {
      email,
      userPhone,
      userHomePhone,
      userName,
      address,
      addressDetail,
      userProfileImgUrl,
      userProfileInfo,
      memo,
      kakaoId,
      kakaoRegDate,
      googleId,
      googleRegDate,
      naverId,
      naverRegDate,
      ...rest
    }: UserInfo | Record<string, string>,
    isSignup = false,
  ) {
    if (!this.analytics) {
      return Promise.reject(new Error('Analytics is not initialized when identify'));
      return;
    }
    if (this.isIdentified && !isSignup) {
      return Promise.reject(new Error('Analytics is already identified when identify'));
      return;
    }
    await this.analytics.identify(String(rest.userId), {
      ...rest,
    });
    this.isIdentified = true;
  }

  // 페이지 뷰 이벤트
  page(pageData: PageData) {
    if (!this.analytics) {
      return Promise.resolve();
    }

    const { extraData = {}, ...restPageData } = pageData;

    ChannelService.track('Page View', { ...restPageData });
    Pixel.pageView(restPageData);
    KakaoPixel.pageView();
    CriteoTag.addViewHomeTag({
      email: extraData?.user?.email ?? '',
    });

    return this.analytics.page({ ...restPageData, anonymousId: this.anonymousId });
  }

  // 스크롤 포지션 이벤트, 설계상 각각의 채널 분리가 안되는구조라 별도의 함수로 만듬
  scroll(props?: Record<string, string | number | any>) {
    if (!this.analytics) {
      return Promise.resolve();
    }

    const propsObj = { ...props };

    // 외부에서 넣어주면 좋겠는데 기존 설계를 다 바꿀수 없어서 여기서 공통 프로퍼티를 넣어줌
    if (typeof window !== 'undefined' && typeof window.location !== 'undefined') {
      // console.log('track:location', location);
      const { hostname, pathname, search, hash } = window.location;
      Object.assign(propsObj, { hostname, pathname, search, hash, ...$url.searchToObject(search) });
    }

    // value가 primitive가 아닐땐 생략
    const omitted = omitBy(propsObj, (value) => {
      return isObject(value);
    });
    // console.log('omitted', omitted);
    ChannelService.track('Scroll Position', omitted);
    return Promise.resolve();
  }

  // 커스텀 이벤트
  track(event: string, props?: Record<string, string | number | any>) {
    if (!this.analytics) {
      return Promise.resolve();
    }

    const propsObj = { ...props, referrerUrl: window.history.state?.back };

    // 외부에서 넣어주면 좋겠는데 기존 설계를 다 바꿀수 없어서 여기서 공통 프로퍼티를 넣어줌
    if (typeof window !== 'undefined' && typeof window.location !== 'undefined') {
      // console.log('track:location', location);
      const { hostname, pathname, search, hash } = window.location;
      Object.assign({ hostname, pathname, search, hash, ...$url.searchToObject(search) }, propsObj);
    }

    // value가 primitive가 아닐땐 생략
    const omitted = omitBy(propsObj, (value) => {
      return isObject(value);
    });
    ChannelService.track(event, omitted);
    Pixel.trackCustom(event, omitted);

    return this.analytics.track(event, { ...propsObj, anonymousId: this.anonymousId });
  }
}

export default new Analytics();
