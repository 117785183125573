<script lang="ts" setup>
const handleErrorClear = () => clearError({ redirect: '/' });
</script>

<template>
  <div class="error-container">
    <div class="error-header">
      <h1 class="error-logo" @click="handleErrorClear">
        <img class="error-logo-image" src="/images/logo.svg" alt="월급쟁이부자들" />
      </h1>
    </div>
    <div class="error-content">
      <div class="error-404-image"></div>
      <div class="error-text">
        <h2 class="error-text-title">찾을 수 없는 페이지입니다.</h2>
        <p class="error-text-description">
          주소를 잘못 입력했거나 삭제된 페이지예요!<br />
          추가 문제 발생 시 채널톡으로 알려주세요.
        </p>
      </div>
      <button class="error-clear-button" @click="handleErrorClear">월급쟁이부자들 홈 가기</button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100dvh;
  padding: 0 16px;
  background-color: #fff;
}

.error-header {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1180px;
  min-height: 74px;
  padding: 16px 0;

  .error-logo {
    display: block;
    height: 20px;
    cursor: pointer;

    .error-logo-image {
      display: block;
      height: 100%;
    }
  }
}

.error-content {
  display: flex;
  padding-bottom: 74px;
  width: 100%;
  max-width: 1180px;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .error-404-image {
    max-width: 784px;
    width: 100%;
    height: 200px;
    background: url('~/assets/images/error_404.png') no-repeat center;
    background-size: cover;
  }

  .error-text {
    margin: 16px 0 40px 0;
    text-align: center;

    .error-text-title {
      margin-bottom: 8px;
      color: #000;
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
    }

    .error-text-description {
      color: #000;
      text-align: center;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .error-clear-button {
    padding: 12px 16px;
    border-radius: 40px;
    border: 1px solid #1f5af2;
    background: #fff;
    color: #1f5af2;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    cursor: pointer;
  }
}
</style>
