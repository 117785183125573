<script setup lang="ts">
import { Modal } from 'jenesius-vue-modal';

import { type CustomConfirmOptions } from '~/types/global';

const action = ref<boolean>(false);
const props = withDefaults(
  defineProps<{
    customConfirmOptions: CustomConfirmOptions;
  }>(),
  {},
);

const INIT_CONFIRM = (): CustomConfirmOptions => ({
  title: '',
  message: '',
  leftButtonLabel: '아니요',
  rightButtonLabel: '예',
  trueButton: 'RIGHT',
});

const route = useRoute();
const confirm = reactive<CustomConfirmOptions>(INIT_CONFIRM());

const emit = defineEmits<{
  // @ts-ignore
  (e: Modal.EVENT_PROMPT, action: boolean): void;
}>();

watch(
  () => route,
  () => {
    handleVisible.value = false;
  },
  { deep: true },
);

const handleVisible = computed<boolean>({
  get: () => {
    return true;
  },
  set: (val) => {
    if (!val) {
      nextTick(() => {
        emit(Modal.EVENT_PROMPT, action.value);
      });
    }
  },
});

onBeforeMount(() => {
  Object.assign(confirm, props.customConfirmOptions);
});
</script>

<template>
  <Dialog
    v-model:visible="handleVisible"
    :header="confirm.title"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :modal="true"
    class="wb-confirm"
    @update:visible="handleVisible">
    <p v-if="confirm.title" class="confirm-header">{{ confirm.title }}</p>
    <p class="confirm-message text-center whitespace-pre-line" :class="{ 'with-title': confirm.title }">
      {{ confirm.message }}
    </p>
    <template #footer>
      <Button
        :label="confirm.leftButtonLabel"
        :style="confirm.leftButtonStyle"
        text
        class="w-full text-base text-neutral-600"
        @click="
          action = confirm.trueButton === 'LEFT' ? true : false;
          handleVisible = false;
        " />
      <hr />
      <Button
        :label="confirm.rightButtonLabel"
        :style="confirm.rightButtonStyle"
        text
        class="w-full text-base text-primary-600"
        autofocus
        @click="
          action = confirm.trueButton === 'RIGHT' ? true : false;
          handleVisible = false;
        " />
    </template>
  </Dialog>
</template>

<style lang="scss" scoped>
.confirm-header {
  font-family: Pretendard;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  margin-bottom: 8px;
}

.confirm-message {
  font-family: Pretendard;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #091227;

  &.with-title {
    font-size: 14px;
    color: #48546e;
  }
}
</style>
