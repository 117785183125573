declare global {
  interface Window {
    kakaoPixel(pixelId: string): any;
  }
}

class KakaoPixel {
  pixelId = '';
  kakaoPixel = null;
  init() {
    const {
      public: { kakaoPixelId },
    } = useRuntimeConfig();
    this.pixelId = kakaoPixelId;
    this.kakaoPixel = window.kakaoPixel(this.pixelId);
  }

  // 방문
  pageView() {
    console.log('KAKAO PIXEL: pageView');
    this.kakaoPixel?.pageView();
  }

  // 회원가입
  completeRegistration() {
    console.log('KAKAO PIXEL: completeRegistration');
    this.kakaoPixel?.completeRegistration();
  }

  // 로그인
  login() {
    this.kakaoPixel?.login();
  }

  // 검색
  search() {
    this.kakaoPixel?.search();
  }

  // 컨텐츠 / 상품 조회
  viewContent(options: { id: string }) {
    this.kakaoPixel?.viewContent(options);
  }

  // 관심상품 추가
  addToWishList() {
    this.kakaoPixel?.addToWishList();
  }

  // 장바구니 추가
  addToCard() {
    this.kakaoPixel?.addToCard();
  }

  // 장바구니 보기
  viewCart() {
    this.kakaoPixel?.viewCart();
  }

  // 구매
  purchase(options) {
    console.log('KAKAO PIXEL: purchase', options);
    this.kakaoPixel?.purchase(options);
  }

  // 잠재고객
  participation() {
    this.kakaoPixel?.participation();
  }

  // 사전준비
  preparation(options: { tag?: string }) {
    console.log('KAKAO PIXEL: preparation', options);
    this.kakaoPixel?.preparation(options?.tag);
  }

  // 서비스 신청
  signUp(options: { tag?: string }) {
    console.log('KAKAO PIXEL: signUp', options);
    this.kakaoPixel?.signUp(options?.tag);
  }

  // 튜토리얼
  tutorial() {
    this.kakaoPixel?.tutorial();
  }

  // 목표달성
  missionComplete() {
    this.kakaoPixel?.missionComplete();
  }

  // 앱실행
  appLaunch() {
    this.kakaoPixel?.appLaunch();
  }

  // 앱설치
  appInstall() {
    this.kakaoPixel?.appInstall();
  }

  // 인앱구매
  inAppPurchase() {
    this.kakaoPixel?.inAppPurchase();
  }
}

export default new KakaoPixel();
