import qs from 'qs';

import BaseService from '~/services/BaseService';

import { type PaginationOptions } from '~/types/global';

class UserGnbService extends BaseService {
  constructor() {
    super('/user/gnb');
  }

  getLastProgress() {
    return this.getV2<{ progress: string | null }>('/last/progress');
  }

  getCategoryList() {
    return this.getV2(`/category/list`);
  }

  searchClassList(keyword: string, params: PaginationOptions) {
    return this.getV2(`/search/class/${keyword}`, { params });
  }

  searchNicknameList(keyword: string, params: PaginationOptions) {
    return this.getV2(`/search/users/${keyword}`, { params });
  }

  recommendClassList(params: PaginationOptions) {
    return this.getV2(`/search/recommand/product/list`, { params });
  }
}

export const userGnbService = new UserGnbService();
