import * as Sentry from '@sentry/vue';

const shouldTracePath = (path: string) => {
  // 측정 대상 페이지들
  if (['/', '/community', '/product', '/class'].includes(path)) {
    return true;
  }

  // 커뮤니티 상세 페이지
  if (/^\/community\/\d+\/?$/i.test(path)) {
    return true;
  }

  // 프로필 상세 페이지
  if (/^\/profile\/\d+\/?$/i.test(path)) {
    return true;
  }

  return false;
};

export default defineNuxtPlugin(() => {
  const { vueApp, $router } = useNuxtApp();

  const {
    public: { app_env, SENTRY_ENABLED, SENTRY_RELEASE },
  } = useRuntimeConfig();

  // console.log('Sentry Enabled :>> ', IS_LOCAL !== 'true');
  // console.log('SENTRY_RELEASE :>> ', SENTRY_RELEASE);

  Sentry.init({
    app: [vueApp],
    environment: app_env === 'prod' ? 'production' : 'development',
    enabled: SENTRY_ENABLED === 'true',
    dsn: 'https://b9f910d6d07741e48abbe37f44281187@o4505577032712192.ingest.sentry.io/4505599555665920',
    tracePropagationTargets: ['localhost', 'https://api.weolbu.com/'],
    integrations: [
      Sentry.browserTracingIntegration({
        enableInp: true,
        router: $router,
      }),
      Sentry.replayIntegration(),
    ],
    autoSessionTracking: false,
    release: SENTRY_RELEASE,
    // Performance Monitoring
    tracesSampler: (samplingContext) => {
      const {
        transactionContext: { op, name },
      } = samplingContext;

      // pageload op만 추적합니다.
      if (op !== 'pageload') {
        return 0;
      }

      // 측정 대상 페이지가 아니면, 추적하지 않습니다.
      if (!shouldTracePath(name)) {
        return 0;
      }

      return 0.2;
    },
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, log it.
      if (event.exception) {
        console.error(`[Exeption handled by Sentry]: (${hint.originalException})`, { event, hint });
        console.log('hint.originalException', hint.originalException);
        const { name, request, response } = hint.originalException;
        console.log('name', name);
        console.log('request.responseURL', request.responseURL);
        console.log('response.status', response.status);
        // 필터 API urls
        console.log('isBlockedUrl(request.responseURL)', isBlockedUrl(request.responseURL));
        if (isBlockedUrl(request.responseURL)) {
          console.log(`%cURL::${request.responseURL} 은 센트리 트랙킹에서 제외되었습니다.`, 'color: yellow;');
          return null;
        }

        // 필터 status 코드
        console.log('isBlockedStatusCode(response.status', isBlockedStatusCode(response.status));
        if (isBlockedStatusCode(response.status)) {
          console.log(`%cStatus code::${response.status}는 센트리 트랙킹에서 제외되었습니다.`, 'color: yellow;');
          return null;
        }

        // 에러 name (AxiosError, TypeError, ...)
        console.log('isBlockedErrorName(name)', isBlockedErrorName(name));
        if (isBlockedErrorName(name)) {
          console.log(`%cError::${name}는 센트리 트랙킹에서 제외되었습니다.`, 'color: yellow;');
          return null;
        }
      }
      // Continue sending to Sentry
      return event;
    },
    // 관련 링크 - https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-platformidentifier-nameignore-errors
    ignoreErrors: [
      'TypeError',
      '로그인 권한이 필요합니다.',
      'Request aborted',
      'timeout of 30000ms exceeded',
      'AxiosError: Network Error',
      'Error: Analytic is already identified',
      'Error: Analytics is not initialized',
    ],
  });

  vueApp.mixin(
    Sentry.createTracingMixins({ trackComponents: true, timeout: 2000, hooks: ['activate', 'mount', 'update'] }),
  );

  Sentry.attachErrorHandler(vueApp, {
    logErrors: false,
    attachProps: true,
    trackComponents: true,
    timeout: 2000,
    hooks: ['activate', 'mount', 'update'],
  });

  // API url 에서 트랙킹 할 필요없는 URL 을 필터링
  const isBlockedUrl = (url: string): boolean => {
    const blockedUrls = ['/v1/user/auth/sign-in', '/v1/user/honors/is-running'];
    return blockedUrls.filter((blockedUrl) => url.includes(blockedUrl)).length > 0;
  };

  // API url 에서 트랙킹 할 필요없는 statusCode를 필터링
  const isBlockedStatusCode = (statusCode: number): boolean => {
    const blockedStatusCode = [419];
    return blockedStatusCode.includes(statusCode);
  };

  // 에러에서 트래킹할 필요없는 에러 이름을 필터할
  const isBlockedErrorName = (errorName: string): boolean => {
    const blockedErrorNames = ['TypeError'];
    return blockedErrorNames.includes(errorName);
  };

  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException,
    },
  };
});
