<template>
  <i>
    <svg width="100%" height="100%" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.90895 17.5033C1.59145 16.7748 1.41536 15.9704 1.41536 15.125C1.41536 11.8343 4.083 9.16666 7.3737 9.16666C10.6644 9.16666 13.332 11.8343 13.332 15.125C13.332 18.4157 10.6644 21.0833 7.3737 21.0833C6.45818 21.0833 5.59089 20.8768 4.81586 20.5079C4.77033 20.5273 4.72233 20.5419 4.67249 20.5512L2.1694 21.017C1.94661 21.0585 1.71771 20.9875 1.55747 20.8273C1.39723 20.667 1.32625 20.4381 1.36771 20.2153L1.83356 17.7122C1.84742 17.6378 1.87319 17.5674 1.90895 17.5033Z"
        fill="currentColor" />
      <path
        d="M21.5807 9.16666C21.5807 10.4683 21.2793 11.6996 20.7424 12.7944L20.743 12.7974L21.388 16.2632C21.4295 16.486 21.3585 16.7149 21.1982 16.8751C21.038 17.0354 20.8091 17.1064 20.5863 17.0649L17.2281 16.4399C16.8007 16.6694 16.3508 16.8623 15.8824 17.0145C15.1885 17.24 14.6024 16.5516 14.6712 15.8251C14.693 15.5947 14.7042 15.3612 14.7042 15.125C14.7042 11.0749 11.4209 7.79166 7.37082 7.79166C7.13533 7.79166 6.90243 7.80276 6.67262 7.82447C5.94618 7.89308 5.25799 7.30657 5.48367 6.61268C6.55903 3.30642 9.6658 0.916664 13.3307 0.916664C17.8871 0.916664 21.5807 4.61031 21.5807 9.16666Z"
        fill="currentColor" />
    </svg>
  </i>
</template>
<style scoped lang="scss">
i {
  width: 22px;
  height: 22px;
  display: inline-block;
}
</style>
