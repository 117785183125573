import type { ApiResponse, ApiResponseInner } from '~~/src/types/global';

import { type Coupon } from '~/services/coupon';

import BaseService from '../BaseService';

export type Interst = {
  id: number;
  title: string;
  order: number;
};

export type InterestMeta = {
  id: number;
  title: string;
  order: number;
  items: Interst[];
};
class InterestService extends BaseService {
  constructor() {
    super('/user/interests');
  }

  // 사용자 관심사 정보 조회
  getMyInterest() {
    return this.get<{ items: Coupon }>(`/me`);
  }

  // 사용자 관심사 정보 업데이트
  //  [{"id": 3}]
  updateMyInterest(params) {
    return this.put<{ items: Coupon }>(`/me`, params);
  }

  // 관심사 메타 정보 조회
  getInterestMeta() {
    return this.getV2<ApiResponse<{ interestsResponse: InterestMeta[] }>>(`?version=2`);
  }
}

export const interestService = new InterestService();
