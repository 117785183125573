<template>
  <i>
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 23 22" fill="none">
      <g clip-path="url(#clip0_17592_6695)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.984375 5.04167C0.984375 2.63693 2.9338 0.6875 5.33854 0.6875H17.2552C19.6599 0.6875 21.6094 2.63693 21.6094 5.04167V12.8333C21.6094 15.2381 19.6599 17.1875 17.2552 17.1875H5.33854C2.9338 17.1875 0.984375 15.2381 0.984375 12.8333V5.04167ZM5.33854 2.0625C3.69319 2.0625 2.35938 3.39632 2.35938 5.04167V12.8333C2.35938 14.4787 3.69319 15.8125 5.33854 15.8125H17.2552C18.9006 15.8125 20.2344 14.4787 20.2344 12.8333V5.04167C20.2344 3.39632 18.9006 2.0625 17.2552 2.0625H5.33854Z"
          fill="currentColor" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.10938 19.9375C5.10938 19.5578 5.41718 19.25 5.79688 19.25H16.7969C17.1766 19.25 17.4844 19.5578 17.4844 19.9375C17.4844 20.3172 17.1766 20.625 16.7969 20.625H5.79688C5.41718 20.625 5.10938 20.3172 5.10938 19.9375Z"
          fill="currentColor" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.6094 7.0473V10.8276L13.2826 8.93747L10.6094 7.0473ZM9.23438 6.74326C9.23438 6.11226 9.71757 5.5 10.4328 5.5C10.6778 5.5 10.9125 5.57762 11.1081 5.71594L14.2113 7.91015C14.7571 8.29611 14.8823 9.04435 14.5544 9.59299C14.4681 9.73752 14.3522 9.86516 14.2113 9.96479L11.1081 12.159C10.5244 12.5717 9.76059 12.3668 9.41424 11.7872C9.2951 11.5878 9.23438 11.3603 9.23438 11.1317V6.74326Z"
          fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_17592_6695">
          <rect width="22" height="22" fill="white" transform="translate(0.296875)" />
        </clipPath>
      </defs>
    </svg>
  </i>
</template>
<style scoped lang="scss">
i {
  width: 22px;
  height: 22px;
  display: inline-block;
}
</style>
