// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

function getFirebaseConfig() {
  const runtime = useRuntimeConfig();
  return {
    apiKey: runtime.public.firebaseApiKey,
    authDomain: runtime.public.firebaseAuthDomain,
    projectId: runtime.public.firebaseProjectId,
    storageBucket: runtime.public.firebaseStorageBucket,
    messagingSenderId: runtime.public.firebaseMessagingSenderId,
    appId: runtime.public.firebaseAppId,
    measurementId: runtime.public.firebaseMeasurementId,
  };
}

// Initialize Firebase
let messaging: any = null;

export const init = async () => {
  const app = initializeApp(getFirebaseConfig());
  if (typeof window !== 'undefined' && typeof window.navigator !== 'undefined') {
    // Register Firebase Service Worker
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/firebase-messaging-sw.js')
        .then(function (registration) {})
        .catch(function (error) {
          console.error('Firebase Service Worker registration failed:', error);
        });
    }

    // Web Push supported
    const registration = await navigator.serviceWorker.ready;
    if (!('pushManager' in registration)) {
      return;
    }

    // Get Firebase Messaging instance
    messaging = getMessaging(app);

    // Unregister other service workers if needed
    //   navigator.serviceWorker.getRegistrations().then(function (registrations) {
    //     for (let registration of registrations) {
    //       registration.unregister();
    //     }
    //   });
  }
};

export async function requestPermission() {
  let token;

  try {
    if (Notification.permission === 'granted') {
      token = await getDeviceToken();
    } else {
      const permission = await Notification.requestPermission();
      if (permission === 'denied') {
        return;
      }
      token = await getDeviceToken();
    }
    onMessage(messaging, (payload) => {
      console.log('!! 메시지가 도착했습니다.', payload);
    });
  } catch (error) {
    console.log(error);
  }

  return token;
}

export async function getDeviceToken() {
  const runtime = useRuntimeConfig();
  let token: string | null = null;
  try {
    token = await getToken(messaging, {
      vapidKey: runtime.public.firebaseVapidKey,
    });
  } catch (e) {
    console.log(e);
  }
  return token;
}
