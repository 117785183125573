import { type InterestMeta, interestService } from '.';

interface State {
  interestPopupVisible: boolean;
  infoPopupVisible: boolean;
  interestMeta: InterestMeta[];
  userInterest: any;
}

// 사용자 관심사 및 추가 정보
export const useInterestStore = defineStore('user-interest', {
  state: (): State => ({
    interestPopupVisible: false,
    infoPopupVisible: false, // 삭제
    interestMeta: [],
    userInterest: undefined,
  }),
  actions: {
    async fetchInterestMeta() {
      try {
        const res = await interestService.getInterestMeta();
        if (!res?.data) {
          return [];
        }
        this.interestMeta = res.data.interestsResponse;

        const {
          public: { app_env },
        } = useRuntimeConfig();
        if (app_env !== 'prod') {
          window.openInterest = () => this.setInterestPopup(true);
        }

        return res.data.interestsResponse;
      } catch (err) {
        console.error('err :>> ', err);
      }
    },
    async fetchMyInterest() {
      const { data } = await interestService.getMyInterest();
      this.userInterest = data?.data?.interestsMeResponse;
      return data?.data?.interestsMeResponse;
    },
    async updateMyInterest(params) {
      await interestService.updateMyInterest(params);
    },
    setInterestPopup(visible) {
      const {
        public: { app_env },
      } = useRuntimeConfig();
      this.interestPopupVisible = visible;
    },
  },
  getters: {
    getInterestPopupVisible(state): any {
      return state.interestPopupVisible;
    },
    getInterestMeta(state): any {
      return state.interestMeta;
    },
  },
});
